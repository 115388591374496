// DatePicker.js
import React from 'react';
import { Label, Input, InputGroup, FormGroup, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types'
import Flatpickr from "react-flatpickr";
import moment from 'moment';

function TimePicker({ validation ,label, name  }) {

  // console.log("validation",validation);

  return (
    <div className="form-group mb-3">
        <FormGroup>
                      <Label>{label}</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            time_24hr: true
                          }}
                          name={name}
                          value={validation.values[name]}
                          onChange={(e)=>{
                            validation.setFieldValue(name,moment(e[0]).format('HH:mm:ss'))
                          
                          }}
                          invalid={validation.errors[name]}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>
        </FormGroup>
                {validation.errors[name] ? (
                        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
                    ) : null}
                    </div>
  );
}

TimePicker.propTypes = {
  validation: PropTypes.object,
}

export default TimePicker;
