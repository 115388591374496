const labelConstant = {
    EMAIL: 'Email',
    OTP: 'OTP',
    MOBILE: 'Mobile',
    NAME: 'Name',
    SHORTNAME : 'Short Name',
    DURATION : 'Duration',
    LOGO: 'Logo',
    PROFILEIMAGE:'Profile Image',
    ICON : 'Icon',
    DASHBOARD: 'Dashboard',
    EMPLOYEE: 'Employee',
    PROJECT: 'Project',
    SPRINT: 'Sprint',
    CREATE: 'Create',
    DOB: 'Date of Birth',
    SALARY: 'Salary',
    BACKGROUND_COLOR: 'Background Color',
    COLUMN_BACKGROUND_COLOR: 'Column Background Color',
    STORYPOINTS : 'Story Points',
    ESTIMATEDTIME : 'Estimated Time',
}
export default labelConstant
