import { all, fork } from "redux-saga/effects";

import LayoutSaga from "./layout/saga";
import AppMasterSaga from "./AppMaster/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AppMasterSaga),
    fork(LayoutSaga),
  ]);
}
