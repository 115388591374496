import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb2"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Row,
  Col,
  CardBody,
  Card,
  Form,
  Container,
  Modal,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Button, ModalHeader, ModalBody, ModalFooter
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import api from "../../constants/api"
import {
  addNewData as onAddNewData,
  getList as onGetList,
  clearData,
  updateData as onUpdateData,
} from "store/AppMaster/actions"
import LoginButton from "components/Common/PageComponent/LoginButton"
import CustomSelect from "components/Common/PageComponent/DropdownList"
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import DeleteModal from "components/Common/DeleteModal"
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  toastClass: 'toast-custom-style',
  // colorClass: 'toast-error',
};

const Workflow = props => {
  document.title = "Workflow | Project Naya"
  const navigate = useNavigate()
  const params = useParams()

  const [currentPage, setCurrentPage] = useState(1)
  const [isApiCall, setIsApiCall] = useState(0)
  const [isDelete, setIsDelete] = useState(false)
  const [workFlowData, setWorkFlowData] = useState(null)
  const [workFlowDataSet, setWorkFlowDataSet] = useState(false)
  const [defaultOpenIndex, setDefaultOpenIndex] = useState(0)
  const [deleteItemId, setDeleteItemId] = useState(null)
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }))
  const [modal_standard, setmodal_standard] = useState(false)
  const perPageData = 12
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData
  const currentdata = useMemo(() => indexOfFirst, indexOfLast, [
    indexOfFirst,
    indexOfLast,
  ])
  const [addFlag,setAddFlag]=useState(false);
  const [modal, setModal] = useState(false);
  const [updateTaskMetaData, setUpdateTaskMetaData] = useState(false)
  const [selectedStatus , setselectedStatus ] = useState([])
  const [selectedMoveColumn , setselectedMoveColumn ] = useState([])

  const toggle = (e) => {
    setModal(!modal)
    e.stopPropagation()

  };

  function tog_standard(itemId) {
    setDeleteItemId(itemId) // Set the ID of the item to be deleted
    setmodal_standard(!modal_standard)

  }

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      getTaskMetaData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setWorkFlowDataSet(true);
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.BoardWorkFlowList))
  }

  if (!isEmpty(data) && !isEmpty(data.task_board_workflow) && workFlowDataSet) {
    setWorkFlowData(data);
    setWorkFlowDataSet(false);
  }

  const getTaskMetaData = () => {
    setUpdateTaskMetaData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
  }

  if (!isEmpty(data) && !isEmpty(data.status)  && updateTaskMetaData) {
    setUpdateTaskMetaData(false)
    data.status.map((item, index) => {
      selectedStatus.push({ label: item.column_name, value: item.id })
      selectedMoveColumn.push({ label: item.column_name, value: item.id })
    })}

      // Add Board WorkFlow
const validation = useFormik({
  initialValues: {
    task_board_column_id: "",
    task_move_column_id: "",
  },
  validationSchema: Yup.object({
    task_board_column_id: Yup.string().required('Task Board Column is required'),
    task_move_column_id: Yup.string().required('Task Move Column is required'),
  }),
  onSubmit: values => {
    setAddFlag(true)
    if( values["task_board_column_id"] === values["task_move_column_id"]){
      // alert("Task Board Column and Task Move Column should not be same")
      toastr["error"]("Task Board Column and Task Move Column should not be same")
      return false
    }
    else{
    const addData = { 
      task_board_column_id: values["task_board_column_id"],
      task_move_column_id: values["task_move_column_id"],
    }
    dispatch(clearData())
    dispatch(onAddNewData(addData, props.router.navigate, api.BoardWorkFlowAdd))
  }
  },  
});

if (!loading && validation.isSubmitting) {
  validation.isSubmitting = false
}

if (!isEmpty(data) && !isEmpty(data.task_board_workflow) && addFlag) {
  setAddFlag(false)
  validation.resetForm()
  // toggle()
  setModal(!modal)
  dispatch(clearData())
  getListData()
}

  

  const handleAccordionItemToggle = index => {
    setDefaultOpenIndex(index)
  }
  const handleDeleteItem = () => {
    if (deleteItemId) {
      setIsDelete(true)
      const deleteData = {
        board_workflow_id: deleteItemId,
      }
      dispatch(clearData())
      dispatch(onUpdateData(deleteData,props.router.navigate,api.BoardWorkFlowDelete))
      setDeleteItemId(null)
      setmodal_standard(false)
      // getListData()
    }
  }

  if(!isEmpty(data) && !isEmpty(data.task_board_workflow) && isDelete) {
    getListData();
    setDeleteItemId(data.task_board_workflow)
    setIsDelete(false)
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (!workFlowData || isEmpty(workFlowData.task_board_workflow)) {
    return <div>No data available.</div>
  }

const handleBoardColumnId = (selectedStatus ) => {
  validation.setFieldValue("task_board_column_id",selectedStatus)
}

const handleTaskMoveColumnId = (selectedMoveColumn ) => {
  validation.setFieldValue("task_move_column_id",selectedMoveColumn)
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Workflow"
            breadcrumbItems={[
              { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
              { title: "Workflow", path: `/${props.router.organization_slug}/organization/workflow` },

            ]}
          />

          <Row className="mt-3">
          <Col lg="12">
            <div className="text-lg-end mb-2">
                <Link to="#" className="btn btn-primary waves-effect waves-light" onClick={toggle}>
                    <i className="mdi mdi-plus me-1" ></i>Add WorkFlow
                </Link>
            </div>
          </Col>
            <Col>
              <Card>
                <CardBody>
                  <UncontrolledAccordion defaultOpen={defaultOpenIndex}>
                    {workFlowData.task_board_workflow.map((workflow, index) => (
                      <AccordionItem key={index}>
                        <AccordionHeader targetId={workflow.id}>
                      <h5 className="mb-0 fw-medium">{workflow.column_name}</h5>
                        </AccordionHeader>
                        <AccordionBody
                          accordionId={workflow.id}
                          onToggle={() => handleAccordionItemToggle(index)}
                        >
                          {workflow.board_workflow.map((board, idx) =>
                          (
                            <Row key={idx}>
                              <Col md="6">
                                <Row>
                                  <Col md="auto">
                                    <div className="text-muted">
                                      <h5>To</h5>
                                    </div>
                                  </Col>
                                  <Col md="auto">
                                    <h5>
                                      {
                                        board.board_column_able_to_move_column_name
                                      }
                                    </h5>
                                  </Col>        
                                </Row>
                              </Col>
                              <Col md="6" className="text-end">
                                <Link
                                  to="#"
                                  onClick={() => {
                                    tog_standard(board.id) // Pass the item ID to the delete function
                                  }}
                                  data-toggle="modal"
                                  data-target="#myModal"
                                >
                                  <i className="mdi mdi-trash-can font-size-18 text-danger"></i>
                                </Link>
                              </Col>
                            </Row>
                          ))}
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </UncontrolledAccordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
                    {/* Delete Modal */}
                    <DeleteModal
        show={modal_standard}
        onDeleteClick={handleDeleteItem}
        // modalTitle="User Delete"
        onCloseClick={() => setmodal_standard(false)}
        ModalName={ "Delete Workflow"}
      />
      {/* <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
        centered
      >
        <div className="modal-header border-0">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="text-center modal-body">
          <Row>
            <Col sm={12}>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to permanently erase the workflow.
              </p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteItem}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                >
                  Close
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal> */}

      {/* Create Board WorkFlow */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add WorkFlow</ModalHeader>
        <Form
        onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}
          >
        <ModalBody>
        <Row>
        <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                      label="Task Board Column"
                      showStrike={true}
                      options={selectedStatus}
                      name="task_board_column_id"
                      className="custom-select"
                      // validation={validation}
                      onChange={handleBoardColumnId}
                      validation={validation}
                    />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                      label="Task Move Column"
                      showStrike={true}
                      options={selectedMoveColumn}
                      name="task_move_column_id"
                      className="custom-select"
                      onChange={handleTaskMoveColumnId}
                      validation={validation}
                    />
              </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{' '}
          <LoginButton
                buttonName="Submit"
                />
        </ModalFooter>
        </Form>
      </Modal>
      
    </React.Fragment>
  )
}

export default withRouter(Workflow)
