import PropTypes from 'prop-types'
import { FormFeedback, Input, Label } from "reactstrap"
import React from "react";
import labelConstant from 'constants/label';
const Email = ({ validation }) => {

    return (
        <div className="mb-3">
            <Label className="form-label">{labelConstant.EMAIL}<span className="text-danger">*</span></Label>
            <Input
                name="email"
                className="form-control"
                placeholder={"Enter " + labelConstant.EMAIL}
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                    validation.touched.email && validation.errors.email ? true : false
                }
            />  
            {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
            ) : null}
        </div>

    )
}

Email.propTypes = {
    validation: PropTypes.object,
}

export default Email
