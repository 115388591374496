import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import AppMaster from "./AppMaster/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  AppMaster
});

export default rootReducer;
