import { call, put, takeEvery } from "redux-saga/effects"
import { fetchApiFormData, fetchApiJSON } from "../../helpers/api"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  GET_LIST,
  GET_DATA,
  ADD_NEW_DATA,
  UPDATE_DATA,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_FORM_DATA,
} from "./actionTypes"
import {
  getListFail,
  getListSuccess,
  getDataFail,
  getDataSuccess,
  addDataFail,
  addDataSuccess,
  updateDataSuccess,
  updateDataFail,
  apiError,
} from "./actions"
// import { toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"

// toastr.options = {
//   positionClass: "toast-top-right",
//   timeOut: 5000,
//   showEasing: "swing",
//   hideEasing: "linear",
//   showMethod: "fadeIn",
//   hideMethod: "fadeOut",
//   showDuration: 300,
//   hideDuration: 1000
// };
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  toastClass: "toast-custom-style", // Add a custom class for styling
}
function* fetchList({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("projectNayaAuthUser"))
  const options = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200 || response.STATUS_CODE == 404) {
      yield put(getListSuccess(response.DATA))
    } else if (response.STATUS_CODE == 401) {
      toastr["error"](response.MESSAGE)
      // toast.error(response.MESSAGE, { autoClose: 2000 });
      yield put(getListFail(response.MESSAGE))
      localStorage.removeItem("projectNayaAuthUser")
      localStorage.removeItem("projectNayaAuthUserDetails")
      history.push("/login")
    } else {
      toastr["error"](response.MESSAGE)
      // toast.error(response.MESSAGE, { autoClose: 2000 });
      yield put(getListFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(getListFail(error))
  }
}
function* onUpdateData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("projectNayaAuthUser"));
  let options

  if (data.type && data.type === "formData") {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (key !== "type") {
        formData.append(key, data[key])
      }
    })
    options = {
      body: formData,
      method: "POST",
      headers: {
        Authorization: "Bearer " + authData,
      },
    }
  } else {
    options = {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData,
      },
    }
  }

  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200) {
      // console.log("response",response);
      // alert(response.MESSAGE);
      toastr["success"](response.MESSAGE)

      // toastr.success(response.MESSAGE, '');
      yield put(updateDataSuccess(response.DATA))
    } else if (response.STATUS_CODE == 401) {
      // toastr.error(response.MESSAGE, '');
      toastr["error"](response.MESSAGE)
      yield put(getListFail(response.MESSAGE))
      localStorage.removeItem("projectNayaAuthUser")
      localStorage.removeItem("projectNayaAuthUserDetails")
      history("/login")
    } else {
      yield put(updateDataFail(response.MESSAGE))
      toastr["error"](response.MESSAGE)
      // toast.error(response.MESSAGE, { autoClose: 20000 });
    }
  } catch (error) {
    yield put(updateDataFail(error))
  }
}

function* onUpdateFormData({ payload: { data, history, url } }) {
  // const authData = JSON.parse(localStorage.getItem("projectNayaAuthUser"));
  // const options = {
  //   body: JSON.stringify(data),
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + authData
  //   },
  // };
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiFormData, apiUrl, data)
    if (response.STATUS_CODE == 200) {
      // console.log("response",response);
      // alert(response.MESSAGE);
      toastr["success"](response.MESSAGE)

      // toastr.success(response.MESSAGE, '');
      yield put(updateDataSuccess(response.DATA))
    } else if (response.STATUS_CODE == 401) {
      // toastr.error(response.MESSAGE, '');
      toastr["error"](response.MESSAGE)
      yield put(getListFail(response.MESSAGE))
      localStorage.removeItem("projectNayaAuthUser")
      localStorage.removeItem("projectNayaAuthUserDetails")
      history("/login")
    } else {
      yield put(updateDataFail(response.MESSAGE))
      toastr["error"](response.MESSAGE)
      // toast.error(response.MESSAGE, { autoClose: 20000 });
    }
  } catch (error) {
    yield put(updateDataFail(error))
  }
}
function* onAddNewData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("projectNayaAuthUser"))
  let options

  if (data.type && data.type === "formData") {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (key !== "type") {
        formData.append(key, data[key])
      }
    })
    options = {
      body: formData,
      method: "POST",
      headers: {
        Authorization: "Bearer " + authData,
      },
    }
  } else {
    options = {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData,
      },
    }
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200) {
      // toastr.success(response.MESSAGE, '');
      toastr["success"](response.MESSAGE)
      yield put(addDataSuccess(response.DATA))
    } else if (response.STATUS_CODE == 401) {
      // toastr.error(response.MESSAGE, '');
      toastr["error"](response.MESSAGE)
      yield put(getListFail(response.MESSAGE))
      localStorage.removeItem("projectNayaAuthUser")
      localStorage.removeItem("projectNayaAuthUserDetails")
      history("/login")
    } else {
      toastr["error"](response.MESSAGE)
      // toast.error(response.MESSAGE, { autoClose: 2000 });
      yield put(addDataFail(error))
    }
  } catch (error) {
    yield put(addDataFail(error))
  }
}
function* onGetData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("projectNayaAuthUser"))
  const options = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200 || response.STATUS_CODE == 404) {
      if(url == "v1/check-email"){
        toastr["error"](response.MESSAGE)
       
      }
      yield put(getDataSuccess(response.DATA))
    } else if (response.STATUS_CODE == 401) {
      toastr["error"](response.MESSAGE)
      // toast.error(response.MESSAGE, { autoClose: 2000 });
      yield put(getListFail(response.MESSAGE))
      localStorage.removeItem("projectNayaAuthUser")
      localStorage.removeItem("projectNayaAuthUserDetails")
      history("/login")
    } else {
     if(url != "v1/check-email"){
      toastr["error"](response.MESSAGE)
     
    }
    if(url == "v1/check-email"){
      yield put(getDataSuccess(response)) 
    }
      // toast.error(response.MESSAGE, { autoClose: 2000 });
      yield put(addDataFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(getDataFail(error))
  }
}
function* loginUser({ payload: { data, history, url } }) {
  try {
    const options = {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
    const apiUrl = process.env.REACT_APP_API_URL + url
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (
      (response.STATUS_CODE == 200 || response.STATUS_CODE == 400) &&
      url == "v1/check-email"
    ) {
      yield put(getDataSuccess(response))
    } else if (
      (response.STATUS_CODE == 200 || response.STATUS_CODE == 400) &&
      url == "v1/send-otp"
    ) {
      yield put(getDataSuccess(response))
    } else if (response.STATUS_CODE == 200) {
      localStorage.setItem(
        "projectNayaAuthUser",
        JSON.stringify(response.DATA.token)
      )
      localStorage.setItem(
        "projectNayaAuthUserDetails",
        JSON.stringify(response.DATA)
      )
      toastr["success"](response.MESSAGE)
      if (response.DATA.userDetails.role_id == 2) {
        history("/select-organization")
      } else {
        history("/dashboard")
      }
      yield put(getDataSuccess(response.DATA))
    } else {
      yield put(apiError(response.MESSAGE))
      toastr["error"](response.MESSAGE)
    }
  } catch (error) {
    yield put(apiError(error))
  }
}
function* logoutUser({ payload: { history, url } }) {
  yield put(getDataSuccess("logout"))
  localStorage.removeItem("projectNayaAuthUser")
  localStorage.removeItem("projectNayaAuthUserDetails")
  localStorage.removeItem("projectNayaOrganization")
  history("/login")
}
function* appMasterSaga() {
  yield takeEvery(GET_LIST, fetchList)
  yield takeEvery(ADD_NEW_DATA, onAddNewData)
  yield takeEvery(UPDATE_DATA, onUpdateData)
  yield takeEvery(UPDATE_FORM_DATA, onUpdateFormData)
  yield takeEvery(GET_DATA, onGetData)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}
export default appMasterSaga
