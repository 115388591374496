import PropTypes from 'prop-types'
import { FormFeedback, Input, Label } from "reactstrap"
import React, { useState } from "react";
import labelConstant from 'constants/label';
import { SketchPicker } from "react-color";
import { ColorPicker } from "@vtaits/react-color-picker";
const BackGroundColor = ({ validation, showStrike }) => {
    const handleBackgroundColor = color => {
        validation.setFieldValue("background_color", color.hex);
    };
    const [simpleColor, setSimpleColor] = useState(0);
    return (
        <div className="mb-3">
            {/* <Label className="form-label">{labelConstant.BACKGROUND_COLOR}</Label> */}
            <Label>{labelConstant.BACKGROUND_COLOR}{showStrike && (
          <span style={{  color: 'red' }}>*</span>
        )}</Label>
            <div
                className="input-group colorpicker-default"
                title="Using format option"
            >
                <Input
                    type="text"
                    placeholder='Select Background color here'
                    onClick={() => {
                        setSimpleColor(!simpleColor);
                    }}
                    value={validation.values.background_color ? validation.values.background_color : ""}
                    readOnly
                    invalid={
                        validation.touched.background_color && validation.errors.background_color ? true : false
                    }
                />
                <span className="input-group-append">
                    <span
                        className="input-group-text colorpicker-input-addon rounded-start-0 rounded-end-1"
                        onClick={() => {
                            setSimpleColor(!simpleColor);
                        }}
                    >
                        <i
                            style={{
                                height: "21.5px",
                                width: "16px",
                                background: validation.values.background_color
                            }}
                        />
                    </span>
                </span>
            </div>
            {simpleColor ? (
                <SketchPicker
                    value={simpleColor}
                    width="160px"
                    onChangeComplete={handleBackgroundColor}
                />
            ) : null}
            {simpleColor ? (
                <ColorPicker
                    saturationHeight={100}
                    saturationWidth={100}
                    value={validation.values.background_color}
                    onDrag={handleBackgroundColor}
                />
            ) : null}

            {validation.touched.background_color && validation.errors.background_color ? (
                <FormFeedback type="invalid">{validation.errors.background_color}</FormFeedback>
            ) : null}
        </div>

    )
}

BackGroundColor.propTypes = {
    validation: PropTypes.object,
}

export default BackGroundColor
