import React, { useState } from 'react';
import LoginButton from 'components/Common/PageComponent/LoginButton';
import { Modal, Form, Row, Col, Input, Label, FormFeedback } from 'reactstrap'; // Import necessary components
import AnotherButton from 'components/Common/PageComponent/AnotherButton';
import Duration from 'components/Common/PageComponent/Duration';
import BackGroundColor from 'components/Common/PageComponent/BackGroundColor';
import DatePicker from 'components/Common/PageComponent/DatePicker';
import ShortName from 'components/Common/PageComponent/ShortName';
import Name from 'components/Common/PageComponent/Name';
import labelConstant from 'constants/label';

const CreateEditProjectModal = ({ isOpen, toggle, isEdit, validation, handleChange, handleImageChange, buttonOnClick }) =>
 {

  return (
    
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {!!isEdit ? "Edit Project" : "Add Project"}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <Form
         onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          >
        <div className="modal-body">
        <Row>
              <Col md="6">
                <Name validation={validation} />
              </Col>
              <Col md="6">
                <ShortName validation={validation} />
                </Col>
              <Col md="6">
                <DatePicker
                label="Start Date"
                // onChange={handleChange}
                name="start_date"
                validation={validation}
                showStrike={true}
              />
              </Col>
              <Col md="6">
                <Duration validation={validation} />
              </Col>
              <Col md="6">
                <BackGroundColor validation={validation} showStrike={true} />
              </Col>
              <Col md="6">
              <Label className="form-label">{labelConstant.LOGO}</Label>
              <Input
               name="logo"
               type="file"
               accept="image/*"
               onChange={handleImageChange}
               invalid={
                validation.touched.logo && validation.errors.logo ? true : false
            }
             />
           { validation.touched.logo && validation.errors.logo ? (
              <FormFeedback type="invalid">{validation.errors.logo}</FormFeedback>
          ) : null}
              </Col>
            </Row>
      </div>
      <div className="modal-footer">
          <div className="d-flex align-items-center gap-2">
             <AnotherButton
              className="btn btn-secondary"
              // onClick={(e)=>{
              //   e.preventDefault();
              //   handleclick();
              //   }}
              onClick={buttonOnClick}
              buttonName="Cancel"
            />{" "}
            <LoginButton buttonName="Submit" />
          </div>
          </div>
        </Form>
      
    </Modal>
  );
};

export default CreateEditProjectModal;
