// DatePicker.js
import React from 'react';
import { Label, FormFeedback, InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from 'moment';

function DatePicker({ validation, label, className, name, showStrike }) {
  // const maxDate = name === 'date_of_birth' ? moment().subtract(15, 'years').toDate() : '';
  let maxDate = '';
  let formattedMaxDate = '';
  if (name === 'date_of_birth') {
    maxDate = moment().subtract(15, 'years').toDate();
    formattedMaxDate = maxDate ? moment(maxDate).format('YYYY-MM-DD') : null;
  }
  if (name === 'joining_date') {
    maxDate = moment().toDate();
    formattedMaxDate = maxDate ? moment(maxDate).format('YYYY-MM-DD') : null;
  }

  // const minDate = name === 'start_date' ? moment().toDate() : '';
  const minDate = name === 'start_date' ? moment().startOf('day').toDate() : '';
  
  if(validation.values[name] === '0000-00-00') {
    validation.values[name] = '';
  } else {
    validation.values[name] = validation.values[name];
  }
  return (
    <div className='mb-3'>
      <Label>{label}{showStrike && (
          <span style={{  color: 'red' }}>*</span>
        )}</Label>
      <InputGroup>
        <Flatpickr
          className={"form-control d-block " + (validation.errors[name] ? "is-invalid" : "") + {className}}
          placeholder="dd-mm-yyyy"
          options={{
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            defaultDate: validation.values[name], // Set the default date from validation.values[name]0
            maxDate: formattedMaxDate,
            minDate : minDate,
          }}
          name={name}
          value={validation.values[name] ? validation.values[name] : ''} // Set the value from validation.values[name]
          onChange={date => {
            // Extract the date part only
            const selectedDate = date[0] ? moment(date[0]).format('YYYY-MM-DD') : ''; // Format the date using moment.js
            // Check if date is an array
            validation.handleChange({ target: { name, value: selectedDate } }); // Call handleChange with the date
          }}
          onInvalid={() => {
            // Mark the field as touched to show error feedback only if it's touched and there's an error
            if (validation.touched[name]) {
              validation.touched({ [name]: true });
            }
            else{
              validation.touched({ [name]: false });
            }
          }}
        
        />
        {validation.touched[name] && validation.errors[name] ? (
          <FormFeedback type="onInvalid">{validation.errors[name]}</FormFeedback>
        ) : null}
      </InputGroup>
    </div>
  );
}

DatePicker.propTypes = {
  validation: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showStrike: PropTypes.bool, // Prop to control whether the strike sign should be displayed
};

export default DatePicker;
