import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Signup from "../pages/Authentication/Signup";
import OrganizationProfile from "pages/Authentication/OrganizationProfile";
import Dashboard from "../pages/Dashboard/Index";
import Employee from "../pages/Employee/Index";
import EmployeeView from "../pages/Employee/View";
import Project from "../pages/Project/Index";
import ProjectView from "../pages/Project/View";
import Sprint from "../pages/Sprint/Index";
import Workflow from "../pages/Workflow/Index";
import BoardColumn from "../pages/BoardColumn/Index";
import Backlog from "../pages/Backlog/Index";
import ActiveSprint from "../pages/ActiveSprint/Index";
import SelectOrganization from "../pages/Authentication/SelectOrganization";


/**Task Tag */
import OrganizationTaskTag from "../pages/Organization/Task/Tag/Index";
import OrganizationTaskEpic from "../pages/Organization/Task/Epic/Index";
import OrganizationTaskType from "../pages/Organization/Task/TaskType/Index";
import OrganizationPriority from "../pages/Organization/Task/Priority/Index";
import OrganizationDesignation from "../pages/Organization/Task/Designation/Index";
import OrganizationTaskStoryPoint from "../pages/Organization/Task/TaskStoryPoint/Index";
import OrganizationHoliday from "../pages/Organization/Task/Holiday/Index";
const authProtectedRoutes = [

    { path: "/:organization_slug/organization/dashboard", component: <Dashboard /> },
    { path: "/:organization_slug/organization/profile", component: <OrganizationProfile /> },
    { path: "/:organization_slug/organization/employee", component: <Employee /> },
    { path: "/:organization_slug/organization/employee/view/:id", component: <EmployeeView /> },
    { path: "/:organization_slug/organization/project", component: <Project /> },
    { path: "/:organization_slug/organization/Sprint", component: <Sprint /> },
    { path: "/:organization_slug/organization/project/view/:id", component: <ProjectView /> },
    { path: "/:organization_slug/organization/workflow", component: <Workflow /> },
    { path: "/:organization_slug/organization/boardcolumn", component: <BoardColumn /> },
    { path: "/:organization_slug/organization/backlog", component: <Backlog /> },
    { path: "/:organization_slug/organization/active-sprint", component: <ActiveSprint /> },


    /**Task Tag Route */
    { path: "/:organization_slug/organization/task-tag", component: <OrganizationTaskTag /> },
    { path: "/:organization_slug/organization/task-epic", component: <OrganizationTaskEpic /> },
    { path: "/:organization_slug/organization/task-type", component: <OrganizationTaskType /> },
    { path: "/:organization_slug/organization/priority", component: <OrganizationPriority /> },
    { path: "/:organization_slug/organization/task-Story-Point", component: <OrganizationTaskStoryPoint /> },
    { path: "/:organization_slug/organization/designation", component: <OrganizationDesignation /> },
    { path: "/:organization_slug/organization/holiday", component: <OrganizationHoliday /> },
    {
        path: "/",
        exact: true,
        component: <Navigate to="/:organization_slug/organization/dashboard" />,
    },
];

const authProtectedRoutesWithoutSidebar = [
    { path: "/select-organization", component: <SelectOrganization /> },
];

const publicRoutes = [
    { path: "/login", component: <Login /> },
    { path: "/logout", component: <Logout /> },
    { path: "/signup", component: <Signup /> },
];


export { authProtectedRoutes, authProtectedRoutesWithoutSidebar, publicRoutes };
