import PropTypes from 'prop-types'
import { FormFeedback, Input, Label } from "reactstrap"
import React from "react";
import labelConstant from 'constants/label';
const EstimatedTime = ({ validation }) => {
    
    const handleEstimatedTimeChange = (e) => {
        const { value } = e.target;
        // Remove non-digit characters and limit to 6 characters
        const formattedOtp = value.replace(/\D/g, '');
        validation.setFieldValue("estimated_time", formattedOtp);
    };

    return (
        <div className="mb-3">
            <Label className="form-label">{labelConstant.ESTIMATEDTIME} (in hours)</Label>
            <Input
                name="estimated_time"
                className="form-control"
                placeholder={"Enter " + labelConstant.ESTIMATEDTIME + " in hours"}
                type="text"
                onChange={handleEstimatedTimeChange}
                onBlur={validation.handleBlur}
                value={validation.values.estimated_time || ""}
                invalid={validation.errors.estimated_time}
            />
            {validation.errors.estimated_time ? (
                <FormFeedback type="invalid">{validation.errors.estimated_time}</FormFeedback>
            ) : null}
        </div>

    )
}

EstimatedTime.propTypes = {
    validation: PropTypes.object,
}

export default EstimatedTime
