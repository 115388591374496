import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Label,
  Input,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  InputGroup,
  Collapse,
  FormGroup,
  Button,
  Badge,
  Progress,
  UncontrolledTooltip,
} from "reactstrap"

//redux
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import api from "../../constants/api"
import { isEmpty } from "lodash"
import moment from "moment"

import configJs from "../../config"
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData,
} from "store/AppMaster/actions"
import { useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import Name from "components/Common/PageComponent/Name"
import ShortName from "components/Common/PageComponent/ShortName"
import DatePicker from "components/Common/PageComponent/DatePicker"
import Duration from "components/Common/PageComponent/Duration"
import BackGroundColor from "components/Common/PageComponent/BackGroundColor"
import LoginButton from "components/Common/PageComponent/LoginButton"
import AnotherButton from "components/Common/PageComponent/AnotherButton"
import DatePicker1 from "components/Common/PageComponent/DatePickerForTimeLog"
import messageConstant from "constants/message"
import Description from "components/Common/PageComponent/Description"
import CustomSelect from "components/Common/PageComponent/DropdownList"
import TimePicker from "components/Common/PageComponent/TimePicker"
import EstimatedTime from "components/Common/PageComponent/EstimatedTime"
import DeleteModal from "components/Common/DeleteModal"
import { Editor } from "react-draft-wysiwyg"
import { EditorState , ContentState , convertFromHTML } from 'draft-js';
import { stateToHTML } from "draft-js-export-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LeaveTab from "./LeaveTab"
import ProjectTab from "./ProjectTab"
import OverviewTab from "./OverviewTab"
import TasksTab from "./TasksTab"

const Employee = props => {
  document.title = "Employee view | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
    id: localStorage.getItem("projectNayaOrganization")
      ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
      : 0,
  })
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedStoryPoint, setSelectedStoryPoint] = useState([])
  const [selectedSprint, setSelectedSprint] = useState([])
  const [selectedAssignee_id, setSelectedAssignee_id] = useState([])
  const [selectedReporter_id , setSelectedReporter_id ] = useState([])
  const [selectedTask_epic_id , setSelectedTask_epic_id ] = useState([])
  const [selectedTasktype , setSelectedTasktype ] = useState([])
  const [selectedPriority , setSelectedPriority ] = useState([])
  const [selectedProject, setSelectedProject ] = useState([])
  const [imgStore, setImgStore] = useState([])
  const [active, setActive] = useState(0)
  const [dropList, setDropList] = useState(false)

  const [customActiveTab, setcustomActiveTab] = useState("1")

  useEffect(() => {
    // Get the active tab from local storage when component mounts
    const storedActiveTab = localStorage.getItem('customActiveTab');
    if (storedActiveTab) {
      setcustomActiveTab(storedActiveTab);
    }
  }, []);
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
      localStorage.setItem('customActiveTab', tab);
    }
  }
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_standard4, setmodal_standard4] = useState(false)
  const [modal_standard1, setmodal_standard1] = useState(false)
  const [modal_standard2, setmodal_standard2] = useState(false)
  function tog_standard() {
    setmodal_standard(!modal_standard)
  }

  function tog_standard1() {
    setmodal_standard1(!modal_standard1)
  }
  function tog_standard2() {
    setmodal_standard2(!modal_standard2)
  }

  const [isApiCall, setIsApiCall] = useState(0)
  const [employee, setEmployee] = useState({})
  const [status, setStatus] = useState()
  const [employeeTask, setEmployeeTask] = useState()
  const [employeeProjects, setEmployeeProjects] = useState()
  const [employeeLeaves, setEmployeeLeaves] = useState()
  const [priorityTask, setPriorityTask] = useState()
  const [addFlag, setAddFlag] = useState(false)
  const [employeeTimeLog, setEmployeeTimeLog] = useState()

  const [updateListData, setUpdateListData] = useState(false)
  const [updateProjectListData, setUpdateProjectListData] = useState(false)
  const [updateTaskTagListData, setUpdateTaskTagListData] = useState(false)
  const [updateTaskMetaData, setUpdateTaskMetaData] = useState(false)
  const [updateTaskListData, setUpdateTaskListData] = useState(false)
  const [updateLeaveListData, setUpdateLeaveListData] = useState(false)
  const [updateTimelogListData,setUpdateTimelogListData]=useState(false);
  const [deleteUser, setDeleteUser] = useState('');

  const [activeTask, setActiveTask] = useState()
  const [noOfProjects, setNoOfProjects] = useState()
  const [todayHours, setTodayHours] = useState()
  const [monthlyHours, setMonthlyHours] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [project, setProject] = useState({})
  const [employeeList, setEmployeeList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [sprintList, setSprintList] = useState([])
  const [tasktypeList, setTaskTypeList] = useState([])
  const [priorityList, setPriorityList] = useState([])
  const [tagList, setTagList] = useState([])
  const [metaDataList, setMetaDataList] = useState()
  const [particularLog, setParticularLog] = useState({
    task_id: "",
    time_log_id: "",
    date: "",
    start_time: "",
    end_time: "",
    description: "",
  })
  const [editorDescription, setEditorDescription] = useState(() => EditorState.createEmpty());
  
  const onEditorDescriptionChange = (editorState) => {
    setEditorDescription(editorState);
  };
  function toggle() {
    setmodal_standard(!modal_standard)
    setProject(" ")
  }

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      // getTimeLogData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  useEffect(() => { 
    if (customActiveTab === 2) {
      getTimeLogData();
    }
  }, [customActiveTab])

  // Employee View Api [Overview Page]
  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeView))
  }
  if (!isEmpty(data) && !isEmpty(data.employee) && isEmpty(employee) && updateListData)
   {
    setEmployee(data.employee)
    setPriorityTask(data.priority_task)
    setActiveTask(data.active_task)
    setNoOfProjects(data.no_of_project)
    setTodayHours(data.today_hours)
    setMonthlyHours(data.monthly_hours)
    setUpdateListData(false)
  }

  const getTaskMetaData = () => {
    setUpdateTaskMetaData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
  }

  // && !isEmpty(data.task_type)
  if (!isEmpty(data) && updateTaskMetaData) {
    // setMetaDataList(data)
    // setStatus(data.status)
    setUpdateTaskMetaData(false)
    data.status.map((item, index) => {
      selectedStatus.push({ label: item.column_name, value: item.id })
    })
    data.storyPoints.map((item, index) => {
      selectedStoryPoint.push({ label: item.name, value: item.id })
    })
    data.sprint.map((item, index) => {
      selectedSprint.push({ label: item.name, value: item.id })
    })
    data.employee.map((item, index) => {
      selectedAssignee_id.push({ label: item.name, value: item.id })
      selectedReporter_id.push({ label: item.name, value: item.id })
    })
    data.taskEpic.map((item, index) => {
      selectedTask_epic_id.push({ label: item.name, value: item.id })
    })
    data.taskType.map((item, index) => {
      selectedTasktype.push({ label: item.name, value: item.id })
    })
    data.priority.map((item, index) => {
      selectedPriority.push({ label: item.name, value: item.id })
    })
    data.project.map((item, index) => {
      selectedProject.push({ label: item.name, value: item.id })
    })
      }

  // if (!isEmpty(data) && !isEmpty(data.status) && updateListData) {
  //   setStatus(data.status)
  //   setUpdateListData(false)
  //   data.status.map((item, index) => {
  //     selectedStatus.push({ label: item.column_name, value: item.id })
  //   })
  // }

  // Employee Task Api [Tasks Page]

  // const getTaskData = () => {
  //   setUpdateTaskListData(true)
  //   dispatch(clearData())
  //   let filterData = {
  //     id: params.id,
  //   }
  //   dispatch(onGetList(filterData, props.router.navigate, api.employeeTasks))
  // }

  // if (!isEmpty(data) && !isEmpty(data.tasks) && isEmpty(employeeTask) && updateTaskListData) 
  // {
  //   setEmployeeTask(data.tasks)
  //   setUpdateListData(false)
  // }
  // Employee Project Api [Projects Page]
  const getProjectData = () => {
    setUpdateProjectListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeProjects))
  }
  if (!isEmpty(data) && !isEmpty(data.projects) && isEmpty(employeeProjects) && updateProjectListData)
   {
    setEmployeeProjects(data.projects)
    setUpdateProjectListData(false)
  }

  // Employee Leave Api [Leave Page]
  const getLeaveData = () => {
    setUpdateLeaveListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeLeaves))
  }

  if (!isEmpty(data) && !isEmpty(data.leaves) && isEmpty(employeeLeaves) && updateLeaveListData) 
    {
    setEmployeeLeaves(data.leaves)
    setUpdateLeaveListData(false)
    }
      // Employee Leave Api [Leave Page]

  const getTimelogData = () => {
    setUpdateTimelogListData(true)
    dispatch(clearData())
    let filterData = {
      id : params.id,
      date: moment(particularLog.date).format("DD-MM-YYYY"),
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeTimeLog))
    
  }

  if (!isEmpty(data) && !isEmpty(data.time_log)  && updateTimelogListData) 
    {
    setEmployeeTimeLog(data.time_log)
    setUpdateTimelogListData(false)
    }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      name: (project && project.name) || "",
      short_name: (project && project.short_name) || "",
      start_date: (project && project.start_date) || "",
      duration: (project && project.duration) || "",
      background_color: (project && project.background_color) || "",
      logo: (project && project.logo) || "",
      id: (project && project.id) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please Enter Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
      short_name: Yup.string()
        .required("Please Enter Short Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
      start_date: Yup.string().required("Please Enter Project Start Date"),
      // .max(new Date(), "Selected date cannot be in the future"),
      duration: Yup.string()
        .required("Please Enter Project Duration in hours")
        .matches(/^[0-9\b]+$/, "Enter Only Numbers"),
      // background_color: Yup.string().required("Please Select Project Background Color"),
      background_color: Yup.string()
        .required(messageConstant.BACKGROUND_COLOR_REQUIRED)
        .max(250, messageConstant.BACKGROUND_COLOR_INVALID),
      // profile_image: Yup.string().required("Please Enter Profile Image").max(15, "Must be less than  15 characters"),
    }),
    onSubmit: values => {
      // alert("Project has been Added")
      addFlag(true)
      const newData = {
        organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
        name: values["name"],
        short_name: values["short_name"],
        start_date: values["start_date"],
        duration: values["duration"],
        background_color: values["background_color"],
        logo: values["logo"],
      }
      dispatch(clearData())
      dispatch(onAddNewData(newData, props.router.navigate, api.projectCreate))
    },
  })

  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false
  }
  // Project Add
  if (!isEmpty(data) && !isEmpty(data.Project) && addFlag) {
    setAddFlag(false)
    validation.resetForm()
    toggle()
    dispatch(clearData())
    // getListData();
  }

  // leavevalidation
  const leavevalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      user_id: employee.user_id,
      leave_type: "",
      // html_description: "",
      start_date: "",
      end_date: "",
      status: "",
    },
    validationSchema: Yup.object({
      leave_type: Yup.string().required("Please Enter Leave Type"),
      status: Yup.string().required("Please Enter Status"),
      // html_description: Yup.string()
      //   .required("Please Enter Description")
      //   .max(250, "Must be less than  250 characters"),
      start_date: Yup.string().required("Please Enter Start Date"),
      // .max(15, "Must be less than  15 characters"),
      end_date: Yup.string().required("Please Enter End Date"),
      // .max(15, "Must be less than  15 characters"),
    }),
    onSubmit: values => {
      // alert("Leave has been Added")
      // console.log("values",values);
      const contentDescription = editorDescription.getCurrentContent();
      alert(contentDescription);
      const description = stateToHTML(contentDescription);
      // addFlag(true);
      const newData = {
        organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
        user_id: employee.user_id,
        leave_type: values["leave_type"],
        html_description: description,
        start_date: values["start_date"],
        end_date: values["end_date"],
        status: values["status"],
      }
      dispatch(clearData())
      dispatch(onAddNewData(newData, props.router.navigate, api.LeaveCreate))
    },
  })
  // console.log("leavevalidation",leavevalidation);

  if (!loading && leavevalidation.isSubmitting) {
    validation.isSubmitting = false
  }

  // Leave Add
  if (!isEmpty(data) && !isEmpty(data.leave))
  {
    // setAddFlag(false)
    leavevalidation.resetForm()
    // toggle()
    tog_standard2()
    dispatch(clearData())
    // windows.location.reload();
    // getListData()
  }

  // timevalidation
  const [editFlag, setEditFlag] = useState(false)

  // console.log("particularLog",particularLog.description);
  const timevalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      time_log_id: particularLog.time_log_id ,
      task_id: particularLog.task_id ,
      date: particularLog.date ,
      start_time: particularLog.start_time ,
      end_time: particularLog.end_time ,
      description: particularLog.description,
    },
    validationSchema: Yup.object({
      start_time: Yup.string().required("Please Enter Start Time"),
      end_time: Yup.string().required("Please Enter End Time"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: values => {
      setEditFlag(true);
      const contentDescription = editorDescription.getCurrentContent();
      const description = stateToHTML(contentDescription);
      console.log(description);
      const updateData = {
        time_log_id: particularLog.time_log_id,
        task_id: particularLog.task_id,
        date: particularLog.date,
        start_time: values["start_time"],
        end_time: values["end_time"],
        description: description,
      }
      dispatch(clearData())
      dispatch(onUpdateData(updateData, props.router.navigate, api.TimeLogEdit))
    },
  })
  // console.log("timevalidation",timevalidation);
  if (!loading && timevalidation.isSubmitting) {
    timevalidation.isSubmitting = false
  }

  // TimeLog Edit 
  if (!isEmpty(data) && !isEmpty(data.taskTimeLog) && editFlag) {
    setEditFlag(false)
    timevalidation.resetForm()
    TimelogToggle()
    dispatch(clearData())
    getTimelogData()
  }

  const taskvalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      project_id: "",
      status: "",
      summary: "",
      assignee_id: "",
      reporter_id: "",
      story_points: "",
      sprint_id: "",
      task_epic_id: "",
      task_type: "",
      priority: "",
      // description: "",
      tags: "",
      estimated_time: "",
    },
    validationSchema: Yup.object({
      // description: Yup.string()
      //   .required("Please Enter Description"),
      summary: Yup.string(),
      assignee_id: Yup.string().required("Please Enter Assignee"),
      reporter_id: Yup.string().required("Please Enter Reporter"),
      sprint_id: Yup.string().required("Please Enter Sprint"),
      task_epic_id: Yup.string().required("Please Enter Task Epic"),
      task_type: Yup.string().required("Please Enter Task Type"),
      priority: Yup.string().required("Please Enter Priority"),
      // tags: Yup.string().required("Please Enter Tags"),
    }),
    onSubmit: values => {
      const contentDescription = editorDescription.getCurrentContent();
      const description = stateToHTML(contentDescription);
      addFlag(true);
      const addData = {
        organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
        project_id: values["project_id"],
        status: values["status"],
        summary: values["summary"],
        assignee_id: values["assignee_id"],
        reporter_id: values["reporter_id"],
        story_points: values["story_points"],
        sprint_id: values["sprint_id"],
        task_epic_id: values["task_epic_id"],
        task_type: values["task_type"],
        priority: values["priority"],
        description: description,
        tags: values["tags"],
        estimated_time: values["estimated_time"],
      }
      dispatch(clearData())
      dispatch(onAddNewData(addData, props.router.navigate, api.TaskCreate))
    },
  })

  if (!loading && taskvalidation.isSubmitting) {
    taskvalidation.isSubmitting = false
  }

  // Task Add
  if (!isEmpty(data) && !isEmpty(data.task) && addFlag) {
    setAddFlag(false)
    taskvalidation.resetForm()
    TimelogToggle()
    dispatch(clearData())
    getListData()
  }

  //delete Timelog
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteid, setDeleteId] = useState()
  const onClickDelete = users => {
    setDeleteId(users)
    // console.log("users", users)
    setDeleteModal(true)
  }
  const handleDeleteUser = () => {
    console.log("users", deleteid)
    if (deleteid) {
      console.log("abc");
      const deleteData = {
        task_time_log_id : deleteid,
      }
      dispatch(clearData())
      dispatch(onUpdateData(deleteData, props.router.navigate, api.TaskTimeLogDelete))
      // setDeleteModal(false);
    }
  }
  if (!isEmpty(data) && !isEmpty(data.task_time_log) && isEmpty(deleteUser)) {
    setDeleteModal(false)
    setDeleteUser(data.task_time_log)
    window.location.reload();
  }

  const [selectedDate, setSelectedDate] = useState(new Date())
  // Function to handle Date for date of birth and joining date
  const handleChangeForTimeLog = event => {
    const selected = new Date(event.target.value)
    // console.log("selected", selected)
    const today = new Date()
    // console.log(today);
    if (selected <= today) {
      setSelectedDate(selected)
    }
    getTimeLogData(selected)
  }

  const getTimeLogData = e => {
    dispatch(clearData())
    setEmployeeTimeLog("")
    let filterData = {
      id: params.id,
      date: moment(e).format("DD-MM-YYYY"),
    }
    dispatch(onGetData(filterData, props.router.navigate, api.employeeTimeLog))
  }
  if (!isEmpty(data) && !isEmpty(data.time_log) && isEmpty(employeeTimeLog)) {
    setEmployeeTimeLog(data.time_log)
  }
  // useEffect(() => {
  //   if (data && data.tags && data.tags.length > 0) {
  //     setTagDataList(data.tags);
  //   }
  // }, [data]);

  const getTagData = e => {
    dispatch(clearData())
    let filterData = {
      tag: e.target.value,
    }
    dispatch(onGetData(filterData, props.router.navigate, api.TagAutoComplete))
  }
  const[tagDatalist,setTagDataList]=useState([]);
  if (!isEmpty(data) && !isEmpty(data.tags)) {
    // console.log(data.tags);
    // setTagDataList(data.tags);
  }
  // Function to handle Date for date of birth and joining date
  const handleChange = event => {
    const selected = new Date(event.target.value)
    const today = new Date()
    if (selected <= today) {
      setSelectedDate(selected)
    }
  }

  // Options for select
  const leaveOptions = [
    { value: "Single Full Day", label: "Single Full Day" },
    { value: "Single Half Day", label: "Single Half Day" },
    { value: "Multiple Days", label: "Multiple Days" },
  ]

  // Handle select change
  const handleLeave = (selectedLeave, name) => {
    leavevalidation.setFieldValue("leave_type",selectedLeave)
  }
  const[selectedLeaveStatus,setSelectedLeaveStatus]=useState();

  const handleLeaveStatus = (selectedLeaveStatus, name) => {
    // leavevalidation.values[name] = selectedLeaveStatus
    leavevalidation.setFieldValue("status",selectedLeaveStatus)
  }

  const handleTask = (selectedStatus) => {
    taskvalidation.setFieldValue("status",selectedStatus)
  }
  const handleStoryPoint = (selectedStoryPoint) => {
    taskvalidation.setFieldValue("story_points",selectedStoryPoint)
  }
  const handleSprint = (selectedSprint) => {
    taskvalidation.setFieldValue("sprint_id",selectedSprint)
  }
  const handleAssignee_id = (selectedAssignee_id) => {
    taskvalidation.setFieldValue("assignee_id",selectedAssignee_id)
  }
  const handleReporter_id = (selectedReporter_id ) => {
    taskvalidation.setFieldValue("reporter_id",selectedReporter_id)
  }
  const handleTask_epic_id = (selectedTask_epic_id ) => {
    taskvalidation.setFieldValue("task_epic_id",selectedTask_epic_id)
  }
  const handleTaskType = (selectedTasktype ) => {
    taskvalidation.setFieldValue("task_type",selectedTasktype)
  }
  const handlePriority = (selectedPriority ) => {
    taskvalidation.setFieldValue("priority",selectedPriority)
  }
  const handleProject = (selectedProject ) => {
    taskvalidation.setFieldValue("project_id",selectedProject)
  }
  // Options for select
  const statusOptions = [
    // { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    // { value: "Rejected", label: "Rejected" },
  ]

  const [selectedTask, setSelectedTask] = useState("")
  const handletaskstatus = (selectedTask, name) => {
    taskvalidation.values[name] = selectedTask
  }

  function TimelogToggle(id, task_id, date, start_time, end_time, description) {
    setmodal_standard4(!modal_standard4)
    if (!isEmpty(description)) {
      const blocksFromHTML = convertFromHTML(description);
      const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
      );
      // console.log(contentState);
      setEditorDescription(EditorState.createWithContent(contentState));
  }
    setParticularLog({
      time_log_id: id,
      task_id: task_id,
      date: date,
      start_time: start_time,
      end_time: end_time,
      description: description,
    
    })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    validation.setFieldValue("profile_image",file)
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Employee" breadcrumbItem="Employee View" />
          <Card>
            <CardBody>
              <div className="d-flex align-items-center gap-4">
                <div className="avatar-md">
                  <img
                    src={employee.profile_image}
                    alt=""
                    className="rounded-circle avatar-md"
                  />
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h2 className="text-truncate">
                    <Link
                      to="/:organization_slug/organization/project/view"
                      className="text-dark"
                    >
                      {employee && employee.name}
                    </Link>
                  </h2>
                  <h3 className="text-muted font-size-18 mb-0">
                    {employee && employee.designation}
                  </h3>
                </div>
              </div>
            </CardBody>
          </Card>
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === "1" })}
                onClick={() => {toggleCustom("1")}}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Overview</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === "2" })}
                onClick={() => {
                  toggleCustom("2")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-clock"></i>
                </span>
                <span className="d-none d-sm-block">Time</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === "3" })}
                onClick={() => {
                  toggleCustom("3")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-tasks"></i>
                </span>
                <span className="d-none d-sm-block">Tasks</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === "4" })}
                onClick={() => {
                  toggleCustom("4")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-briefcase"></i>
                </span>
                <span className="d-none d-sm-block">Projects</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === "5" })}
                onClick={() => {
                  toggleCustom("5")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="d-none d-sm-block">Leave</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1">
              <OverviewTab />
            </TabPane>
            <TabPane tabId="2">
            <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      {/* <Label>Date:</Label> */}

                      <InputGroup>
                        <Row className="align-items-center mb-3">
                          <Col md="auto">
                            <h5>Date</h5>
                          </Col>
                          <Col>
                            {" "}
                            <DatePicker1
                              // label="Date"
                              handleChange={handleChangeForTimeLog}
                              name="start_date"
                              validation={validation}
                              value={selectedDate}
                            />
                          </Col>
                        </Row>
                      </InputGroup>
                    </Col>
                  </Row>

                  {employeeTimeLog ? (
                    employeeTimeLog.map((timelog, index) => {
                      return (
                        <ul
                          className="verti-timeline list-unstyled mt-4"
                          key={index}
                        >
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle font-size-18  " />
                            </div>
                            <div className="me-md-3">
                              <div className="d-block d-md-flex align-items-start gap-3">
                              <h5 className="font-size-14 text-nowrap">
                                  <span>{timelog.start_time} - {timelog.end_time}</span>
                                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                </h5>
                                <div>
                                </div>
                                <div className="border rounded-2 p-3">
                                  <h5 className="font-size-14 mb-0">
                                    {timelog.task_name}
                                  </h5>
                                  <button
                                    className=" btn-sm btn btn-success mt-2"
                                    style={{
                                      backgroundColor:
                                        timelog && timelog.background_color,
                                    }}
                                  >
                                    {timelog.project_name}
                                  </button>
                                  <div className="mt-3">
                                    {timelog && timelog.task_attachment.map((image,index) =>{
                                      // console.log(image);
                                      return(
                                        <img
                                        onClick={() => {
                                          window.open(image);                        
                                        }}
                                        key={index}
                                        src={image}
                                        alt=""
                                        className="rounded avatar-md"
                                        />
                                      );
                                    })}
                                  </div>
                                  <Row className="mt-3 align-items-center">
                                  <Col md="12" xl="12">
                                      <div className="border rounded p-2">
                                        <p className="text-muted font-size-14 mb-0">
                                          {timelog.description}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col md="12" xl="12" className="mt-2 mt-md-0">
                                      <div className="text-end text-md-start">
                                        <Link
                                          to="#"
                                          className="text-success"
                                          onClick={() => {
                                            TimelogToggle(
                                              timelog.id,
                                              timelog.task_id,
                                              timelog.date,
                                              timelog.start_time,
                                              timelog.end_time,
                                              timelog.description
                                            )
                                          }}
                                          data-toggle="modal"
                                          data-target="#myModal"
                                        >
                                          <i className="mdi mdi-pencil font-size-18"></i>
                                        </Link>
                                        <div className="vr mx-2 mx-md-1"></div>
                                        <Link to="#" className="text-danger" onClick={() => {
                                          const userData = timelog.id;
                                          onClickDelete(userData);
                                        }}>
                                          <i className="mdi mdi-delete font-size-18"></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      )
                    })
                  ) : (
                    <div>
                      <h4>No Record Found</h4>
                    </div>
                  )}
                </CardBody>
              </Card>
             {/* <TimeLogTab /> */}
            </TabPane>
            <TabPane tabId="3">
            <TasksTab />
            </TabPane>
            {/* Project Page Start Here */}
            <TabPane tabId="4">
              <ProjectTab />
            </TabPane>
            {/* Leave Page Start Here */}
            <TabPane tabId="5">
              <LeaveTab />
            </TabPane>
          </TabContent>
        </Container>
      </div>

      {/*Create Project Modal backdrop="static"*/}
      <Modal
      size="lg"
        backdrop="static"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard1()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {!!isEdit ? "Edit Project" : "Add Project"}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggle()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="modal-body">
            <Row>
              <Col sm={12} md="6">
                <Name validation={validation} />
                </Col>
              <Col sm={12} md="6">
                <ShortName validation={validation} />
                </Col>
              <Col sm={12} md="6">
              <DatePicker
                label="Start Date"
                onChange={handleChange}
                name="start_date"
                validation={validation}
              />
              </Col>
              <Col sm={12} md="6">
              <Duration validation={validation} />
              </Col>
              <Col sm={12} md="6">
              <BackGroundColor validation={validation} />
              </Col>
              <Col sm={12} md="6">
              <label>Logo</label>
              <Input 
                  name="logo"
                  type="file" 
                  accept="image/*" 
                  onChange={handleImageChange} />
              {/* <Logo validation={validation} /> */}
              </Col>
            </Row>
            </div>
          </Form>
        
        <div className="modal-footer">
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <LoginButton buttonName="Submit" />{" "}
              <AnotherButton
                className="btn btn-secondary"
                onClick={e => {
                  e.preventDefault()
                  tog_standard()
                }}
                buttonName="Cancel"
              />
            </div>
          </Form>
        </div>
      </Modal>

      {/*create Leave Modal */}
      <Modal
      size="lg"
        isOpen={modal_standard2}
        toggle={() => {
          tog_standard2()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Leave
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              // console.log("onSubmit",leavevalidation,e);
              leavevalidation.handleSubmit()
              return false
            }}
          >
            <div className="modal-body">
            <Row>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Leave Type"
                  // value={leavevalidation.values["leave_type"]}
                  options={leaveOptions}
                  name="leave_type"
                  className="custom-select"
                  validation={leavevalidation}
                  // onChange={handleLeave}
                  onChange={(selectedLeave) => handleLeave(selectedLeave, 'leave_type')}
                />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Status"
                  // value={selectedStatus}
                  options={statusOptions}
                  name="status"
                  className="custom-select"
                  validation={leavevalidation}
                  // onChange={handleLeaveStatus}
                  onChange={(selectedStatus) => handleLeaveStatus(selectedStatus, 'status')}
                  // defaultValue="Pending"
                  // styles={customStyles}
                />
              </Col>
              <Col sm={12} md="6">
                <DatePicker
                label="Start Date"
                name="start_date"
                validation={leavevalidation}
              />
              </Col>
              <Col sm={12} md="6">
              <DatePicker
                label="End Date"
                name="end_date"
                validation={leavevalidation}
              />
              </Col>
              <Col sm={12} className="mb-3">
                {/* <Label>Description</Label>
                <Description
                  placeholder="Description For Leave..."
                  validation={leavevalidation}
                  name="html_description"
                /> */}
                <Label>Leave Description</Label> 
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Place Your Content Here..."
                    name="html_description"
                    value={leavevalidation.values.html_description}
                    editorState={editorDescription}
                    onEditorStateChange={onEditorDescriptionChange}
                  />
              </Col>
            </Row>
            </div>
            <div className="modal-footer">
              <LoginButton buttonName="Submit" />
              <AnotherButton
                className="btn btn-secondary"
                onClick={e => {
                  e.preventDefault()
                  tog_standard2()
                }}
                buttonName="Cancel"
              />
            </div>
          </Form>
        
      </Modal>

      {/*Edit DELETE Timelog Modal */}
        <Modal
        size="lg"
        isOpen={modal_standard4}
        toggle={() => {
          TimelogToggle()
        }}
      >
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            timevalidation.handleSubmit()
            return false
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Edit TimeLog
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard4(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              {/* <DatePicker
                          label="Date"
                          name="date"
                          values={particularLog.date}
                          validation={timevalidation}
                        /> */}
              <Col md="6">
                <TimePicker
                  validation={timevalidation}
                  label="Start Time"
                  name="start_time"
                />
              </Col>
              <Col md="6">
                <TimePicker
                  label="End Time"
                  name="end_time"
                  validation={timevalidation}
                />
              </Col>
              <Col md="12">
              <Label>Description</Label>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Write Timelog Description Here..."
                    editorState={editorDescription}
                    onEditorStateChange={onEditorDescriptionChange}
                  />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <AnotherButton
              className="btn btn-secondary"
              onClick={e => {
                e.preventDefault()
                TimelogToggle()
              }}
              buttonName="Cancel"
            />
            <LoginButton buttonName="Submit" />
          </div>
        </Form>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        // modalTitle="User Delete"
        onCloseClick={() => setDeleteModal(false)}
        ModalName={employee.name + "'s Time Log"}
      />

      {/*create Task Modal */}
      <Modal
        size="lg"
        isOpen={modal_standard1}
        toggle={() => {
          tog_standard1()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Task
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard1(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              taskvalidation.handleSubmit()
              return false
            }}
          >
            <div className="modal-body">
            <Row>
              <Col sm={12} md="6">
                <Label>Summary</Label>
                <Description
                  placeholder="Task Summary"
                  validation={taskvalidation}
                  name="summary"
                />
              </Col>
              <Col sm={12} md="6">
                <CustomSelect
                      label="Report To"
                      options={selectedReporter_id}
                      name="reporter_id"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleReporter_id}
                    />
              </Col>
              {/* Assigne */}
              <Col sm={12} md="6">
                    <CustomSelect
                      label="Assigne To"
                      options={selectedAssignee_id}
                      name="assignee_id"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleAssignee_id}
                    />
                <Button type="button" color="link" name="assignee_id"
                 onClick={() =>{
                  taskvalidation.setFieldValue("assignee_id", employee.id);
                }}
                  >
                    Assign to me
                  </Button>
              </Col>
              {/* Story Point */}
              <Col sm={12} md="6">
              <CustomSelect
                      label="Story Point"
                      // value={selectedValue}
                      options={selectedStoryPoint}
                      name="story_points"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleStoryPoint}
                    />
              </Col>
            </Row>
            <Row className="mt-3">
              {/* Status */}
              <Col sm={12} md="6" className="mb-3">
                {/* <Label>Status</Label> */}
                      <CustomSelect
                      label="Status"
                      // value={selectedValue}
                      options={selectedStatus}
                      name="status"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleTask}
                    />
              </Col>
              {/* Sprint */}
              <Col sm={12} md="6" className="mb-3">
              <CustomSelect
                      label="Sprint"
                      options={selectedSprint}
                      name="sprint_id"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleSprint}
                    />
              </Col>
              {/* Epic */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                      label="Task Epic"
                      options={selectedTask_epic_id}
                      name="task_epic_id"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleTask_epic_id}
                    />
              </Col>
              {/* Tag */}
              <Col sm={12} md="6" className="mb-3">
               
              </Col>
              {/* Task Type */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                      label="Task Type"
                      options={selectedTasktype}
                      name="task_type"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleTaskType}
                    />
              </Col>
              {/* Priority */}
              <Col sm={12} md="6" className="mb-3">
                  <CustomSelect
                      label="Priority"
                      options={selectedPriority}
                      name="priority"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handlePriority}
                    />
              </Col>
              {/* Project */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                      label="Project"
                      options={selectedProject}
                      name="project_id"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleProject}
                    />
              </Col>
              <Col sm={12} md="6">
              <EstimatedTime validation={taskvalidation} />
              </Col>
              <Col sm={12}>
                <Label>Description</Label>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Place Your Content Here..."
                    // name="description"
                    // value={taskvalidation.values.description}
                    editorState={editorDescription}
                    onEditorStateChange={onEditorDescriptionChange}
                  />
              </Col>
            </Row>
            </div>
            <div className="modal-footer">
              <AnotherButton
                className="btn btn-secondary"
                onClick={e => {
                  e.preventDefault()
                  tog_standard1()
                }}
                buttonName="Cancel"
              />
              <LoginButton validation={taskvalidation} buttonName="Submit" />
            </div>
          </Form>
        
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(Employee)
