import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Label,
    Collapse,
    Input,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    InputGroup,
    Toast,
    ToastHeader,
    ToastBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { createSelector } from "reselect";
import { activityDataDashboard } from "common/data";
import { Link } from "react-router-dom";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import { handleSearchData } from "components/Common/searchFile";
const Dashboard = props => {
    const selectDashboardState = (state) => state.Dashboard;
    const DashboardProperties = createSelector(
        selectDashboardState,
        (dashboard) => ({
            chartsData: dashboard.chartsData
        })
    );

    //meta title
    document.title = "Dashboard | Project Naya";
    const [selectedGroup, setselectedGroup] = useState(null);
    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup);
    }
    const optionGroup = [
        {
            label: "Picnic",
            options: [
                { label: "Mustard", value: "Mustard" },
                { label: "Ketchup", value: "Ketchup" },
                { label: "Relish", value: "Relish" }
            ]
        },
        {
            label: "Camping",
            options: [
                { label: "Tent", value: "Tent" },
                { label: "Flashlight", value: "Flashlight" },
                { label: "Toilet Paper", value: "Toilet Paper" }
            ]
        }
    ];
    const [col5, setcol5] = useState(false);
    const [col6, setcol6] = useState(false);
    const [col7, setcol7] = useState(false);

    const t_col5 = () => {
        setcol5(!col5);
    };

    const t_col6 = () => {
        setcol6(!col6);
    };

    const t_col7 = () => {
        setcol7(!col7);
    };

    const hendeleSearch = (ele) => {
        handleSearchData({ setState: setMessages, data: chatData, item: ele.value })
    }
    const [toast, setToast] = useState(true);
    const toggleToast = () => {
        setToast(!toast);
    };
    const [toggleSwitchSize, settoggleSwitchSize] = useState(true);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />
                    <Row>
                        <Col xl="12" className="m-auto">
                            <Toast isOpen={toast} className="w-100 shadow-none border-warning bg-transparent mb-4">
                                <ToastBody>
                                    <ToastHeader toggle={toggleToast}></ToastHeader>
                                    Hello, world! This is a toast message.
                                </ToastBody>
                            </Toast>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Row className="mb-4">
                                <Col xl="2">
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            className="form-control rounded mb-3 mb-xl-0" 
                                            placeholder="Search ..."
                                            aria-label="Recipient's username"
                                            onChange={(e) => hendeleSearch(e.target)}
                                        />
                                        <i className="mdi mdi-magnify font-size-16 position-absolute end-0 top-0 bottom-0 d-flex align-items-center m-auto pe-2 z-index"></i>
                                    </InputGroup>
                                </Col>
                                <Col xl="auto" className="mb-3 mb-xl-0">
                                    <div className="avatar-group">
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-primary text-white font-size-16">K</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-danger text-white font-size-16">M</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-warning text-white font-size-16">N</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-success text-white font-size-16">A</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-info text-white font-size-16">+2</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="auto" xl="auto" className="mb-3 mb-md-0">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Epic <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xs="auto" xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Type <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Project Name
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Project Name
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xs="auto"  xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Quick Filters <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Only My Issues
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Recently Updated
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <div className="dropdown-divider"></div>
                                            <DropdownItem href="#">
                                                <div
                                                    className="form-check form-switch"

                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customSwitchsizesm"
                                                        defaultChecked
                                                        onClick={e => {
                                                            settoggleSwitchSize(!toggleSwitchSize)
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customSwitchsizesm"
                                                    >
                                                        Quick Filters
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="6">
                            <Card>
                                <CardBody>
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">Todo Task</h4>
                                        <div className="page-title-right">
                                            <button
                                                type="button" className="btn btn-primary">
                                                <i className="bx bx-plus font-size-14 align-middle me-1"></i>
                                               Todo
                                            </button>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xl="6">
                                            <div className="mb-3">
                                                <Select
                                                    value={selectedGroup}
                                                    onChange={() => {
                                                        handleSelectGroup();
                                                    }}
                                                    options={optionGroup}
                                                    className="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col xl="6">
                                    <Card>
                                        <CardBody>
                                            <h5 className="font-size-14">Project Name</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="6">
                                    <Card>
                                        <CardBody>
                                            <h5 className="font-size-14">All task</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="6">
                                    <Card>
                                        <CardBody>
                                            <h5 className="font-size-14">By Due</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="6">
                                    <Card>
                                        <CardBody>
                                            <h5 className="font-size-14">By Type</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="6">
                            <Card>
                                <CardBody>
                                    <h4 className="mb-3 font-size-18">Today Time Sheet</h4>
                                    <ul className="verti-timeline list-unstyled">
                                        <li className="event-list">
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle font-size-18  " />
                                            </div>
                                            <div className="me-3">
                                                <div className="d-block d-md-flex align-items-start gap-2">
                                                    <h5 className="font-size-14" onClick={t_col5} style={{ cursor: "pointer" }}>
                                                        9:30
                                                        <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                    </h5>
                                                    <div>
                                                        <h5 className="font-size-14">Project Name</h5>
                                                        <h5 className="font-size-14">Task Title</h5>
                                                        <div className="d-flex align-items-center flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0 d-flex mt-3">
                                                <div className="flex-grow-1">
                                                    <Collapse isOpen={col5}>
                                                        <Card className="border">
                                                            <CardBody>
                                                                <ul className="verti-timeline list-unstyled">
                                                                    <li className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            <i className="bx bx-right-arrow-circle font-size-18  " />
                                                                        </div>
                                                                        <div className="flex-shrink-0 d-flex">
                                                                            <div className="me-3">
                                                                                <h5 className="font-size-14">
                                                                                    9:30
                                                                                    <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                                                </h5>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div>
                                                                                    <h5 className="font-size-14">Project Name</h5>
                                                                                    <h5 className="font-size-14">Task Title</h5>
                                                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                                                        <div className="me-2">
                                                                                            <img src={avatar5} alt="" className="rounded avatar-md" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="w-sm btn btn-primary "
                                                                                            >
                                                                                                Bilevel
                                                                                            </button>
                                                                                            <div className="mt-2">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="w-sm  btn btn-danger"
                                                                                                >
                                                                                                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            <i className="bx bx-right-arrow-circle font-size-18  " />
                                                                        </div>
                                                                        <div className="flex-shrink-0 d-flex">
                                                                            <div className="me-3">
                                                                                <h5 className="font-size-14">
                                                                                    9:40
                                                                                    <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                                                </h5>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div>
                                                                                    <h5 className="font-size-14">Project Name</h5>
                                                                                    <h5 className="font-size-14">Task Title</h5>
                                                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                                                        <div className="me-2">
                                                                                            <img src={avatar5} alt="" className="rounded avatar-md" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="w-sm btn btn-primary "
                                                                                            >
                                                                                                Bilevel
                                                                                            </button>
                                                                                            <div className="mt-2">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="w-sm  btn btn-danger"
                                                                                                >
                                                                                                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="event-list">
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle font-size-18  bx-fade-right" />
                                            </div>
                                            <div className="me-3">
                                                <div className="d-block d-md-flex align-items-start gap-2">
                                                    <h5 className="font-size-14" onClick={t_col6} style={{ cursor: "pointer" }}>
                                                        10:30
                                                        <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                    </h5>
                                                    <div>
                                                        <h5 className="font-size-14">Project Name</h5>
                                                        <h5 className="font-size-14">Task Title</h5>
                                                        <div className="d-flex align-items-center flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0 d-flex mt-3">
                                                <div className="flex-grow-1">
                                                    <Collapse isOpen={col6}>
                                                        <Card className="border">
                                                            <CardBody>
                                                                <ul className="verti-timeline list-unstyled">
                                                                    <li className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            <i className="bx bx-right-arrow-circle font-size-18  " />
                                                                        </div>
                                                                        <div className="flex-shrink-0 d-flex">
                                                                            <div className="me-3">
                                                                                <h5 className="font-size-14">
                                                                                    10:30
                                                                                    <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                                                </h5>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div>
                                                                                    <h5 className="font-size-14">Project Name</h5>
                                                                                    <h5 className="font-size-14">Task Title</h5>
                                                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                                                        <div className="me-2">
                                                                                            <img src={avatar5} alt="" className="rounded avatar-md" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="w-sm btn btn-primary "
                                                                                            >
                                                                                                Bilevel
                                                                                            </button>
                                                                                            <div className="mt-2">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="w-sm  btn btn-danger"
                                                                                                >
                                                                                                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            <i className="bx bx-right-arrow-circle font-size-18  " />
                                                                        </div>
                                                                        <div className="flex-shrink-0 d-flex">
                                                                            <div className="me-3">
                                                                                <h5 className="font-size-14">
                                                                                    10:40
                                                                                    <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                                                </h5>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div>
                                                                                    <h5 className="font-size-14">Project Name</h5>
                                                                                    <h5 className="font-size-14">Task Title</h5>
                                                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                                                        <div className="me-2">
                                                                                            <img src={avatar5} alt="" className="rounded avatar-md" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="w-sm btn btn-primary "
                                                                                            >
                                                                                                Bilevel
                                                                                            </button>
                                                                                            <div className="mt-2">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="w-sm  btn btn-danger"
                                                                                                >
                                                                                                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </li>
                                        
                                        <li className="event-list">
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle font-size-18  " />
                                            </div>
                                            <div className="me-3">
                                                <div className="d-block d-md-flex align-items-start gap-2">
                                                    <h5 className="font-size-14" onClick={t_col7} style={{ cursor: "pointer" }}>
                                                        11:30
                                                        <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                    </h5>
                                                    <div>
                                                        <h5 className="font-size-14">Project Name</h5>
                                                        <h5 className="font-size-14">Task Title</h5>
                                                        <div className="d-flex align-items-center flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                            <div className="me-2">
                                                                <img src={avatar5} alt="" className="rounded avatar-md" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0 d-flex mt-3">
                                                <div className="flex-grow-1">
                                                    <Collapse isOpen={col7}>
                                                        <Card className="border">
                                                            <CardBody>
                                                                <ul className="verti-timeline list-unstyled">
                                                                    <li className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            <i className="bx bx-right-arrow-circle font-size-18  " />
                                                                        </div>
                                                                        <div className="flex-shrink-0 d-flex">
                                                                            <div className="me-3">
                                                                                <h5 className="font-size-14">
                                                                                    11:30
                                                                                    <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                                                </h5>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div>
                                                                                    <h5 className="font-size-14">Project Name</h5>
                                                                                    <h5 className="font-size-14">Task Title</h5>
                                                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                                                        <div className="me-2">
                                                                                            <img src={avatar5} alt="" className="rounded avatar-md" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="w-sm btn btn-primary "
                                                                                            >
                                                                                                Bilevel
                                                                                            </button>
                                                                                            <div className="mt-2">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="w-sm  btn btn-danger"
                                                                                                >
                                                                                                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            <i className="bx bx-right-arrow-circle font-size-18  " />
                                                                        </div>
                                                                        <div className="flex-shrink-0 d-flex">
                                                                            <div className="me-3">
                                                                                <h5 className="font-size-14">
                                                                                    11:40
                                                                                    <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2" />
                                                                                </h5>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div>
                                                                                    <h5 className="font-size-14">Project Name</h5>
                                                                                    <h5 className="font-size-14">Task Title</h5>
                                                                                    <div className="d-sm-flex align-items-center justify-content-between">
                                                                                        <div className="me-2">
                                                                                            <img src={avatar5} alt="" className="rounded avatar-md" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="w-sm btn btn-primary "
                                                                                            >
                                                                                                Bilevel
                                                                                            </button>
                                                                                            <div className="mt-2">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="w-sm  btn btn-danger"
                                                                                                >
                                                                                                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    
                </Container>
            </div>
        </React.Fragment>
    );
};

Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);