import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { Col, Container, Form, Row } from "reactstrap"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"
import ReCAPTCHA from "react-google-recaptcha"

import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import { clearData, loginUser } from "store/AppMaster/actions"
import api from "../../constants/api"
import Email from "components/Common/PageComponent/Email"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import Otp from "components/Common/PageComponent/Otp"
import messageConstant from "constants/message"
import LoginButton from "components/Common/PageComponent/LoginButton"
import { isImageJpgOrPng, isImageSizeValid } from "common/commonFunction"
import configJs from "../../config"

const Login = props => {
  document.title = "Login | " + process.env.REACT_APP_NAME
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [isEmailScreen, setIsEmailScreen] = useState(true)
  const [email, setEmail] = useState("")
  const [isOtpScreen, setIsOtpScreen] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")
  const dispatch = useDispatch()
  const recaptchaRef = useRef(null)
  const navigate = useNavigate()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(messageConstant.EMAIL_REQUIRED)
        .email(messageConstant.EMAIL_INVALID)
        .max(255),
    }),
    onSubmit: async values => {
      if (recaptchaRef.current) {
        const token = await recaptchaRef.current.executeAsync()
        setRecaptchaResponse(token)
      }

      const loginData = {
        email: values.email,
      }
      dispatch(loginUser(loginData, props.router.navigate, api.checkEmail))
      validation.resetForm()
    },
  })

  const sendOtp = email => {
    const loginData = {
      email: email,
    }
    dispatch(clearData())
    dispatch(loginUser(loginData, props.router.navigate, api.sendOtp))
  }

  const validationOtp = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required(messageConstant.OTP_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.OTP_INVALID)
        .max(6)
        .min(6),
    }),
    onSubmit: async values => {
      if (recaptchaRef.current) {
        const token = await recaptchaRef.current.executeAsync()
        setRecaptchaResponse(token)
      }

      const loginData = {
        email: email,
        otp: values.otp,
        gRecaptchaResponse: recaptchaResponse,
      }
      dispatch(clearData())
      dispatch(loginUser(loginData, props.router.navigate, api.login))
    },
  })

  useEffect(() => {
    if (
      !isEmpty(data) &&
      (data.STATUS_CODE === 200 || data.STATUS_CODE === 400) &&
      isEmailScreen
    ) {
      setIsEmailScreen(false)
      setEmail(data.DATA.email)
      if (data.STATUS_CODE === 200) {
        sendOtp(data.DATA.email)
        setIsOtpScreen(true)
      }
    }
    if (!isEmpty(data) && !isEmpty(data.userDetails)) {
      validationOtp.resetForm()
      setRecaptchaResponse("")
    }
  }, [data, isEmailScreen, validationOtp])

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={9}>
              <CarouselPage />
            </Col>
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block card-logo">
                        <img
                          src={logodark}
                          className="logo-dark-element m-auto"
                          alt="logo-dark"
                        />
                        <img
                          src={logolight}
                          alt="logo-light"
                          className="logo-light-element m-auto"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to {process.env.REACT_APP_NAME}.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            if (isEmailScreen) {
                              validation.handleSubmit()
                            } else {
                              validationOtp.handleSubmit()
                            }
                            return false
                          }}
                        >
                          {isEmailScreen ? (
                            <Email validation={validation} />
                          ) : (
                            <Otp validation={validationOtp} />
                          )}
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={
                              configJs.google_recaptcha
                                .REACT_RECAPTCHA_SITE_KEY
                            }
                          />
                          <LoginButton buttonName="Log In" />
                        </Form>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p>
                        Don't have an account ?
                        <Link className="fw-medium text-primary" to="/signup">
                          Signup now
                        </Link>
                      </p>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        {new Date().getFullYear()} ©{" "}
                        {process.env.REACT_APP_NAME}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
