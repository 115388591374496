import PropTypes from 'prop-types'
import { FormFeedback, Input } from "reactstrap"
import React from "react";
const Description = ({ validation , placeholder , name }) => {

    return (
        <div className="mb-3">
            <Input
                name={name}
                className="form-control"
                placeholder={placeholder}
                type="textarea"
                onChange={validation.handleChange}
                value={validation.values[name]}
                invalid={validation.errors[name]}
                maxLength="225"
            />
            {validation.errors[name] ? (
                <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
            ) : null}
        </div>

    )
}

Description.propTypes = {
    validation: PropTypes.object,
}

export default Description
