import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Col, Container, Form, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { isEmpty } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//Import Card
import CardEmployee from "./CardList";

import configJs from '../../config';
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../components/Common/TableContainer';
import {
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
    getData as onGetData,
    UpdateFormData as onUpdateFormData,
    clearData
} from "store/AppMaster/actions";
import api from '../../constants/api';
import Paginations from "components/Common/Pagination";
import DeleteModal from "components/Common/DeleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoginButton from "components/Common/PageComponent/LoginButton";
import AnotherButton from "components/Common/PageComponent/AnotherButton";
import Name from "components/Common/PageComponent/Name";
import Email from "components/Common/PageComponent/Email";
import Mobile from "components/Common/PageComponent/Mobile";
import DatePicker from "components/Common/PageComponent/DatePicker";
import Salary from "components/Common/PageComponent/Salary";
import labelConstant from "constants/label";
import CustomSelect from "components/Common/PageComponent/DropdownList";
import { checkImageTypeForMultipart } from "common/commonFunction";


const Employee = props => {

    //meta title
    document.title = "Employee | " + process.env.REACT_APP_NAME

    const dispatch = useDispatch();
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }));
    const [filterData, setFilterData] = useState({
        start: configJs.api_parameter.START,
        limit: configJs.api_parameter.LIMIT,
        search: '',
        sort_by: configJs.api_parameter.SORTBY,
        sort_order: configJs.api_parameter.SORTORDER,
        id: localStorage.getItem('projectNayaOrganization') ? JSON.parse(localStorage.getItem('projectNayaOrganization')).id : 0
    });
    const [employee, setEmployee] = useState({})
    const [isApiCall, setIsApiCall] = useState(0);
    const [employeeList, setEmployeeList] = useState([]);
    const [listCount, setListCount] = useState(0);
    const [updateListData, setUpdateListData] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    
    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [addEmployee, setAddEmployee] = useState('');
    const [editEmployee, setEditEmployee] = useState('');
    const [editFlag,setEditFlag]=useState(false);
    const [addFlag,setAddFlag]=useState(false);
    const [changeStatusEmployee, setChangeStatusEmployee] = useState('');
    const [deleteEmployee, setDeleteEmployee] = useState('');
    // const [adminEmployee, setAdminEmployee] = useState('');
    const [contactNumber, setContactNumber] = useState(' ');
    const [image, setImage] = useState(null);
    const [selectedDesignation, setSelectedDesignation] = useState([])
    const [updateTaskMetaData, setUpdateTaskMetaData] = useState(false)
    const [selectedDesignationValue, setSelectedDesignationValue] = useState('');
    const [defaultDesignationValue, setDefaultDesignationValue] = useState('');


    useEffect(() => {
        if (isApiCall == 0) {
            getListData();
            // getTaskMetaData();
            setIsApiCall(1);
        }
        if (!isEmpty(employee) && employee.designation) {
            setDefaultDesignationValue(employee.designation);
        }
    }, [isApiCall,employee]);
    const getTaskMetaData = () => {
        setUpdateTaskMetaData(true)
        dispatch(clearData())
        let filterData = {
          organization_id: localStorage.getItem("projectNayaOrganization")
            ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
            : 0,
        }
        dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
      }
    
      // && !isEmpty(data.task_type)
      if (!isEmpty(data) && updateTaskMetaData) {
        setUpdateTaskMetaData(false)
        // alert("hi")
        // console.log("designation",data.designation);
        data.designation.map((item, index) => {
          selectedDesignation.push({ label: item.name, value: item.name })
        })
          }


    const getListData = () => {
        setUpdateListData(true);
        dispatch(clearData());
        dispatch(onGetList(filterData, props.router.navigate, api.employeeList))
    }

    if (!isEmpty(data) && !isEmpty(data.Employee) && updateListData) {
        setEmployeeList(data.Employee);
        setListCount(data.filter_count);
        setUpdateListData(false);
        getTaskMetaData();
    }

    
    const breadcrumbItem = [
        { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
        { title: "Employee", path: `/${props.router.organization_slug}/organization/employee` }
    ];

    /**Add Edit Employee*/
    
        const toggle = () =>{
        setModalOpen(!modalOpen)
        }
        console.log("contactNumber",contactNumber);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            organization_id:localStorage.getItem("projectNayaOrganization")
            ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
            : 0,
            name: (employee && employee.name) || "",
            email: (employee && employee.email) || "",
            designation: (employee && employee.designation) || "",
            mobile: contactNumber || "",
            mobileStatus: "",
            country_code:  (employee && employee.country_code) || "",
            date_of_birth: (employee && employee.date_of_birth) || "",
            joining_date: (employee && employee.joining_date) || "",
            salary: (employee && employee.salary) || "",
            profile_image: "",
            id: (employee && employee.id) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name").max(250, "Must be less than  250 characters")
                .matches(
                    /^[a-zA-Z\s]+$/,
                    "Only Alphabets are allowed for Name"),
            email: Yup.string().email("Email is required and format should be john@doe.com.").required("Please Enter Email").max(250, "Must be less than  250 characters"),
            mobile: Yup.string().required("Mobile Number is Required"),
            // .matches(/^[\d()-]+$/i, "Please Enter Valid Mobile"),
           
            mobileStatus: Yup.boolean().oneOf([true], "Please Enter Valid Mobile"),
            date_of_birth: Yup.string(),
            joining_date: Yup.string(),
            designation: Yup.string(),
            salary: Yup.string().max(15, "Must be less than  15 Numbers").matches(
                /^\d*\.?\d*$/,
                "Please Type in Numeric Only"),
            // /^\d*\.?\d*$/
            profile_image: Yup.mixed()
            .test("fileType", "Only JPG, JPEG, and PNG file formats are accepted.", (value) => {
                if (!value) {
                    return true;
                }
                return checkImageTypeForMultipart(value);
            }),
        }),
        onSubmit: values => {
            if (isEdit) {
                setEditFlag(true)
                const updateData = {
                    id: employee.id,
                    type :"formData",
                    name: values["name"],
                    email: values["email"],
                    designation: values["designation"],
                    mobile: values["mobile"].startsWith("0") ? values["mobile"].substr(1) : values["mobile"],
                    country_code: "+"+values["country_code"],
                    // country_code: "91",
                    date_of_birth: values["date_of_birth"],
                    joining_date: values["joining_date"],
                    salary:values["salary"],
                    profile_image:values["profile_image"]
                };
                dispatch(clearData());
                dispatch(onUpdateFormData(updateData, props.router.navigate, api.employeeEdit))

            } else {
                setAddFlag(true);
                const newData = {
                    organization_id:localStorage.getItem("projectNayaOrganization")
                    ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
                    : 0,
                    type :"formData",
                    name: values["name"],
                    email: values["email"],
                    designation: values["designation"],
                    mobile: values["mobile"],
                    country_code: "+"+values["country_code"],
                    date_of_birth: values["date_of_birth"],
                    joining_date: values["joining_date"],
                    salary:values["salary"],
                    profile_image:values["profile_image"]
                };
                dispatch(clearData());
                dispatch(onAddNewData(newData, props.router.navigate, api.employeeCreate))

            }
        },
    });
    // console.log("validation", validation);
    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false;
    }
            
                    //employeeAdd
    if (!isEmpty(data) && !isEmpty(data.employee) && addFlag) {
        setAddFlag(false);
        // setAddEmployee(data.employee);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        getListData();
    }
                         //employeeEdit
    if (!isEmpty(data) && !isEmpty(data.employee) && editFlag) {
        setEditFlag(false);
        // setEditEmployee(data.employee);
        validation.resetForm();
        setIsEdit(false);
        toggle();
        dispatch(clearData());
        getListData();
    }


        // Edit employee
        const handleEditClick = arg => {
            const employee = arg;

            setEmployee({
                id: employee.id,
                name: employee.name,
                email: employee.email,
                designation: employee.designation,
                mobile: employee.mobile,
                country_code: employee.country_code,
                date_of_birth:employee.date_of_birth,
                joining_date:employee.joining_date,
                salary:employee.salary,
                profile_image: '',
            });
             setIsEdit(true);
             let mobile=employee.country_code+" "+employee.mobile;
             console.log(mobile);
            setContactNumber(mobile);
            toggle();
        };

        const handleClick = () => {
            setContactNumber(' ');
            setEmployee(' ');
            setModalOpen(!modalOpen);
            setIsEdit(false);
          }

    //   const [salary, setSalary] = useState('');
    //   // Function to handle salary input change
    //   const handleSalaryChange = (event) => {
    //     const value = event.target.value;
    //     validation.setSalary(value)
    //     // Only allow numbers
    //     const isValid = /^\d*\.?\d*$/.test(value);
    //     if (isValid || value === '') { // Allow empty string
    //       setSalary(value);
    //     }
    //   };
    
      const [selectedDate, setSelectedDate] = useState(new Date());
      // Function to handle Date for date of birth and joining date
      const handleChange = (event) => {
        const selected = new Date(event.target.value);
          const today = new Date();
    
          if (selected <= today) {
              setSelectedDate(selected);
          }
      };

      const handleImageChange = (event) => {
        const file = event.target.files[0]
        validation.setFieldValue("profile_image",file)
        // console.log(event.target.files[0]);
    };

    const handleDesignation = (selectedDesignation) => {
        setSelectedDesignationValue(selectedDesignation);
        validation.setFieldValue("designation",selectedDesignation)
      }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Employee" breadcrumbItems={breadcrumbItem} />
                    <Row className="mb-3">
                        <Col lg="12">
                            <div className="text-lg-end">
                                <Link to="#" className="btn btn-primary waves-effect waves-light"  onClick={()=>handleClick()}>
                                    <i className="mdi mdi-plus me-1" ></i>Add New Employee
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {(employeeList || [])?.map((singleEmployee, key) => (
                            <CardEmployee singleEmployee={singleEmployee} key={"_employee_" + key} handleEditClick={handleEditClick} />
                            
                        ))}
                    </Row>
                    <Row>
                        <Paginations
                            perPageData={filterData.limit}
                            data={employeeList}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            listCount={listCount}
                            getFilterData={getListData}
                            filterData={filterData}
                            isShowingPageLength={false}
                            paginationDiv="col-12"
                            paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5" />
                    </Row>
                            {/* Create EDit Employee Modal */}
    <Modal size="lg" isOpen={modalOpen} toggle={toggle} backdrop="static" >
        <ModalHeader  toggle={()=>handleClick() } >
            <h4>{!!isEdit ? "Edit Employee" : "Add Employee"}</h4>
        </ModalHeader>
              <div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                //   console.log(validation.handleSubmit());
                  validation.handleSubmit()
                  return false
                }}
              >
                 <ModalBody>
                <Row>
                    <Col md="6">
                    <Name validation={validation} />
                    </Col>
                    <Col md="6">
                    <Email validation={validation} />
                    </Col>
                    <Col md="6">
                    <Mobile validation={validation} />
                    </Col>
                    <Col md="6">
                    <DatePicker name="date_of_birth" label="Date of Birth" onChange={handleChange} validation={validation} />
                    </Col>
                    <Col md="6">
                    <DatePicker name="joining_date" onChange={handleChange} label="Company Joining Date" validation={validation} />
                    </Col>
                    <Col md="6" className="mb-3 mb-md-0">
                    {/* <Designation validation={validation} /> */}
                    
                    <CustomSelect
                      label="Designation"
                    //   value={employee.designation}
                      value={selectedDesignationValue || defaultDesignationValue}
                      options={selectedDesignation}
                      name="designation"
                      className="custom-select"
                      validation={validation}
                      onChange={handleDesignation}
                    />
                    </Col>
                    <Col md="6">
                    <Salary validation={validation} />
                    </Col>
                    <Col md="6">
                    <Label className="form-label">{labelConstant.PROFILEIMAGE}</Label>
                    <Input
                    name="profile_image"
                    type="file"
                    accept="image/*"
                    invalid={
                        validation.touched.profile_image && validation.errors.profile_image ? true : false
                    }
                    onChange={handleImageChange} />
                   { validation.touched.profile_image && validation.errors.profile_image ? (
                <FormFeedback type="invalid">{validation.errors.profile_image}</FormFeedback>
            ) : null}
                    </Col>
                </Row>
              {/* </Form> */}
              </ModalBody>
            <ModalFooter>
              <div className="d-flex align-items-center gap-2">
                <AnotherButton  className="btn btn-secondary" onClick={
                    (e)=>{
                        e.preventDefault();
                        handleClick()}}
                buttonName="Cancel"
                />{' '}
                <LoginButton
                buttonName="Submit"
                />
              </div>
            </ModalFooter>
            </Form>
            </div>
            
    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Employee);
