import React, { useState, useEffect } from "react"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Form,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  Row,
  ModalHeader,
} from "reactstrap"
import {
  addNewData as onAddNewData,
  getList as onGetList,
  getData as onGetData,
  UpdateFormData as onUpdateFormData,
  clearData,
} from "store/AppMaster/actions"
import api from "../../constants/api"
import { useParams } from "react-router-dom"
import LoginButton from "components/Common/PageComponent/LoginButton"
import AnotherButton from "components/Common/PageComponent/AnotherButton"
import DatePicker from "components/Common/PageComponent/DatePicker"
import CustomSelect from "components/Common/PageComponent/DropdownList"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertFromHTML } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import { isEmpty, set } from "lodash"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import moment from "moment"

const LeaveTab = props => {
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))

  const [modal_standard2, setmodal_standard2] = useState(false)
  const [isApiCall, setIsApiCall] = useState(0)
  const [addLeave, setAddLeave] = useState(false)
  const [updateLeaveListData, setUpdateLeaveListData] = useState(false)
  const [updateListData, setUpdateListData] = useState(false)
  const [employeeLeaves, setEmployeeLeaves] = useState()
  const [employee, setEmployee] = useState({})
  const [editorDescription, setEditorDescription] = useState(() =>
    EditorState.createEmpty()
  )
  const onEditorDescriptionChange = editorState => {
    setEditorDescription(editorState)
  }
  const [SingleLeave, setSingleLeave] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [viewflag, setViewFlag] = useState(false)
  const [singleLeaveId, setSingleLeaveId] = useState()
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState()

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isApiCall == 0 && localStorage.getItem("customActiveTab") === "5") {
      getListData()
      getLeaveData()
      setIsApiCall(1)
    }
  }, [isApiCall, localStorage.getItem("customActiveTab")])
  function tog_standard2() {
    setmodal_standard2(!modal_standard2)
  }
  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeView))
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.employee) &&
    isEmpty(employee) &&
    updateListData
  ) {
    setEmployee(data.employee)
    // console.log("employee",data.employee);
    setUpdateListData(false)
  }

  const getLeaveData = () => {
    setUpdateLeaveListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeLeaves))
  }
  // leaveApplicationCreate

  if (!isEmpty(data) && !isEmpty(data.leaves) && updateLeaveListData) {
    setEmployeeLeaves(data.leaves)
    setUpdateLeaveListData(false)
  }

  const leavevalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      user_id: employee.user_id,
      leave_type: "",
      start_date: "",
      end_date: "",
      status: "Pending",
    },
    validationSchema: Yup.object({
      leave_type: Yup.string().required("Please Enter Leave Type"),
      status: Yup.string().required("Please Enter Status"),
      start_date: Yup.string().required("Please Enter Start Date"),
      end_date: Yup.string(),
    }),
    onSubmit: values => {
      setAddLeave(true)
      const contentDescription = editorDescription.getCurrentContent()
      const description = stateToHTML(contentDescription)
      // addFlag(true);
      if (
        values["leave_type"] === "Single Full Day" ||
        values["leave_type"] === "Single Half Day"
      ) {
        values["end_date"] = values["start_date"]
      }
      const newData = {
        organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
        user_id: employee.user_id,
        leave_type: values["leave_type"],
        html_description: description,
        start_date: values["start_date"],
        end_date: values["end_date"],
        status: values["status"],
      }
      dispatch(clearData())
      dispatch(onAddNewData(newData, props.router.navigate, api.LeaveCreate))
    },
  })
  // console.log("leavevalidation",leavevalidation);

  if (!loading && leavevalidation.isSubmitting) {
    leavevalidation.isSubmitting = false
  }

  // Leave Add
  if (!isEmpty(data) && !isEmpty(data.leaveApplicationCreate) && addLeave) {
    setAddLeave(false)
    leavevalidation.resetForm()
    tog_standard2()
    dispatch(clearData())
    // if (STATUS_CODE === 200) {
    getLeaveData()
    // }
    // getLeaveData();
  }

  const statusvalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      leave_application_id: singleLeaveId,
      status: selectedLeaveStatus,
    },
    validationSchema: Yup.object({
      leave_application_id: Yup.string().required("Please Enter Leave Id"),
      status: Yup.string().required("Please Enter Status"),
    }),
    onSubmit: values => {
      const UpdateData = {
        leave_application_id: singleLeaveId,
        status: selectedLeaveStatus,
      }
      dispatch(clearData())
      dispatch(
        onUpdateFormData(
          UpdateData,
          props.router.navigate,
          api.LeaveStatusChange
        )
      )
    },
  })

  if (!loading && statusvalidation.isSubmitting) {
    statusvalidation.isSubmitting = false
  }
  if (!isEmpty(data) && !isEmpty(data.leaveApplicationChangeStatus)) {
    statusvalidation.resetForm()
    setViewModal(!viewModal)
    dispatch(clearData())
    getLeaveData()
  }
  const leaveOptions = [
    { value: "Single Full Day", label: "Single Full Day" },
    { value: "Single Half Day", label: "Single Half Day" },
    { value: "Multiple Days", label: "Multiple Days" },
    // Add more options as needed
  ]
  const statusOptions = [{ value: "Pending", label: "Pending" }]

  const changeOptions = [
    { value: " ", label: "--Select--" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ]

  const [selectedLeaveType, setSelectedLeaveType] = useState("")
  // Handle select change
  const handleLeave = (selectedLeave, name) => {
    leavevalidation.setFieldValue("leave_type", selectedLeave)
    setSelectedLeaveType(selectedLeave)
  }

  const handleLeaveStatus = selectedLeaveStatus => {
    setSelectedLeaveStatus(selectedLeaveStatus)
    statusvalidation.setFieldValue("status", selectedLeaveStatus)
    // console.log("selectedLeaveStatus Function",selectedLeaveStatus);
  }

  // Leave View Modal
  const tog_center = leaveId => {
    setViewModal(!viewModal)
    setSingleLeaveId(leaveId)
    setViewFlag(true)
    if (leaveId) {
      dispatch(clearData())
      let filterData = {
        leave_application_id: leaveId,
      }
      dispatch(onGetData(filterData, props.router.navigate, api.LeaveView))
    }
  }
  if (!isEmpty(data) && !isEmpty(data.leaveApplicationView) && viewflag) {
    setSingleLeave(data.leaveApplicationView)
    setViewFlag(false)
  }

  return (
    <>
      <div className="p-md-3">
        <Row className="mb-3 pt-3 pt-md-0">
          <Col className="text-end">
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                tog_standard2()
              }}
              data-toggle="modal"
              data-target="#myModal"
              className="btn btn-rounded"
            >
              <i className="mdi mdi-plus me-1"></i>
              Add Leave
            </Button>
          </Col>
        </Row>
        {/* Leave Description */}
        <Row>
          {employeeLeaves &&
            employeeLeaves.map((leave, index) => {
              // console.log("leave",leave);
              return (
                <Col md="12" key={index}>
                  <Card className="shadow-sm border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 border-light">
                    <CardBody>
                      <Row className="align-items-center mb-2">
                        <Col>
                          <h4 className="font-size-15 text-dark mb-0">
                            {leave.total_number_leave_days} days
                          </h4>
                        </Col>

                        <Col sm="auto">
                          <Badge
                            className={
                              leave.status === "Approved"
                                ? "bg-success font-size-12"
                                : leave.status === "Pending"
                                ? "bg-warning font-size-12"
                                : "bg-danger font-size-12"
                            }
                          >
                            {leave.status}
                          </Badge>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <p className="mb-0 font-size-14">
                            <span className="text-dark me-1 fw-bold">
                              {/* {leave.leave_type} : */}
                              Leave Duration :
                            </span>
                            {leave.leave_type !== "Single Half Day" &&
                              leave.leave_type !== "Single Full Day" && (
                                <b>From </b>
                              )}
                            {moment(leave.start_date).format("DD-MM-YYYY")}
                            {leave.leave_type === "Multiple Days" && (
                              <React.Fragment>
                                {" "}
                                <b>To </b>
                                {moment(leave.end_date).format("DD-MM-YYYY")}
                              </React.Fragment>
                            )}
                          </p>
                        </Col>
                      </Row>
                      <div
                        className="border p-3 rounded-2 mt-3 "
                        onClick={() => {
                          tog_center(leave.id)
                        }}
                        style={ {cursor: "pointer"} }
                      >
                        <div
                          className="mb-0 "
                          dangerouslySetInnerHTML={{
                            __html: leave.description,
                          }}
                        />
                      </div>
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center gap-3 mt-3">
                            <div className="avatar-xs">
                              <img
                                src={employee && employee.profile_image}
                                alt=""
                                className="rounded-circle avatar-xs"
                              />
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h2 className="text-dark mb-0 font-size-15">
                                {employee.name}
                              </h2>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
        </Row>
      </div>
      <Modal
        size="lg"
        isOpen={modal_standard2}
        toggle={() => {
          tog_standard2()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Leave
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              // console.log("onSubmit",leavevalidation,e);
              leavevalidation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Leave Type"
                  // value={leavevalidation.values["leave_type"]}
                  options={leaveOptions}
                  name="leave_type"
                  className="custom-select"
                  validation={leavevalidation}
                  // onChange={handleLeave}
                  onChange={selectedLeave =>
                    handleLeave(selectedLeave, "leave_type")
                  }
                />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Status"
                  value="Pending"
                  options={statusOptions}
                  name="status"
                  className="custom-select"
                  validation={leavevalidation}
                  onChange={selectedStatus =>
                    handleLeaveStatus(selectedStatus, "status")
                  }
                />
              </Col>

              {selectedLeaveType === "Multiple Days" && (
                <>
                  <Col sm={12} md="6">
                    <DatePicker
                      label="Start Date"
                      className="leave-type-custom"
                      name="start_date"
                      validation={leavevalidation}
                      showStrike={true}
                    />
                  </Col>
                  <Col sm={12} md="6">
                    <DatePicker
                      label="End Date"
                      name="end_date"
                      validation={leavevalidation}
                      showStrike={true}
                    />
                  </Col>
                </>
              )}

              {(selectedLeaveType === "Single Full Day" ||
                selectedLeaveType === "Single Half Day") && (
                <DatePicker
                  label="Start Date"
                  name="start_date"
                  validation={leavevalidation}
                  showStrike={true}
                />
              )}
              <Col sm={12} className="mb-3">
                <Label>Leave Description</Label>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  placeholder="Place Your Content Here..."
                  name="html_description"
                  value={leavevalidation.values.html_description}
                  editorState={editorDescription}
                  onEditorStateChange={onEditorDescriptionChange}
                />
              </Col>
            </Row>
            <div className="modal-footer">
              <LoginButton buttonName="Submit" />
              <AnotherButton
                className="btn btn-secondary"
                onClick={e => {
                  e.preventDefault()
                  tog_standard2()
                }}
                buttonName="Cancel"
              />
            </div>
          </Form>
        </div>
      </Modal>

      {/* Leave View Modal */}
      <Modal
        isOpen={viewModal}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Leave Status Update
          </h5>
          <button
            type="button"
            onClick={() => {
              tog_center()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            statusvalidation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
            <div className="d-flex justify-content-end">
            </div>
            <div>
              <CustomSelect
                label="Status"
                options={changeOptions}
                name="status"
                className="custom-select"
                validation={statusvalidation}
                onChange={selectedStatusChange =>
                  handleLeaveStatus(selectedStatusChange, "status")
                }
              />
            </div>
          </div>
          <div className="modal-footer">
            <LoginButton buttonName="Submit" />
            <AnotherButton
              className="btn btn-secondary"
              onClick={e => {
                e.preventDefault()
                tog_center()
              }}
              buttonName="Cancel"
            />
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default withRouter(LeaveTab)
