import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import api from "../../constants/api"
import { get, isEmpty } from "lodash"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useNavigate } from "react-router-dom"
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  UpdateFormData as onUpdateFormData,
  clearData
} from "store/AppMaster/actions";
import withRouter from "components/Common/withRouter"
import CreateEditTaskModal from "./CreateEditTaskModal"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";


const TaskTab = props => {
  document.title = "Task Tab | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { data, loading } = useSelector(state => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }))

  const [isApiCall, setIsApiCall] = useState(0)
  const [taskList, setList] = useState([])
  const [statusListData, setStatusListData] = useState(false)
  const [addFlag, setAddFlag] = useState(false)
  const [modal_standard1, setmodal_standard1] = useState(false)
  const [updateTaskMetaData, setUpdateTaskMetaData] = useState(false)
  const [projectFieldVisible, setProjectFieldVisible] = useState(true); // State variable to control the visibility of the project field
  

  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedStoryPoint, setSelectedStoryPoint] = useState([])
  const [selectedSprint, setSelectedSprint] = useState([])
  const [selectedAssignee_id, setSelectedAssignee_id] = useState([])
  const [selectedReporter_id, setSelectedReporter_id] = useState([])
  const [selectedTask_epic_id, setSelectedTask_epic_id] = useState([])
  const [selectedTasktype, setSelectedTasktype] = useState([])
  const [selectedProject, setSelectedProject] = useState([])
  const [selectedPriority, setSelectedPriority] = useState([])
  const [editorDescription, setEditorDescription] = useState(() =>
    EditorState.createEmpty()
    );
    const onEditorDescriptionChange = (editorState) => {
      setEditorDescription(editorState);
    };
  function tog_standard1() {
    setmodal_standard1(!modal_standard1)
  }
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (isApiCall === 0) {
      getTaskListData()
      getTaskMetaData()
      setIsApiCall(1)
    }
  }, [isApiCall,taskList])

  const getTaskListData = () => {
    setStatusListData(true)
    dispatch(clearData())
    let filterData = {
      project_id: params.id,
    }
    dispatch(
      onGetList(filterData, props.router.navigate, api.ProjectTaskList)
    )
  }

  if (!isEmpty(data) && !isEmpty(data.task) && statusListData) {
    setList(data.task)
    setStatusListData(false)
  }

  const getTaskMetaData = () => {
    setUpdateTaskMetaData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
  }

  // && !isEmpty(data.task_type)
  // console.log(data);
  if (!isEmpty(data) && !isEmpty(data.storyPoints) && updateTaskMetaData) {
    // console.log(data);
    setUpdateTaskMetaData(false)

    data.status.map((item, index) => {
      selectedStatus.push({ label: item.column_name, value: item.column_name })
    })
    data.storyPoints.map((item, index) => {
      selectedStoryPoint.push({ label: item.name, value: item.id })
    })
    data.sprint.map((item, index) => {
      selectedSprint.push({ label: item.name, value: item.id })
    })
    data.employee.map((item, index) => {
      selectedAssignee_id.push({ label: item.name, value: item.id })
      selectedReporter_id.push({ label: item.name, value: item.id })
    })
    data.taskEpic.map((item, index) => {
      selectedTask_epic_id.push({ label: item.name, value: item.id })
    })
    data.taskType.map((item, index) => {
      selectedTasktype.push({ label: item.name, value: item.id })
    })
    data.priority.map((item, index) => {
      selectedPriority.push({ label: item.name, value: item.id })
    })
    // data.project.map((item, index) => {
    //   selectedProject.push({ label: item.name, value: item.id })
    // })
      }

  const handleClick = () => {
    setmodal_standard1(!modal_standard1)
    // setModalOpen(!modalOpen);
    // setIsEdit(false);
  }

  const taskvalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      project_id: params.id,
      status: "",
      summary: "",
      assignee_id: "",
      reporter_id: "",
      story_points: "",
      sprint_id: "",
      task_epic_id: "",
      task_type: "",
      priority: "",
      tags: "",
      estimated_time: "",
    },
    validationSchema: Yup.object({
      summary: Yup.string(),
      assignee_id: Yup.string().required("Please Enter Assignee"),
      reporter_id: Yup.string().required("Please Enter Reporter"),
      sprint_id: Yup.string().required("Please Enter Sprint"),
      task_epic_id: Yup.string().required("Please Enter Task Epic"),
      task_type: Yup.string().required("Please Enter Task Type"),
      priority: Yup.string().required("Please Enter Priority"),
    }),
    onSubmit: values => {
      setAddFlag(true)
      const contentDescription = editorDescription.getCurrentContent();
      const description = stateToHTML(contentDescription);
      let tagString = '';
      if (tags.length > 0) {
        tagString = tags.map(tag => tag.text).join(',');
    }
      const addData = {
        organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
        project_id: params.id,
        status: values["status"],
        summary: values["summary"],
        assignee_id: values["assignee_id"],
        reporter_id: values["reporter_id"],
        story_points: values["story_points"],
        sprint_id: values["sprint_id"],
        task_epic_id: values["task_epic_id"],
        task_type: values["task_type"],
        priority: values["priority"],
        description: description,
        tags: tagString,
        estimated_time: values["estimated_time"],
      }
      dispatch(clearData())
      dispatch(onAddNewData(addData, props.router.navigate, api.TaskCreate))
    },
  })
// console.log("taskvalidation",taskvalidation);
  if (!loading && taskvalidation.isSubmitting) {
    taskvalidation.isSubmitting = false
  }

  // Task Add
  if (!isEmpty(data) && !isEmpty(data.taskCreate) && addFlag) {
    setAddFlag(false)
    taskvalidation.resetForm()
    tog_standard1()
    dispatch(clearData())
    getTaskListData()
  }
  const handleTask = (selectedStatus) => {
    taskvalidation.setFieldValue("status",selectedStatus)
  }
  const handleStoryPoint = (selectedStoryPoint) => {
    taskvalidation.setFieldValue("story_points",selectedStoryPoint)
  }
  const handleSprint = (selectedSprint) => {
    taskvalidation.setFieldValue("sprint_id",selectedSprint)
  }
  const handleAssignee_id = (selectedAssignee_id) => {
    taskvalidation.setFieldValue("assignee_id",selectedAssignee_id)
  }
  const handleReporter_id = (selectedReporter_id ) => {
    taskvalidation.setFieldValue("reporter_id",selectedReporter_id)
  }
  const handleTask_epic_id = (selectedTask_epic_id ) => {
    taskvalidation.setFieldValue("task_epic_id",selectedTask_epic_id)
  }
  const handleTaskType = (selectedTasktype ) => {
    taskvalidation.setFieldValue("task_type",selectedTasktype)
  }
  const handlePriority = (selectedPriority ) => {
    taskvalidation.setFieldValue("priority",selectedPriority)
  }
  const handleProject = (selectedProject ) => {
    taskvalidation.setFieldValue("project_id",params.id)
  }

  const handleDelete = i => {
    setTags(tags.filter((tags, index) => index !== i));
};

const handleAddition = tag => {
    setTags([...tags, tag]);
    taskvalidation.setFieldValue('tags', tags);
};

const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
};

const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
};
  return (
    <>
    <div>
          <Row className="mb-3">
                        <Col lg="12">
                            <div className="text-lg-end">
                                <Link to="#" className="btn btn-primary waves-effect waves-light"  onClick={()=>handleClick()}>
                                    <i className="mdi mdi-plus me-1" ></i>Add Task
                                </Link>
                            </div>
                        </Col>
                    </Row>
      <Row>
      {taskList
          ? taskList.map((task, index) => (
        <Col key={index} sm="4">
          <Card className="border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 border-danger">
            <CardBody>
              <div className="d-flex align-items-center justify-content-between mb-1">
                <h4 className="text-muted font-size-13 mb-0"># {task.id}</h4>
                <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                {task.story_points}
                </span>
              </div>

              <Link to="#" className="text-dark">
                <h5 className="font-size-13">{task.summary}</h5>
              </Link>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div>
                  <span className="badge font-size-12 mt-2" style={{ backgroundColor: task.project.background_color }}>
                  {task.project.name}
                  </span>
                  <div className="d-flex align-items-center mt-2 pt-1">
                    <div className="avatar-xs">
                    <img
                        src={task.assignee.profile_image_path}
                        alt=""
                        className="rounded-circle avatar-xs"
                      />
                    </div>
                    <h4 className="font-size-14 mb-0 ms-2">{task.assignee.name}</h4>
                  </div>
                </div>
                {/* <div id="radialchart-1">
                  <ReactApexChart
                    options={getChartOptions(index)}
                    series={[chartdata.value]}
                    type="radialBar"
                    height={60}
                    width={60}
                    className="apex-charts"
                  />
                </div> */}
                <div className="text-end">
                  <p>
                    <span className="me-1"></span>
                  </p>
                  <span className="badge me-1 " style={{ backgroundColor: task.priority.background_color }}>
                  <img
                        src={task.priority.priority_icon_path}
                        alt=""
                        className="me-1"
                      />
                    {task.priority.name}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        ))
          : ""}
      </Row>
    </div>

        <CreateEditTaskModal isOpen={modal_standard1} toggle={tog_standard1} taskvalidation={taskvalidation}
        handleReporter_id={handleReporter_id} selectedReporter_id={selectedReporter_id}
        handleAssignee_id={handleAssignee_id} selectedAssignee_id={selectedAssignee_id}
        handleStoryPoint={handleStoryPoint} selectedStoryPoint={selectedStoryPoint}
        handleTask={handleTask} selectedStatus={selectedStatus}
        handleSprint={handleSprint} selectedSprint={selectedSprint}
        handleTask_epic_id={handleTask_epic_id} selectedTask_epic_id={selectedTask_epic_id}
        tags={tags} suggestions={suggestions} handleDelete={handleDelete} handleAddition={handleAddition} handleDrag={handleDrag} handleTagClick={handleTagClick}
        handleTaskType={handleTaskType} selectedTasktype={selectedTasktype}
        handlePriority={handlePriority} selectedPriority={selectedPriority}
        handleProject={handleProject} selectedProject={selectedProject} projectFieldVisible={false}
        editorDescription={editorDescription} onEditorDescriptionChange={onEditorDescriptionChange}
        />
    </>
  )
}

export default withRouter(TaskTab)
