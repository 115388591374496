import Breadcrumbs from "components/Common/Breadcrumb2";
import Name from "components/Common/PageComponent/Name";
import ToggleSubmitCancelButton from "components/Common/PageComponent/ToggleSubmitCancelButton";
import TableContainer from "components/Common/TableContainer";
import withRouter from "components/Common/withRouter";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {Card,CardBody,Col,Container,Form,FormFeedback,Input,Label,Modal,ModalBody,ModalFooter,ModalHeader,Row,UncontrolledTooltip} from "reactstrap";
import {
    clearData,
    addNewData as onAddNewData,
    getList as onGetList,
    updateData as onUpdateData,
    UpdateFormData as onUpdateFormData,
} from "store/AppMaster/actions";
import * as Yup from "yup";
import configJs from '../../../../config';
import api from '../../../../constants/api';
import messageConstant from "constants/message";
import AddButton from "components/Common/PageComponent/AddButton";
import BackGroundColor from "components/Common/PageComponent/BackGroundColor";
import Logo from "components/Common/PageComponent/Logo";
import labelConstant from "constants/label";
import { checkImageTypeForMultipart } from "common/commonFunction";
const Priority = (props) => {
    document.title = "Priority | " + configJs.app_name.REACT_APP_NAME;
    const dispatch = useDispatch();
    const [adminPriority, setPriority] = useState('');
    const [editTag, setEditTag] = useState(false);
    const [addTag, setAddTag] = useState(false);
    /**Tag List*/
    const [listCount, setListCount] = useState(0);
    const [filterData, setFilterData] = useState({
        start: configJs.api_parameter.START,
        limit: configJs.api_parameter.LIMIT,
        search: '',
        sort_by: configJs.api_parameter.SORTBY,
        sort_order: configJs.api_parameter.SORTORDER,
    });
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }));
    const [isApiCall, setIsApiCall] = useState(0);
    const [priorityList, setPriorityList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [updateListData, setUpdateListData] = useState(false);

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Name",
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Icon",
                accessorKey: 'icon',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    return (
                        <>
                            <div className="d-flex">
                                {/* <div
                                    style={{
                                        height: "19px",
                                        width: "19px",
                                    }}
                                /> */}
                                <img height="30px" width="30px" src={cell.row.original.icon} alt="" />
                            </div>
                        </>
                    );
                }
            },
            {
                header: "Background Color",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    return (
                        <>
                            <div className="d-flex gap-3">
                                <div
                                    style={{
                                        height: "19px",
                                        width: "19px",
                                        background: cell.row.original.background_color
                                    }}
                                />
                                <div>{cell.row.original.background_color}</div>
                            </div>
                        </>
                    );
                }
            },
            {
                header: "Action",
                cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#"
                                className="text-secondary"
                                onClick={() => {
                                    const editData = cellProps.row.original;
                                    handleEditClick(editData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="editTeamTooltip" />
                                <UncontrolledTooltip placement="top" target="editTeamTooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );
    useEffect(() => {
        if (isApiCall == 0) {
            getListData();
            setIsApiCall(1);
        }
    }, [isApiCall]);
    const getListData = () => {
        setUpdateListData(true);
        dispatch(clearData());
        dispatch(onGetList(filterData, props.router.navigate, api.PriorityList))
    }
    if (!isEmpty(data) && !isEmpty(data.priority) && updateListData) {
        setPriorityList(data.priority);
        setListCount(data.filter_count);
        setUpdateListData(false);

    }

    const toggle = () => {
        setModal(!modal);
    };
    const handleClicks = () => {
        validation.resetForm();
        setPriority();
        setIsEdit(false);
        toggle();
    };
    const handleEditClick = arg => {
        const adminPriority = arg;
        // console.log("arg",arg);
        setPriority({
            id: adminPriority.id,
            name: adminPriority.name,
            background_color: adminPriority.background_color,
        });
        setIsEdit(true);
        toggle();
    };

    /**Add Edit Priority*/
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: (adminPriority && adminPriority.name) || "",
            background_color: (adminPriority && adminPriority.background_color) || "",
            id: (adminPriority && adminPriority.id) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(messageConstant.NAME_REQUIRED).max(250, messageConstant.NAME_INVALID)
                .matches(
                    /^[a-zA-Z\s]+$/,
                    "Must be a valid Priority"
                ),
            background_color: Yup.string().required(messageConstant.BACKGROUND_COLOR_REQUIRED).max(250, messageConstant.BACKGROUND_COLOR_INVALID),
            icon: Yup.mixed()
            .test("fileType", "Only JPG, JPEG, and PNG file formats are accepted.", (value) => {
                if (!value) {
                    return true;
                }
                return checkImageTypeForMultipart(value);
            }),
        }),
        onSubmit: values => {
            if (isEdit) {
                setEditTag(true);
                const updateData = {
                    id: adminPriority.id,
                    type:"formData",
                    name: values["name"],
                    background_color: values["background_color"],
                    icon: values["icon"]
                };
                dispatch(clearData());
                dispatch(onUpdateFormData(updateData, props.router.navigate, api.PriorityEdit))

            } else {
                setAddTag(true);
                const newData = {
                    type:"formData",
                    name: values["name"],
                    background_color: values["background_color"],
                    icon: values["icon"]
                };
                dispatch(clearData());
                dispatch(onAddNewData(newData, props.router.navigate, api.PriorityCreate))

            }
        },
    });
    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false;
    }
    if (!isEmpty(data) && !isEmpty(data.priority) && addTag) {
        setAddTag(false);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        getListData();
    }
    if (!isEmpty(data) && !isEmpty(data.priority) && editTag) {
        setEditTag(false);
        validation.resetForm();
        setIsEdit(false);
        toggle();
        dispatch(clearData());
        getListData();
    }
    const breadcrumbItems = [
        { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
        { title: "Priority", path: `/${props.router.organization_slug}/organization/priority` },
    ];

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        validation.setFieldValue("icon",file)
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="PRIORITY" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col md={12}>

                            <>

                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={(priorityList) ? priorityList : []}
                                                    isGlobalFilter={true}
                                                    isCustomPageSize={true}
                                                    isPagination={true}
                                                    SearchPlaceholder={listCount > 0 ? "Search in " + listCount + " records..." : "Search in records..."}
                                                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    isAddButton={true}
                                                    buttonName="Add Priority"
                                                    handleClicks={handleClicks}
                                                    filterData={filterData}
                                                    getListData={getListData}
                                                    listCount={listCount}
                                                    isApiCall={isApiCall}
                                                />

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Modal isOpen={modal} toggle={toggle} centered>
                                    <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit ? "Edit Priority" : "Add Priority"}
                                    </ModalHeader>
                                   
                                        <Form
                                            onSubmit={e => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                             <ModalBody>

                                            <Row>
                                                <Name validation={validation} />
                                            </Row>
                                            <Row>
                                                <BackGroundColor validation={validation} showStrike={true} />
                                            </Row>
                                            <Row>
                                            <Col>
                                            <Label className="form-label">{labelConstant.ICON}</Label>
                                                {/* <Logo validation={validation} /> */}
                                                <Input 
                                                name="icon"
                                                type="file" 
                                                accept="image/*" 
                                                onChange={handleImageChange} 
                                                invalid={
                                                    validation.touched.icon && validation.errors.icon ? true : false
                                                }
                                                 />
                                               { validation.touched.icon && validation.errors.icon ? (
                                                  <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                                              ) : null}
                                                </Col>
                                            </Row>
                                            </ModalBody>
                                                <ModalFooter>
                                            <Row >
                                                <Col>
                                                    <ToggleSubmitCancelButton validation={validation} cancelToggle={() => toggle()} />
                                                </Col>
                                            </Row>
                                            </ModalFooter>
                                        </Form>
                                    
                                </Modal>
                            </>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withRouter(Priority);