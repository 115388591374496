// DatePicker.js
import React, { useState } from 'react';
import { Label, Input } from 'reactstrap';
import PropTypes from 'prop-types'
import moment from 'moment';

function DatePicker1({  name, handleChange,value }) {
  // console.log("validation",validation , value);
  return (
    <div>
    
      <Input
      className='form-control'
        name={name}
        type="date"
        // value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
        value={moment(value).format("YYYY-MM-DD")}
        onChange={handleChange}
        max={new Date().toISOString().split('T')[0]}
      />
    </div>
  );
}

DatePicker1.propTypes = {
  validation: PropTypes.object,
}

export default DatePicker1;
