import React, { useState, useMemo, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "components/Common/Breadcrumb2"
import Paginations from "components/Common/Pagination"
import { useFormik } from "formik"
import * as Yup from "yup"
import api from "../../constants/api"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Progress,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Form,
  Label,
  Input,
  InputGroup,
} from "reactstrap"
import Name from "components/Common/PageComponent/Name"
import ShortName from "components/Common/PageComponent/ShortName"
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  UpdateFormData as onUpdateFormData,
  clearData,
} from "store/AppMaster/actions"
import DatePicker from "components/Common/PageComponent/DatePicker"
import LoginButton from "components/Common/PageComponent/LoginButton"
import { useSelector, useDispatch } from "react-redux"
import { isEmpty, map } from "lodash"
import AnotherButton from "components/Common/PageComponent/AnotherButton"
import configJs from "../../config"
// import CardEmployee from "pages/Employee/CardList"
import CardProject from "./CardList"
import BackGroundColor from "components/Common/PageComponent/BackGroundColor"
import messageConstant from "constants/message"
import labelConstant from "constants/label"
import CreateEditProjectModal from "./CreateEditProjectModal"
import { checkImageTypeForMultipart } from "common/commonFunction"

const Project = props => {
  document.title = "Project | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: '',
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
    id: localStorage.getItem("projectNayaOrganization") ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id : 0,
  })

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const perPageData = 6
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData
  const currentdata = useMemo(
    () => (indexOfFirst, indexOfLast),
    [indexOfFirst, indexOfLast]
  )
  const [modal, setModal] = useState(false)
  const [addProject, setAddProject] = useState(" ")
  // const [editProject,setEditProject]= useState(' ');
  const [isEdit, setIsEdit] = useState(false)
  const [project, setProject] = useState({})
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [isApiCall, setIsApiCall] = useState(0)
  const [projectList, setProjectList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)
  const [image,setImage]=useState();


    useEffect(() => {
      if (isApiCall == 0) {
          getListData();
          setIsApiCall(1);
      }
    }, [isApiCall]);

const getListData = () => {
  setUpdateListData(true)
  dispatch(clearData())
  dispatch(onGetList(filterData , props.router.navigate , api.projectList))
} 
    
if (!isEmpty(data) && !isEmpty(data.Project) && updateListData) {   
  setProjectList(data.Project);
  setListCount(data.filter_count);
  setUpdateListData(false);
}
  
  const breadcrumbItem = [
    { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
    { title: "Project", path: `/${props.router.organization_slug}/organization/project` },
  ]
  function handleclick() {
    setModal(!modal)
    setIsEdit(false)
    setProject(" ")
  }
  const toggle = () => {
    setModal(!modal)
  }

  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      name: (project && project.name) || "",
      short_name: (project && project.short_name) || "",
      start_date: (project && project.start_date) || "",
      duration: (project && project.duration) || "",
      background_color: (project && project.background_color) || "",
      logo: "",
      id: (project && project.id) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please Enter Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s\-,:;''""]+$/, "Must be a valid Project"),
      short_name: Yup.string()
        .required("Please Enter Short Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
      start_date: Yup.string()
        .required("Please Enter Project Start Date"),
      duration: Yup.string()
        .required("Please Enter Project Duration in hours")
        .matches(/^[0-9\b]+$/, "Enter Only Numbers"),
      background_color: Yup.string().required(messageConstant.BACKGROUND_COLOR_REQUIRED).max(250, messageConstant.BACKGROUND_COLOR_INVALID),
      logo: Yup.mixed()
            .test("fileType", "Only JPG, JPEG, and PNG file formats are accepted.", (value) => {
                if (!value) {
                    return true;
                }
                return checkImageTypeForMultipart(value);
            }),
    }),
    onSubmit: values => {
      
      if (isEdit) {
        setEditFlag(true);
        const updateData = {
          id: project.id,
          type: "formData",
          name: values["name"],
          short_name: values["short_name"],
          start_date: values["start_date"],
          duration: values["duration"],
          background_color: values["background_color"],
          logo: values["logo"],
        }
        dispatch(clearData());
        dispatch(onUpdateFormData(updateData, props.router.navigate, api.projectEdit))
      } else {
        // alert("Project has been Added")
        setAddFlag(true);
        const newData = {
          organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
          type :"formData",
          name: values["name"],
          short_name: values["short_name"],
          start_date: values["start_date"],
          duration: values["duration"],
          background_color: values["background_color"],
          logo: values["logo"],
        }
        dispatch(clearData());
        dispatch(onAddNewData(newData, props.router.navigate, api.projectCreate))
      }
    },
  })

  if (!loading && validation.isSubmitting) {
      validation.isSubmitting = false;
  }
            // Project Add
      if (!isEmpty(data) && !isEmpty(data.Project) && addFlag) {
        setAddFlag(false);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        getListData();
      }
          //Project Edit
      if (!isEmpty(data) && !isEmpty(data.Project) && editFlag) {
        // console.log("editFlag");
        setEditFlag(false);
        validation.resetForm();
        setIsEdit(false); 
        toggle();
        dispatch(clearData());
        getListData();
        // setEditProject(data.Project);
        }

      const [selectedDate, setSelectedDate] = useState(new Date())
  // Function to handle Date for date of birth and joining date
  const handleChange = event => {
    const selected = new Date(event.target.value)
    const today = new Date()
    if (selected <= today) {
      setSelectedDate(selected)
    }
  }

  const handleEditClick = arg => {
    const project = arg;
    // console.log("project :", project);
    setProject({
        id: project.id,
        name: project.name,
        short_name: project.short_name,
        start_date: project.start_date,
        duration: project.duration,
        background_color: project.background_color,
        logo: project.logo,
    });
     setIsEdit(true);
    toggle();
  };
  
  const handleImageChange = (event) => {
    // setImage(event.target.files[0]);
    const file = event.target.files[0]
    validation.setFieldValue("logo", file)
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Project" breadcrumbItems={breadcrumbItem} />
          <div className="text-end mb-3">
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                handleclick()
              }}
              data-toggle="modal"
              data-target="#myModal"
              className="btn btn-orange"
            >
              <i className="mdi mdi-plus me-1"></i>
              Add New Project
            </Button>
          </div>
            <Row>
              {(projectList || [])?.map((singleProject, key) => (
                  <CardProject singleProject={singleProject} key={"_project_" + key} handleEditClick={handleEditClick} />  
              ))}
            </Row>
          <Row className="mt-4">
            <Paginations
              perPageData={perPageData}
              data={projectList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              listCount={listCount}
              getFilterData={getListData}
              filterData={filterData}
              isShowingPageLength={false}
              paginationDiv="col-12"
              paginationClass="pagination pagination-rounded justify-content-end mt-2 mb-5"
            />
          </Row>
        </Container>
      </div>
      {/*Create Project Modal */}

      <CreateEditProjectModal isOpen={modal} toggle={toggle} isEdit={isEdit} validation={validation}  handleImageChange={handleImageChange} buttonOnClick={(e)=>{
                e.preventDefault();
                handleclick();
                }} />
    </React.Fragment>
  )
}
export default withRouter(Project)
