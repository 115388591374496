import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";
import { clearData, loginUser } from "store/AppMaster/actions";
import api from "../../constants/api";
import Email from "components/Common/PageComponent/Email";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Otp from "components/Common/PageComponent/Otp";
import messageConstant from "constants/message";
import LoginButton from "components/Common/PageComponent/LoginButton";
import Name from "components/Common/PageComponent/Name";
import Mobile from "components/Common/PageComponent/Mobile";
import configJs from "../../config";
import {
  getData as onGetData,
  addNewData as onAddNewData,
  getList as onGetList,
  updateData as onUpdateData
} from "store/AppMaster/actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  toastClass: 'toast-custom-style',
  // colorClass: 'toast-error',
};

const Signup = (props) => {
  document.title = "Signup | " + process.env.REACT_APP_NAME;
  const [isEmailScreen, setIsEmailScreen] = useState(true);
  const [email, setEmail] = useState("");
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [isSignUpScreen, setIsSignUpScreen] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  useEffect(() => {
    setTimeout(() => {
      setRecaptchaResponse("");
    }, 60000);
  }, []);

  const sendOtp = (email) => {
    const loginData = {
      email: email,
    };
    dispatch(clearData());
    dispatch(loginUser(loginData, props.router.navigate, api.sendOtp));
  };

  const validationOtp = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required(messageConstant.OTP_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.OTP_INVALID)
        .max(6)
        .min(6),
    }),
    onSubmit: async (values) => {
      if (recaptchaRef.current) {
        const token = await recaptchaRef.current.executeAsync();
        setRecaptchaResponse(token);
      }
      const addNewData = {
        email: validationSignup.values.email,
        name: validationSignup.values.name,
        country_code: "+" + validationSignup.values.country_code,
        mobile: validationSignup.values.mobile,
        otp: values.otp,
        gRecaptchaResponse: recaptchaResponse, // Use recaptchaResponse here
        logo: validationSignup.values.logo,
      };
      dispatch(clearData());
      dispatch(loginUser(addNewData, props.router.navigate, api.signUp));
    },
  });

  const validationSignup = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      logo: "",
      mobileStatus: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.NAME_REQUIRED)
        .max(255),
      email: Yup.string()
        .required(messageConstant.EMAIL_REQUIRED)
        .email(messageConstant.EMAIL_INVALID)
        .max(255),
      mobile: Yup.string()
        .required("Please Enter Mobile")
        .matches(/^[+0-9]+$/i, "Please Enter Valid Mobile")
        .min(9, "Please Enter Valid Mobile")
        .max(12, "Please Enter Valid Mobile"),
      mobileStatus: Yup.boolean().oneOf(
        [true],
        "Please Enter Valid Mobile"
      ),
    }),
    onSubmit: async (values) => {
      const checkData = {
        email: values["email"],
    };
    dispatch(onGetData(checkData, props.router.navigate, api.checkEmail))

    // sendOtp(values.email);
    // setIsOtpScreen(true);
    // setIsSignUpScreen(false);
    // toastr["success"]("OTP sent successfully");
    },
  });
  // console.log(validationSignup.values.email);

  if(!isEmpty(data) && data.STATUS_CODE == 400 && isEmailScreen){
    sendOtp(validationSignup.values.email);
    setIsOtpScreen(true);
    setIsSignUpScreen(false);
  }
    // console.log(data);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.userDetails)) {
      validationOtp.resetForm();
      setRecaptchaResponse("");
    }
  }, [data, isEmailScreen, validationOtp]);

  if (
    !isEmpty(data) &&
    (data.STATUS_CODE == 200 || data.STATUS_CODE == 400) &&
    isEmailScreen
  ) {
    setIsEmailScreen(false);
    setEmail(data.DATA.email);
    if (data.STATUS_CODE == 200) {
      sendOtp(data.DATA.email);
      setIsOtpScreen(true);
    }
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={9}>
              <CarouselPage />
            </Col>
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-3 mb-md-3">
                      <Link to="/dashboard" className="d-block card-logo">
                        <img
                          src={logodark}
                          alt=""
                          className="logo-dark-element m-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          className="logo-light-element m-auto"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">
                          {" "}
                          {isOtpScreen ? "Verify Otp" : "Signup"}
                        </h5>
                      </div>

                      <div className="mt-3">
                        {isOtpScreen && (
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validationOtp.handleSubmit();
                            }}
                          >
                            <Otp validation={validationOtp} />
                            <LoginButton buttonName="Verify OTP" />
                          </Form>
                        )}
                        {!isOtpScreen && (
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validationSignup.handleSubmit();
                            }}
                          >
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              size="invisible"
                              sitekey={
                                configJs.google_recaptcha
                                  .REACT_RECAPTCHA_SITE_KEY
                              }
                            />
                            <Name validation={validationSignup} />
                            <Mobile validation={validationSignup} />
                            <Email validation={validationSignup} />
                            <LoginButton buttonName="Signup" />
                          </Form>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 text-center">
                      <p>
                        Already have an account ?
                        <Link
                          className="fw-medium text-primary"
                          to="/login"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                    <div className="mt-1 mt-md-3 text-center">
                      <p className="mb-0">
                        {new Date().getFullYear()} ©{" "}
                        {process.env.REACT_APP_NAME}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Signup);

Signup.propTypes = {
  history: PropTypes.object,
};


