import React, { useState, useEffect } from "react"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Form,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  Row,
  Input,
  FormGroup,
  FormFeedback,
  ModalBody,
} from "reactstrap"
import {
  addNewData as onAddNewData,
  getList as onGetList,
  getData as onGetData,
  clearData,
} from "store/AppMaster/actions"
import { useFormik } from "formik"
import * as Yup from "yup"
import api from "../../constants/api"
import { useParams } from "react-router-dom"
import LoginButton from "components/Common/PageComponent/LoginButton"
import AnotherButton from "components/Common/PageComponent/AnotherButton"
import { get, isEmpty } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import Description from "components/Common/PageComponent/Description"
import CustomSelect from "components/Common/PageComponent/DropdownList"
import EstimatedTime from "components/Common/PageComponent/EstimatedTime"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState } from "draft-js"
import { stateToHTML } from "draft-js-export-html"

import { WithContext as ReactTags } from "react-tag-input"
import CreateEditTaskModal from "pages/Project/CreateEditTaskModal"
import TaskViewModal from "./TaskViewModal"
import SimpleBar from "simplebar-react"

const TasksTab = props => {
  const params = useParams()
  const dispatch = useDispatch()

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [TaskModal, setTaskModal] = useState(false)
  const [priorityTask, setPriorityTask] = useState()
  const [updateListData, setUpdateListData] = useState(false)
  const [isApiCall, setIsApiCall] = useState(0)
  const [addFlag, setAddFlag] = useState(false)
  const [updateTaskMetaData, setUpdateTaskMetaData] = useState(false)
  const [projectFieldVisible, setProjectFieldVisible] = useState(true); // State variable to control the visibility of the project field


  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedStoryPoint, setSelectedStoryPoint] = useState([])
  const [selectedSprint, setSelectedSprint] = useState([])
  const [selectedAssignee_id, setSelectedAssignee_id] = useState([])
  const [selectedReporter_id, setSelectedReporter_id] = useState([])
  const [selectedTask_epic_id, setSelectedTask_epic_id] = useState([])
  const [selectedTasktype, setSelectedTasktype] = useState([])
  const [selectedProject, setSelectedProject] = useState([])
  const [selectedPriority, setSelectedPriority] = useState([])
  const [editorDescription, setEditorDescription] = useState(() =>
    EditorState.createEmpty()
  )
  const onEditorDescriptionChange = editorState => {
    setEditorDescription(editorState)
  }
  const [tags, setTags] = useState([])
  const [suggestions, setSuggestions] = useState()
  const [addButtonFlag, setAddButtonFlag] = useState(false)

  useEffect(() => {
    if (addButtonFlag == true) {
      getTaskMetaData()
    }
  }, [addButtonFlag])

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      // getTaskMetaData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeView))
  }
  if (!isEmpty(data) && !isEmpty(data.priority_task) && updateListData) {
    setPriorityTask(data.priority_task)
    setUpdateListData(false)
  }

  const getTaskMetaData = () => {
    setUpdateTaskMetaData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
  }

  if (!isEmpty(data) && updateTaskMetaData) {
    setUpdateTaskMetaData(false)
    data.status.map((item, index) => {
      selectedStatus.push({ label: item.column_name, value: item.column_name })
    })
    data.storyPoints.map((item, index) => {
      selectedStoryPoint.push({ label: item.name, value: item.id })
    })
    data.sprint.map((item, index) => {
      selectedSprint.push({ label: item.name, value: item.id })
    })
    data.employee.map((item, index) => {
      selectedAssignee_id.push({ label: item.name, value: item.id })
      selectedReporter_id.push({ label: item.name, value: item.id })
    })
    data.taskEpic.map((item, index) => {
      selectedTask_epic_id.push({ label: item.name, value: item.id })
    })
    data.taskType.map((item, index) => {
      selectedTasktype.push({ label: item.name, value: item.id })
    })
    data.priority.map((item, index) => {
      selectedPriority.push({ label: item.name, value: item.id })
    })
    data.project.map((item, index) => {
      selectedProject.push({ label: item.name, value: item.id })
    })
  }

  // const getTagData = (e) => {
  //   if (e && e.target && e.target.value) { // Ensure e and e.target are defined before accessing value
  //     dispatch(clearData());
  //     let filterData = {
  //       tag: e.target.value,
  //     };
  //     dispatch(onGetData(filterData, props.router.navigate, api.TagAutoComplete));
  //   }
  // }

  const getTagData = (inputValue) => {
    // console.log(inputValue.toLowerCase()); 
    dispatch(clearData());
    let filterData = {
      tag: inputValue,
    };
    dispatch(onGetData(filterData, props.router.navigate, api.TagAutoComplete));
    // console.log("inputValue", inputValue);
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.tags)) {
      console.log(data.tags);
      // const mappedSuggestions = data.tags.map(tag => ({
      //   // id: tag.id, // Assuming id is the same
      //   text: tag // Assuming name or some other property is used for text
      // }));
      // console.log(mappedSuggestions);

      setSuggestions(data.tags.map(tag => {
        console.log(tag);
        return {
          text: tag
        };
      }));
      // setSuggestions(mappedSuggestions.text);
    }
  }, [data?.tags]);

    // console.log("suggestions",suggestions);

  function tog_standard1() {
    setTaskModal(!TaskModal)
  }

  const taskvalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      project_id: "",
      status: "",
      summary: "",
      assignee_id: "",
      reporter_id: "",
      story_points: "",
      sprint_id: "",
      task_epic_id: "",
      task_type: "",
      priority: "",
      tags: "",
      estimated_time: "",
    },
    validationSchema: Yup.object({
      summary: Yup.string(),
      assignee_id: Yup.string().required("Please Enter Assignee"),
      reporter_id: Yup.string().required("Please Enter Reporter"),
      sprint_id: Yup.string().required("Please Enter Sprint"),
      task_epic_id: Yup.string().required("Please Enter Task Epic"),
      task_type: Yup.string().required("Please Enter Task Type"),
      priority: Yup.string().required("Please Enter Priority"),
    }),
    onSubmit: values => {
      setAddFlag(true)
      const contentDescription = editorDescription.getCurrentContent()
      const description = stateToHTML(contentDescription)
      let tagString = '';
      if (tags.length > 0) {
        tagString = tags.map(tag => tag.text).join(',');
    }
      const addData = {
        organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
        project_id: values["project_id"],
        status: values["status"],
        summary: values["summary"],
        assignee_id: values["assignee_id"],
        reporter_id: values["reporter_id"],
        story_points: values["story_points"],
        sprint_id: values["sprint_id"],
        task_epic_id: values["task_epic_id"],
        task_type: values["task_type"],
        priority: values["priority"],
        description: description,
        tags: tagString,
        estimated_time: values["estimated_time"],
      }
      dispatch(clearData())
      dispatch(onAddNewData(addData, props.router.navigate, api.TaskCreate))
    },
  })
  // console.log("taskvalidation",taskvalidation);
  if (!loading && taskvalidation.isSubmitting) {
    taskvalidation.isSubmitting = false
  }

  // Task Add
  if (!isEmpty(data) && !isEmpty(data.taskCreate) && addFlag) {
    setAddFlag(false)
    taskvalidation.resetForm()
    tog_standard1()
    dispatch(clearData())
    getListData()
  }

  const handleTask = selectedStatus => {
    taskvalidation.setFieldValue("status", selectedStatus)
  }
  const handleStoryPoint = selectedStoryPoint => {
    taskvalidation.setFieldValue("story_points", selectedStoryPoint)
  }
  const handleSprint = selectedSprint => {
    taskvalidation.setFieldValue("sprint_id", selectedSprint)
  }
  const handleAssignee_id = selectedAssignee_id => {
    taskvalidation.setFieldValue("assignee_id", selectedAssignee_id)
  }
  const handleReporter_id = selectedReporter_id => {
    taskvalidation.setFieldValue("reporter_id", selectedReporter_id)
  }
  const handleTask_epic_id = selectedTask_epic_id => {
    taskvalidation.setFieldValue("task_epic_id", selectedTask_epic_id)
  }
  const handleTaskType = selectedTasktype => {
    taskvalidation.setFieldValue("task_type", selectedTasktype)
  }
  const handlePriority = selectedPriority => {
    taskvalidation.setFieldValue("priority", selectedPriority)
  }
  const handleProject = selectedProject => {
    taskvalidation.setFieldValue("project_id", selectedProject)
  }
  const [inputText, setInputText] = useState("")
  const handleDelete = i => {
    setTags(tags.filter((tags, index) => index !== i))
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    taskvalidation.setFieldValue("tags", tags)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)

    setTags(newTags)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  const [singleTaskView, setSingleTaskView] = useState();
  const [modal, setModal] = useState(false);
  const handleEyeClick = (taskId) => {

    console.log(taskId);
    if(taskId){   
    const task = priorityTask.find(Tasks => Tasks.id === taskId);
    setSingleTaskView(task);
    setModal(!modal);
  }
   else {
    setModal(!modal);
          }
  };

   const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <div className="p-md-3">
        <Row className="mb-3 pt-3 pt-md-0">
          <Col className="text-end">
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                setAddButtonFlag(true)
                tog_standard1()

                // getTaskMetaData()
              }}
              data-toggle="modal"
              data-target="#myModal"
              className="btn btn-rounded"
            >
              <i className="mdi mdi-plus me-1"></i>
              Add Task
            </Button>
          </Col>
        </Row>
        <Row>
          {priorityTask &&
            priorityTask.map((Tasks, index) => {
              // console.log("Tasks :",Tasks);
              return (
                <>
                  <Col md="6" lg="6" xxl="4" key={index}>
                    {/*  */}
                    <Card onClick={() => handleEyeClick(Tasks.id)} style={{cursor :"pointer"}} className="shadow-sm border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 border-danger">
                      <CardBody>
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h4
                            className="text-muted font-size-15 mb-0"
                            id="task-budget"
                          >
                            #{Tasks && Tasks.id}
                          </h4>
                          <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                            {Tasks.story_points}
                          </span>
                        </div>
                        <h5 className="font-size-15">
                          <a className="text-dark" href="#">
                            {Tasks.summary}
                          </a>
                        </h5>
                        <div className="d-flex align-items-center justify-content-between mt-4">
                          <div>
                            <button
                              className=" btn-sm btn"
                              style={{
                                backgroundColor:
                                  Tasks && Tasks.project.background_color,
                              }}
                            >
                              {Tasks && Tasks.project.name}
                            </button>
                            <div className="d-flex align-items-center mt-2 pt-1">
                              <div className="avatar-xs">
                                <img
                                  src={
                                    Tasks && Tasks.assignee.profile_image_path
                                  }
                                  alt=""
                                  className="rounded-circle avatar-xs"
                                />
                              </div>
                              <h4 className="font-size-15 mb-0 ms-1">
                                {Tasks && Tasks.assignee.name}
                              </h4>
                            </div>
                          </div>
                          <div className="text-end">
                            {/* <p>
                              <span className="bg-danger badge me-1">
                                3 Days Delay
                              </span>
                            </p> */}
                            <span className="bg-light badge mt-5">
                              <img
                                className="me-1"
                                src={Tasks && Tasks.priority.priority_icon_path}
                              />
                              {Tasks && Tasks.priority.name}
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )
            })}
        </Row>
      </div>

      <Modal
        size="lg"
        isOpen={TaskModal}
        toggle={() => {
          tog_standard1()
        }}
        centered
      >
        <div className="modal-header" >
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Task
          </h5>
          <button
            type="button"
            onClick={() => {
              setTaskModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            taskvalidation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
          {/* <SimpleBar style={{ maxHeight: "350px" }}> */}

            <Row>
              <Col sm={12} md="6">
                <Label>Summary</Label>
                <Description
                  placeholder="Task Summary"
                  validation={taskvalidation}
                  name="summary"
                />
              </Col>
              <Col sm={12} md="6">
                <CustomSelect
                  label="Report To"
                  options={selectedReporter_id}
                  name="reporter_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleReporter_id}
                />
              </Col>
              {/* Assigne */}
              <Col sm={12} md="6">
                    <CustomSelect
                      label="Assigne To"
                      options={selectedAssignee_id}
                      name="assignee_id"
                      className="custom-select"
                      validation={taskvalidation}
                      onChange={handleAssignee_id}
                    />
                {/* <Button type="button" color="link" name="assignee_id"
                 onClick={() =>{
                  taskvalidation.setFieldValue("assignee_id", employee.id);
                }}
                  >
                    Assign to me
                  </Button> */}
              </Col>
              {/* Story Point */}
              <Col sm={12} md="6">
                <CustomSelect
                  label="Story Point"
                  // value={selectedValue}
                  options={selectedStoryPoint}
                  name="story_points"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleStoryPoint}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              {/* Status */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Status"
                  // value={selectedValue}
                  options={selectedStatus}
                  name="status"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleTask}
                />
              </Col>
              {/* Sprint */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Sprint"
                  options={selectedSprint}
                  name="sprint_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleSprint}
                />
              </Col>
              {/* Epic */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Task Epic"
                  options={selectedTask_epic_id}
                  name="task_epic_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleTask_epic_id}
                />
              </Col>
              {/* Tag */}
              <Col sm={12} md="6" className="mb-3">
                <div className="mb-2">
                  <FormGroup className="mb-3">
                    <Label>Tags</Label>
                    <ReactTags
                      placeholder="Enter Tags"
                      tags={tags}
                      // handleInputChange={(inputValue) => getTagData(inputValue)} // Pass inputValue directly
                      // handleInputChange={getTagData}
                      suggestions={suggestions}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                      classNames={{
                        tagInputField: "form-control",
                      }}
                    />
                    {taskvalidation.errors.tags ? (
                      <FormFeedback type="invalid">{taskvalidation.errors.tags}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </Col>
              {/* Task Type */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Task Type"
                  options={selectedTasktype}
                  name="task_type"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleTaskType}
                />
              </Col>
              {/* Priority */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Priority"
                  options={selectedPriority}
                  name="priority"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handlePriority}
                />
              </Col>
              {/* Project */}
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Project"
                  options={selectedProject}
                  name="project_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleProject}
                />
              </Col>
              <Col sm={12} md="6">
                <EstimatedTime validation={taskvalidation} />
              </Col>
              <Col sm={12}>
                <Label>Description</Label>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  placeholder="Place Your Content Here..."
                  // name="description"
                  // value={taskvalidation.values.description}
                  editorState={editorDescription}
                  onEditorStateChange={onEditorDescriptionChange}
                />
              </Col>
            </Row>

            {/* </SimpleBar> */}
          </div>
          <div className="modal-footer">
            <AnotherButton
              className="btn btn-secondary"
              onClick={e => {
                e.preventDefault()
                tog_standard1()
              }}
              buttonName="Cancel"
            />
            <LoginButton validation={taskvalidation} buttonName="Submit" />
          </div>
        </Form>
      </Modal>

      <TaskViewModal modal={modal} handleEyeClick={handleEyeClick} handleClose={handleClose} singleTask={singleTaskView} />    

      {/* <CreateEditTaskModal isOpen={TaskModal} toggle={tog_standard1} taskvalidation={taskvalidation}
        handleReporter_id={handleReporter_id} selectedReporter_id={selectedReporter_id}
        handleAssignee_id={handleAssignee_id} selectedAssignee_id={selectedAssignee_id}
        handleStoryPoint={handleStoryPoint} selectedStoryPoint={selectedStoryPoint}
        handleTask={handleTask} selectedStatus={selectedStatus}
        handleSprint={handleSprint} selectedSprint={selectedSprint}
        handleTask_epic_id={handleTask_epic_id} selectedTask_epic_id={selectedTask_epic_id}
        tags={tags} suggestions={suggestions} handleDelete={handleDelete} handleAddition={handleAddition} handleDrag={handleDrag} handleTagClick={handleTagClick}
        handleTaskType={handleTaskType} selectedTasktype={selectedTasktype}
        handlePriority={handlePriority} selectedPriority={selectedPriority}
        handleProject={handleProject} selectedProject={selectedProject} projectFieldVisible={true}
        editorDescription={editorDescription} onEditorDescriptionChange={onEditorDescriptionChange}
        /> */}
    </>
  )
}

export default withRouter(TasksTab)
