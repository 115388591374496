import axios from "axios";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchApiJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

const fetchApiFormData = async (url, formData) => {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("projectNayaAuthUser"))}`
    };
    let localOptions = { headers: headers };
    try {
        const response = await axios.post(url, formData, localOptions);
        // console.log(response.data.STATUS_CODE, "status");
        if (response.data.STATUS_CODE != 200) {
            throw new Error("Invalid status code: " + response.status);
        }
        return  response.data ; // Return data with no error
    } catch (error) {
        console.error('Error:', error);
        return { data: null, error }; // Return null data and the error
    }
};

export { fetchJSON, fetchApiJSON, fetchApiFormData };
