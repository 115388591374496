import labelConstant from 'constants/label';
import React from 'react';
import { FormFeedback, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types'
const Salary = ({validation}) => {

  return (
    <div className='mb-3'>
      <Label className="form-label" >{labelConstant.SALARY}</Label>
      <Input
        type="text"
        className="form-control"
        name="salary"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.salary || ""}
        invalid={validation.errors.salary}
        placeholder={"Enter " + labelConstant.SALARY}
      />
      {validation.errors.salary ? (
                <FormFeedback type="invalid">{validation.errors.salary}</FormFeedback>
            ) : null}
    </div>
  );
};

Salary.propTypes = {
  validation: PropTypes.object,
}

export default Salary;
