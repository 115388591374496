module.exports = {
  google_recaptcha: {
    REACT_RECAPTCHA_SITE_KEY: "6LfrmJ0pAAAAAJeHpGNd7nONlqKtUPD9RSeYa-Ic",
  },
  app_name: {
    REACT_APP_NAME: "Projectnaya",
  },
  api_parameter: {
    START: "0",
    LIMIT: "12",
    SORTBY: "id",
    SORTORDER: "desc",
  },
}
