import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardFooter, Col, Progress, Row } from "reactstrap"
import { size } from "lodash"

const CardColumn = props => {
  const { column, handleEditClick } = props

  return (
    <React.Fragment>
      <Row className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="auto">
                      <i className="fas fa-bars font-size-22"></i>
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        className="badge font-size-11 m-1"
                        style={{ backgroundColor: column.column_color }}
                      >
                        {column.column_name}
                      </Link>
                    </Col>
                    <Col md="auto">
                      <Link
                        to="#"
                        className="text-muted"
                        onClick={() => {
                          handleEditClick(column)
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        <i className="mdi mdi-pencil font-size-18"></i>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
    </React.Fragment>
  )
}

CardColumn.propTypes = {
  singleEmployee: PropTypes.object,
}

export default CardColumn
