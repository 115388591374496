import React, { useState, useEffect } from "react"
import withRouter from "components/Common/withRouter"
import { Badge, Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { useDispatch , useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import {
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
    getData as onGetData,
    clearData,
  } from "store/AppMaster/actions"
import { useFormik } from "formik"
import * as Yup from "yup"
import api from "../../constants/api"
import { isEmpty } from "lodash"
import TaskViewModal from "./TaskViewModal"
import { t } from "i18next"


const OverviewTab = props => { 
    const dispatch = useDispatch()
    const params = useParams()
  
    const { data } = useSelector(state => ({
      data: state.AppMaster.data,
    }))
    const { loading } = useSelector(state => ({
      loading: state.AppMaster.loading,
    }))

    const [isApiCall, setIsApiCall] = useState(0)
    const [updateListData, setUpdateListData] = useState(false)
    const [employee, setEmployee] = useState({})
    const [updateTaskData, setUpdateTaskData] = useState(false)
    const [singleTaskView, setSingleTaskView] = useState(false)
    const [priorityTask, setPriorityTask] = useState()
    const [noOfProjects, setNoOfProjects] = useState()
    const [todayHours, setTodayHours] = useState()
    const [monthlyHours, setMonthlyHours] = useState()
    const [activeTask, setActiveTask] = useState()
    const [modal, setModal] = useState(false)

    useEffect(() => {

        if (isApiCall == 0) {
          // getParticularTaskData()
          getListData()
          setIsApiCall(1) 
        }
      }, [isApiCall])

      // Employee View Api [Overview Page]
      const getListData = () => {
        setUpdateListData(true)
        dispatch(clearData())
        let filterData = {
          id: params.id,
        }
        dispatch(onGetList(filterData, props.router.navigate, api.employeeView))
      }
      if (!isEmpty(data) && !isEmpty(data.employee) && isEmpty(employee) && updateListData)
      {
        setEmployee(data.employee)
        setPriorityTask(data.priority_task)
        setActiveTask(data.active_task)
        setNoOfProjects(data.no_of_project)
        setTodayHours(data.today_hours)
        setMonthlyHours(data.monthly_hours)
        setUpdateListData(false)
      }

      const handleEyeClick = (taskId) => {

        if(taskId){
        // setUpdateTaskData(true)
       
        const task = priorityTask.find(task => task.id === taskId);
        setSingleTaskView(task);
        setModal(!modal);
      }
       else {
        setModal(!modal);
              }
      };

       const handleClose = () => {
        setModal(false);
      };

    return(
        <>
            <div className="p-md-3 mt-2 mt-md-0">
                <Row className="mt-3">
                  <Col md="4" xl="3">
                    <Card className="rounded-3">
                      <CardBody>
                        <h1 className="fw-bold text-dark">
                          {noOfProjects}
                        </h1>
                        <h4 className="mb-0 text-dark">No. Of Projects</h4>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" xl="3">
                    <Card className="rounded-3">
                      <CardBody>
                        <h1 className="fw-bold text-dark">
                          {activeTask}
                        </h1>
                        <h4 className="mb-0 text-dark">Active Task</h4>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" xl="3">
                    <Card className="rounded-3">
                      <CardBody>
                        <h1 className="fw-bold text-dark">
                          {parseFloat(todayHours).toFixed(2)}
                        </h1>
                        <h4 className="mb-0 text-dark">Today Hours</h4>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" xl="3">
                    <Card className="rounded-3">
                      <CardBody>
                        <h1 className="fw-bold text-dark">
                          {parseFloat(monthlyHours).toFixed(2)}
                        </h1>
                        <h4 className="mb-0 text-dark">Monthly Hours</h4>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <h4 className="mb-sm-0 font-size-18 text-dark">
                  Priority Tasks
                </h4>
                <Row className="mt-3">
                  {priorityTask &&
                    priorityTask.map((task, index) => {
                      return (
                        <>
                          <Col md="6" lg="6" xxl="4" key={index}>
                            <Card onClick={() => handleEyeClick(task.id)} className="border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 border-danger" style={{cursor : 'pointer'}}>
                              <CardBody >
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                  <h4
                                    className="text-muted font-size-15 mb-0"
                                    id="task-budget"
                                  >
                                    #{task.id}
                                  </h4>
                                  <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                                    {task.story_points}
                                  </span>
                                </div>
                                <h5 className="font-size-15">
                                  <p className="text-dark">
                                    {task && task.summary}
                                  </p>
                                </h5>
                                <div className="d-flex align-items-center justify-content-between mt-4">
                                  <div>
                                    <button
                                      className=" btn-sm btn"
                                      style={{
                                        backgroundColor:
                                          task && task.project.background_color,
                                      }}
                                    >
                                      {task && task.project.name}
                                    </button>
                                    <div className="d-flex align-items-center mt-2 pt-1">
                                      <div className="avatar-xs">
                                        <img
                                          src={
                                            employee && employee.profile_image
                                          }
                                          alt=""
                                          className="rounded-circle avatar-xs"
                                        />
                                      </div>
                                      <h4 className="font-size-15 mb-0 ms-1">
                                        {task && task.assignee.name}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="text-end mt-5">
                                    <span className="bg-light badge ">
                                      <img
                                        className="me-1"
                                        src={
                                          task &&
                                          task.priority.priority_icon_path
                                        }
                                      />
                                      {task && task.priority.name}
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </>
                      )
                    })}
                </Row>
              </div>
            
             <TaskViewModal modal={modal} handleEyeClick={handleEyeClick} handleClose={handleClose} singleTask={singleTaskView} />    

              </>
    )
}

export default withRouter(OverviewTab)