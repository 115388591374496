import PropTypes from 'prop-types';
import React from "react";
const AddButton = ({ buttonName, handleClicks }) => {

    return (
        <button type="button" onClick={handleClicks}
            className="me-2 btn btn-primary">
            <i className="mdi mdi-plus me-1"></i>
            {buttonName}
        </button>

    )
}

AddButton.propTypes = {
    buttonName: PropTypes.string,
    handleClicks: PropTypes.func
}

export default AddButton
