import PropTypes from 'prop-types';
import React from "react";
const AnotherButton = ({onClick, buttonName , className }) => {

    return (
        
        <div className="d-grid">
            <button
                className={className}
                // type="submit"
                onClick={onClick}
            >
                {buttonName}
            </button>
        </div>

    )
}

AnotherButton.propTypes = {
    buttonName: PropTypes.string,
}

export default AnotherButton
