import PropTypes from 'prop-types'
import { FormFeedback, Input, Label } from "reactstrap"
import React from "react";
import labelConstant from 'constants/label';
const ShortName = ({ validation }) => {

    return (
        <div className="mb-3">
            <Label className="form-label">{labelConstant.SHORTNAME}<span className="text-danger">*</span></Label>
            <Input
                name="short_name"
                className="form-control"
                placeholder={"Enter " + labelConstant.SHORTNAME}
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.short_name || ""}
                invalid={
                    validation.touched.short_name && validation.errors.short_name ? true : false
                }
            />
            {validation.touched.short_name && validation.errors.short_name ? (
                <FormFeedback type="invalid">{validation.errors.short_name}</FormFeedback>
            ) : null}
        </div>

    )
}

ShortName.propTypes = {
    validation: PropTypes.object,
}

export default ShortName
