import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";
const SelectInstitute = (props) => {
  const authDataJson = !isEmpty(localStorage.getItem('projectNayaAuthUserDetails')) ? JSON.parse(localStorage.getItem('projectNayaAuthUserDetails')) : '';

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {!isEmpty(authDataJson) && (

        <div className="page-content">
          <Container fluid>
            <Row>
              <Col className="col-12 text-center">
                <div className="home-wrapper">


                  <Row>
                    <Col md="7" className="mx-auto">
                      <Card className="border">
                        <div className="px-4 py-3 border-bottom">
                          <h5 className="text-muted font-size-15 mb-0 text-start">
                            <span className="text-dark ms-1" >
                              {authDataJson.userDetails.email}
                            </span>
                          </h5></div>
                        {authDataJson.organization && authDataJson.organization.map((singleOrganization, key) => (
                          <CardBody className="border-bottom workspace-institute-card" key={key}>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <img
                                      className="img-border-secondary rounded avatar-xs"
                                      src={singleOrganization.logo}
                                      alt=""
                                    />

                                  </div>
                                  <div>
                                    <h5 className="text-truncate font-size-15 mb-0 ms-2">
                                      <a className="text-dark" onClick={() => {
                                        localStorage.setItem('projectNayaOrganization', JSON.stringify(singleOrganization))
                                        navigate(
                                          `/${singleOrganization.slug}/organization/dashboard`
                                        )
                                      }}>
                                        {singleOrganization.organization_name}
                                      </a>
                                    </h5>
                                  </div>
                                </div>
                              </Col>
                              <Col xl="auto">
                                <a onClick={() => {
                                  localStorage.setItem('projectNayaOrganization', JSON.stringify(singleOrganization))
                                  navigate(
                                    `/${singleOrganization.slug}/organization/dashboard`
                                  )
                                }} className="page-title-box">
                                  <div className="open-arrow-custom d-flex align-items-center ">
                                    <p className="mb-0 me-2 open-text-new">open</p>
                                    <i className="mdi mdi-arrow-right"></i>
                                  </div>
                                </a>
                              </Col>
                            </Row>
                          </CardBody>
                        ))}
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
      }

    </React.Fragment>
  );
};

export default withRouter(SelectInstitute);