import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  Collapse,
  UncontrolledDropdown,
  Button,
} from "reactstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"

// Import Images
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import api from "../../constants/api"
import { getList as onGetList, clearData } from "store/AppMaster/actions"

const SprintTab = props => {
  document.title = "Project view | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { data, loading } = useSelector(state => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }))

  const [isApiCall, setIsApiCall] = useState(0)
  const [sprintList, setList] = useState([])
  const [statusListData, setStatusListData] = useState(false)
  const [sprintSearchValue, setSprintSearchValue] = useState("")
  const [updateTaskMetaData, setUpdateTaskMetaData] = useState(false)
  const [employee, setEmployee] = useState([])
  const [taskType, setTaskType] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [selectedTaskType, setSelectedTaskType] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [openCollapseIndex, setOpenCollapseIndex] = useState(0)
  const [showBacklog, setShowBacklog] = useState(false) // State to manage visibility of Backlog
  const [filterData, setFilterData] = useState({
    id: params.id,
    search: '',
    assign_id: '',
    type_id: '',
  });

  useEffect(() => {
    if (isApiCall === 0 && localStorage.getItem("customActiveTabProject") == "2") {
      getSprintListData()
      setIsApiCall(1)
    }
  }, [isApiCall, localStorage.getItem("customActiveTabProject")])

  useEffect(() => {
    if (localStorage.getItem("customActiveTabProject") == "2"){
      getTaskMetaData()
    }
  }, [localStorage.getItem("customActiveTabProject")])

  const getSprintListData = () => {
    setStatusListData(true)
    // let filterData = {
    //   id: params.id,
    //   search: sprintSearchValue,
    //   assign_id: selectedEmployee || "",
    //   type_id: selectedTaskType,
    // }
    dispatch(clearData())
    dispatch(onGetList(filterData, props.router.navigate, api.ProjectSprintList))
  }
  // useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.sprintData) && statusListData) {
      // console.log("SprintData",data.sprintData);
      setList(data.sprintData)
      setStatusListData(false)
    }
  // }, [data, statusListData])

  const handleSprintSearchValue = value => {
    // console.log("value",value);
    setSprintSearchValue(value)
    setFilterData(prevFilterData => ({
      ...prevFilterData, 
      search: value 
    }));
    setIsApiCall(0);
    // getSprintListData()
  }

  const handleEmployeeSelection = id => {
    // console.log();
    setSelectedEmployee(id)
    setFilterData(prevFilterData => ({
      ...prevFilterData, 
      assign_id: id 
    }));
    setOpenCollapseIndex(null) // Close all collapses when selecting employee
    // getSprintListData()
    setIsApiCall(0);
  }

  const handleTypeSelection = type => {
    // console.log("type",type);
    setSelectedTaskType(type)
    setFilterData(prevFilterData => ({
      ...prevFilterData,
      type_id: type
    }));
    setOpenCollapseIndex(null) // Close all collapses when selecting task type
    setIsApiCall(0);
    // getSprintListData()
  }

  const getTaskMetaData = () => {
    setUpdateTaskMetaData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
  }

  if (!isEmpty(data) && !isEmpty(data.employee) && updateTaskMetaData) {
    setEmployee(data.employee)
    setTaskType(data.taskType)
    setUpdateTaskMetaData(false)
  }

  const toggleCollapse = index => {
    if (sprintList.length === 0) {
      return
    }

    setOpenCollapseIndex(index === openCollapseIndex ? null : index)
  }

  const clearFilters = () => {
    // console.log(selectedEmployee, selectedTaskType);
    setSprintSearchValue("")
    setSelectedEmployee("")
    setSelectedTaskType("")
    setFilterData({ 
      id: params.id,
      search: '',
      assign_id: '',
      type_id: '',
    })
    setOpenCollapseIndex(0) // Close all collapses when clearing filters
    setIsApiCall(0);
    // getSprintListData()
  }

  return (
    <div>
      <Row>
        <Col sm="12">
          <Row>
            <Col xl="2">
              <InputGroup>
                <Input
                  type="text"
                  className="form-control rounded"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                  value={sprintSearchValue}
                  onChange={(e) => {handleSprintSearchValue(e.target.value)}}
                />
                <i className="mdi mdi-magnify font-size-16 position-absolute end-0 top-0 bottom-0 d-flex align-items-center m-auto pe-2 z-index"></i>
              </InputGroup>
            </Col>
            <Col xl="auto">
              <div className="avatar-group">
                {!isEmpty(employee) &&
                  employee.slice(0, 5).map((emp, index) => (
                    <div
                      className={`avatar-group-item ${
                        selectedEmployee == emp.id
                          ? "active-avatar-group-item"
                          : ""
                      }`}
                      key={index}
                    >
                      <a
                        className="d-inline-block"
                        id={`member${index + 1}`}
                        href="javascript:void(0)"
                        onClick={() => handleEmployeeSelection(emp.id)}
                      >
                        <div className="avatar-xs">
                          <span
                             className={`avatar-title rounded-circle bg-primary text-white font-size-16 ${
                              selectedEmployee === emp.id ? "bold-border" : ""}`}
                            title={emp.name}
                          >
                            {emp.name.charAt(0)}
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
                {!isEmpty(employee) && employee.length > 5 && (
                  <div className="avatar-group-item dropdown-container">
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={() => setDropdownOpen(!dropdownOpen)}
                    >
                      <DropdownToggle
                        caret
                        className="employee-dropdown-circle dropdown-circle"
                        style={{ backgroundColor: "#003664" }}
                      >
                        +{employee.length - 5}
                      </DropdownToggle>
                      <DropdownMenu>
                        {employee.slice(5).map((emp, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => handleEmployeeSelection(emp.id)}
                          >
                            {emp.name}
                          </DropdownItem>
                        ))}
                        {/* <DropdownItem>Unassigned</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </Col>
            <Col xl="auto">
              <UncontrolledDropdown>
                <DropdownToggle type="button" className="btn btn-light">
                  Type <span className="badge rounded-pill font-size-12 badge-soft-secondary ">
                  0
                      </span>
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {!isEmpty(taskType) &&
                    taskType.map((t, index) => {
                      // console.log(t);
                      return(
                      <DropdownItem
                        key={index}
                        onClick={() => handleTypeSelection(t.id)}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="defaultCheck1"
                            checked={selectedTaskType === t.id}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            {t.name}
                          </label>
                        </div>
                      </DropdownItem>
                    );
                    })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col
              xl="auto"
              style={{
                display:
                  sprintSearchValue || selectedEmployee || selectedTaskType
                    ? "block"
                    : "none",
              }}
            >
              <Button className="btn btn-light" onClick={clearFilters}>
                Clear Filter
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            {sprintList
              ? sprintList.map((sprint, index) => {
                  // console.log("sprint",sprint);
                return(
                  <Col xs="12" className="my-1" key={index}>
                    <div
                      onClick={() => toggleCollapse(index)}
                      className="bg-light p-3 rounded-top-4"
                      style={{ cursor: "pointer" }}
                    >
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center gap-2">
                            <h4 className="font-size-14 mb-0" title="Sprint Name">{sprint.name}</h4>
                            {/* <h5 className="font-size-14 mb-0">
                              Sprint {sprint.id}
                            </h5> */}
                            <h6 className="text-muted font-size-13 mb-0" title="Start Date - End Date">
                              {sprint.start_date} - {sprint.end_date}
                            </h6>
                            <h6 className="text-muted font-size-13 mb-0">
                              Issue
                              <span
                                className="badge rounded-pill font-size-12 badge-soft-secondary ms-1"
                                title="Issues"
                               > 
                                {sprint.task.length}
                              </span>
                            </h6>
                          </div>
                        </Col>
                        <Col xs="auto">
                          <span
                            className="badge rounded-pill font-size-12 badge-soft-secondary me-1"
                            title="ToDo"
                          >
                            {sprint.todo}
                          </span>
                          <span
                            className="badge rounded-pill font-size-12 badge-soft-info me-1"
                            title="In Progress"
                          >
                            {sprint.inProgress}
                          </span>
                          <span
                            className="badge rounded-pill font-size-12 badge-soft-success"
                            title="Completed"
                          >
                            {sprint.completed}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    <Collapse isOpen={index === openCollapseIndex}>
                      {sprint.task
                        ? sprint.task.map((task, index1) => 
                        {
                          // console.log("Task",task);
                          return (
                            <Card className="border mb-0" key={index1}>
                              <CardBody>
                                <Row className="align-items-center">
                                  <Col>
                                    <Row className="align-items-center">
                                      <Col sm={7}>
                                        <Row>
                                          <Col sm="auto">
                                            <p className="mb-0 d-flex align-items-center">
                                              <img
                                                className="me-1"
                                                height={20}
                                                width={20}
                                                src={task.task_type.icon_path}
                                              />
                                              <span className="text-muted font-size-13 mb-0">
                                                {task.sprint.name}
                                              </span>
                                            </p>
                                          </Col>
                                          <Col sm="auto">
                                            <span className="text-muted font-size-13 mb-0">
                                              {task.summary}
                                            </span>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col sm={3}>
                                        <Row>
                                          <Col sm="auto">
                                            <span
                                              className="badge  font-size-12"
                                              title={task.project.name}
                                              style={{
                                                backgroundColor:
                                                  task.project.background_color,
                                                  color: "white",
                                              }}
                                            >
                                              {task.project.name}
                                            </span>
                                          </Col>
                                          <Col sm="auto">
                                            <span
                                              className="badge font-size-12"
                                              title="Status"
                                              style={{
                                                backgroundColor: task.status_back_ground_color,
                                              }}
                                            >
                                              {task.status}
                                            </span>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col sm={2}>
                                        <Row className="align-items-center justify-content-end">
                                          <Col sm="auto">
                                            <span 
                                            className="badge rounded-pill font-size-12 badge-soft-secondary "
                                            title="Story Points"
                                            >
                                              {task.story_points}
                                            </span>
                                          </Col>
                                          <Col sm="auto">
                                            <div className="d-flex align-items-center gap-2">
                                              <span
                                              title={"Priority:"+" "+task.priority.name}
                                              >
                                                <img
                                                  className="me-1"
                                                  src={
                                                    task.priority
                                                      .priority_icon_path
                                                  }
                                                />
                                              </span>
                                              <div className="avatar-xs" title={"Assigne:" +" "+ task.assignee.name}>
                                                <img
                                                  src={
                                                    task.assignee
                                                      .profile_image_path
                                                  }
                                                  alt=""
                                                  className="rounded-circle avatar-xs"
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          );})
                        : ""}
                    </Collapse>
                  </Col>
                );})
              : ""}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(SprintTab)
