import PropTypes from 'prop-types'
import { FormFeedback, Input, Label } from "reactstrap"
import React from "react";
import labelConstant from 'constants/label';

const Otp = ({ validation }) => {
    const handleOtpChange = (e) => {
        const { value } = e.target;
        // Remove non-digit characters and limit to 6 characters
        const formattedOtp = value.replace(/\D/g, '').slice(0, 6);
        validation.setFieldValue("otp", formattedOtp);
    };

    return (
        <div className="mb-3">
            <Label className="form-label">{labelConstant.OTP}</Label>
            <Input
                name="otp"
                className="form-control"
                placeholder={"Enter " + labelConstant.OTP}
                type="text"
                onChange={handleOtpChange}
                onBlur={validation.handleBlur}
                value={validation.values.otp || ""}
                invalid={validation.errors.otp}
                maxLength={6} // Limit to 6 characters
            />
            {validation.errors.otp ? (
                <FormFeedback type="invalid">{validation.errors.otp}</FormFeedback>
            ) : null}
        </div>
    );
};

Otp.propTypes = {
    validation: PropTypes.object,
};

export default Otp;
