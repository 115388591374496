import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Form, Label } from "reactstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { FormGroup, FormFeedback } from "reactstrap"; // Import necessary components
import LoginButton from "components/Common/PageComponent/LoginButton";
import AnotherButton from "components/Common/PageComponent/AnotherButton";
import withRouter from "components/Common/withRouter";
import Description from "components/Common/PageComponent/Description";
import CustomSelect from "components/Common/PageComponent/DropdownList";
import EstimatedTime from "components/Common/PageComponent/EstimatedTime";
import { Editor } from "react-draft-wysiwyg";
import api from "../../constants/api";
import { getList as onGetList, clearData } from "store/AppMaster/actions";
import { isEmpty } from "lodash";

const CreateEditTaskModal = ({
  isOpen,
  toggle,
  taskvalidation,
  handleReporter_id,
  selectedReporter_id,
  handleAssignee_id,
  selectedAssignee_id,
  handleStoryPoint,
  selectedStoryPoint,
  handleTask,
  selectedStatus,
  handleSprint,
  selectedSprint,
  handleTask_epic_id,
  selectedTask_epic_id,
  tags,
  suggestions,
  handleDelete,
  handleAddition,
  handleDrag,
  handleTagClick,
  handleTaskType,
  selectedTasktype,
  handlePriority,
  selectedPriority,
  handleProject,
  selectedProject,
  projectFieldVisible,
  editorDescription,
  onEditorDescriptionChange,
  getTagData,
  router // Assuming router is passed as a prop
}) => {
  const { data, loading } = useSelector((state) => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }));
  const dispatch = useDispatch();
  const [tagSuggestions, setTagSuggestions] = useState([]);
  

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.tags)) {
      setTagSuggestions(data.tags);
    }
  }, [data]);

  const fetchTagSuggestions = (tag) => {
    dispatch(clearData());
    let filterData = {
      tag: tag,
    };
    dispatch(onGetList(filterData, router.navigate, api.TagAutoComplete));
  };

  const handleTagInputChange = (input) => {
    // Call API to fetch suggestions based on input
    if(input.length >= 3){
      fetchTagSuggestions(input);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Task
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          taskvalidation.handleSubmit();
          return false;
        }}
      >
        <div className="modal-body">
          <Row>
          <Row className="mt-3">
            <Col sm={12} md="6">
              <Label>Summary</Label>
              <Description
                placeholder="Task Summary"
                validation={taskvalidation}
                name="summary"
              />
            </Col>
            <Col sm={12} md="6">
              <CustomSelect
                label="Report To"
                showStrike={true}
                options={selectedReporter_id}
                name="reporter_id"
                className="custom-select"
                validation={taskvalidation}
                onChange={handleReporter_id}
              />
            </Col>
            <Col sm={12} md="6">
              <CustomSelect
                label="Assign To"
                showStrike={true}
                options={selectedAssignee_id}
                name="assignee_id"
                className="custom-select"
                validation={taskvalidation}
                onChange={handleAssignee_id}
              />
            </Col>
            <Col sm={12} md="6">
              <CustomSelect
                label="Story Point"
                options={selectedStoryPoint}
                name="story_points"
                className="custom-select"
                validation={taskvalidation}
                onChange={handleStoryPoint}
              />
            </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Status"
                  options={selectedStatus}
                  name="status"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleTask}
                />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Sprint"
                  showStrike={true}
                  options={selectedSprint}
                  name="sprint_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleSprint}
                />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Task Epic"
                  showStrike={true}
                  options={selectedTask_epic_id}
                  name="task_epic_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleTask_epic_id}
                />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <div className="mb-2">
                  <FormGroup className="mb-3 ">
                    <Label>Tags</Label>
                    <ReactTags
                      placeholder="Enter Tags"
                      tags={tags}
                      handleInputChange={handleTagInputChange}
                      suggestions={suggestions || tagSuggestions}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                      classNames={{
                        tagInputField: "form-control",
                      }}
                    />
                    {taskvalidation.errors.tags ? (
                      <FormFeedback type="invalid">
                        {taskvalidation.errors.tags}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Task Type"
                  showStrike={true}
                  options={selectedTasktype}
                  name="task_type"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleTaskType}
                />
              </Col>
              <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Priority"
                  showStrike={true}
                  options={selectedPriority}
                  name="priority"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handlePriority}
                />
              </Col>
              {/* <Col sm={12} md="6" className="mb-3">
                <CustomSelect
                  label="Project"
                  showStrike={true}
                  options={selectedProject}
                  name="project_id"
                  className="custom-select"
                  validation={taskvalidation}
                  onChange={handleProject}
                />
              </Col> */}
               <Col sm={12} md="6" className="mb-3">
                {projectFieldVisible && (
                  <CustomSelect
                    label="Project"
                    showStrike={true}
                    options={selectedProject}
                    name="project_id"
                    className="custom-select"
                    validation={taskvalidation}
                    onChange={handleProject}
                  />
                )}
              </Col>
              <Col sm={12} md="6">
                <EstimatedTime validation={taskvalidation} />
              </Col>
              <Col sm={12}>
                <Label>Description</Label>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  placeholder="Place Your Content Here..."
                  editorState={editorDescription}
                  onEditorStateChange={onEditorDescriptionChange}
                />
              </Col>
            </Row>
          </Row>
        </div>
        <div className="modal-footer">
          <AnotherButton
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            buttonName="Cancel"
          />{" "}
          <LoginButton validation={taskvalidation} buttonName="Submit" />
        </div>
      </Form>
    </Modal>
  );
};

export default withRouter(CreateEditTaskModal);
