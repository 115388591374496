import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Row, Modal, Form, Label, Input } from "reactstrap"
import { Link, useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  getList as onGetList,
  updateData as onUpdateData,
  UpdateFormData as onUpdateFormData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData,
} from "store/AppMaster/actions"
import { isEmpty } from "lodash"
import api from "../../constants/api"
import withRouter from "components/Common/withRouter"
import CreateEditProjectModal from "./CreateEditProjectModal"
import messageConstant from "constants/message"

const SettingTab = props => {
  document.title = "Project view | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const { data, loading } = useSelector(state => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }))
  const [isApiCall, setIsApiCall] = useState(0)
  const [modal, setModal] = useState(false)
  const [project, setProject] = useState(null)
  const [updateListData, setUpdateListData] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [editFlag, setEditFlag] = useState(false);

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (isApiCall == 0 && localStorage.getItem("customActiveTabProject") === "6") {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall, localStorage.getItem("customActiveTabProject")])
  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.ProjectView))
  }
  if (!isEmpty(data) && !isEmpty(data.Project) && updateListData) {
    setProject(data.Project)
    setUpdateListData(false)
  }

  // const validation = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     organization_id: project?.organization_id || "",
  //     name: project?.name || "",
  //     short_name: project?.short_name || "",
  //     logo: "",
  //     id: project?.id || ""
  //   },
  //   validationSchema: Yup.object({
  //     name: Yup.string()
  //       .required("Please Enter Name")
  //       .max(250, "Must be less than 250 characters")
  //       .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
  //     short_name: Yup.string()
  //       .required("Please Enter Short Name")
  //       .max(250, "Must be less than 250 characters")
  //       .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
  //   }),
  //   onSubmit: async values => {
  //     setIsUpdate(true);
  //     const updateData = {
  //       id: project.id,
  //       type:"formData",
  //       name: values["name"],
  //       short_name: values["short_name"],
  //       logo: values["logo"],
  //     }
  //     dispatch(clearData())
  //     dispatch(
  //       onUpdateData(updateData, props.router.navigate, api.ProjectSettingEdit)
  //     )
  //   },
  // })
  // if (!loading && validation.isSubmitting) {
  //   validation.isSubmitting = false
  // }
  // if (!isEmpty(data) && !isEmpty(data.Project) && isUpdate) {
  //   setIsUpdate(false);
  //   validation.resetForm();
  //   toggle();
  //   dispatch(clearData());
  //   getListData();
  //   }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      name: (project && project.name) || "",
      short_name: (project && project.short_name) || "",
      start_date: (project && project.start_date) || "",
      duration: (project && project.duration) || "",
      background_color: (project && project.background_color) || "",
      logo: "",
      id: (project && project.id) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please Enter Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s\-,:;''""]+$/, "Must be a valid Project"),
      short_name: Yup.string()
        .required("Please Enter Short Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
      start_date: Yup.string()
        .required("Please Enter Project Start Date"),
      duration: Yup.string()
        .required("Please Enter Project Duration in hours")
        .matches(/^[0-9\b]+$/, "Enter Only Numbers"),
      background_color: Yup.string().required(messageConstant.BACKGROUND_COLOR_REQUIRED).max(250, messageConstant.BACKGROUND_COLOR_INVALID)
      // profile_image: Yup.string().required("Please Enter Profile Image").max(15, "Must be less than  15 characters"),
    }),
    onSubmit: values => {
      // if (isEdit) {
        // isEdit(true)
        setEditFlag(true);
        const updateData = {
          id: project.id,
          type: "formData",
          name: values["name"],
          short_name: values["short_name"],
          start_date: values["start_date"],
          duration: values["duration"],
          background_color: values["background_color"],
          logo: values["logo"],
        }
        dispatch(clearData());
        dispatch(onUpdateFormData(updateData, props.router.navigate, api.projectEdit))
      // }
    },
  })

  if (!loading && validation.isSubmitting) {
      validation.isSubmitting = false;
  }
          //Project Edit
      if (!isEmpty(data) && !isEmpty(data.Project) && editFlag) {
        setEditFlag(false);
        validation.resetForm();
        setIsEdit(false);
        toggle();
        dispatch(clearData());
        getListData();
        }

  const handleImageChange = event => {
    const file = event.target.files[0]
    validation.setFieldValue("logo", file)
  }

  const [selectedDate, setSelectedDate] = useState(new Date())
  // Function to handle Date for date of birth and joining date
  const handleChange = event => {
    const selected = new Date(event.target.value)
    const today = new Date()
    if (selected <= today) {
      setSelectedDate(selected)
    }
  }

  function handleclick() {
    setModal(!modal)
    setIsEdit(false)
    // setProject(" ")
  }

  return (
    <div>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {project && (
                <Row className="align-items-center">
                  <Col lg="4">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <img
                          src={project.logo}
                          alt=""
                          className="avatar-md rounded-2 img img-fluid h-auto"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <h5 className="mb-2">{project.name}</h5>
                        <p className="mb-0 text-muted">{project.short_name}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4" className="align-self-center">
                    <Row>
                      <Col xs="4">
                        <Link to="/:organization_slug/organization/boardcolumn">
                          <h6 className="mb-0 text-muted">Board Column</h6>
                        </Link>
                      </Col>
                      <Col xs="4">
                        <Link to="/:organization_slug/organization/workflow">
                          <h6 className="mb-0 text-muted">Board Workflow</h6>
                        </Link>
                      </Col>
                      {/* <Col xs="4">
                        <Link to="#">
                          <h6 className="mb-0 text-muted">Project edit</h6>
                        </Link>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col lg="4" className="text-end">
                    <Link
                      to="#"
                      onClick={toggle}
                      className="btn btn-outline-dark btn-sm"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i className="mdi mdi-pencil font-size-18"></i>
                    </Link>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* setting edit modal */}
      {/* <Modal isOpen={modal} toggle={toggle}> */}
        {/* <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit Project
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}
        {/* <div className="modal-body"> */}
          {/* <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col md="12">
                <Label htmlFor="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.name && validation.errors.name ? (
                  <div className="text-danger">{validation.errors.name}</div>
                ) : null}
              </Col>
              <Col md="12">
                <Label htmlFor="short_name">Short Name</Label>
                <Input
                  type="text"
                  name="short_name"
                  id="short_name"
                  value={validation.values.short_name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.short_name &&
                validation.errors.short_name ? (
                  <div className="text-danger">
                    {validation.errors.short_name}
                  </div>
                ) : null}
              </Col>
              <Col md="12">
                <Label for="logo">Logo</Label>
                <Input
                  type="file"
                  name="logo"
                  id="logo"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Col>
            </Row>
          </Form> */}
          {/*  handleChange={handleChange} */}
          <CreateEditProjectModal isOpen={modal} toggle={toggle} isEdit={isEdit} validation={validation} handleImageChange={handleImageChange} buttonOnClick={(e)=>{
                e.preventDefault();
                handleclick();
                }} />
        {/* </div> */}
        {/* <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={toggle}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={validation.handleSubmit}
          >
            Save
          </button>
        </div> */}
      {/* </Modal> */}
    </div>
  )
}

export default withRouter(SettingTab)
