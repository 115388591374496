import React from 'react';
import PropTypes from 'prop-types';
import withRouter from './Common/withRouter';
//Import CSS
import "../assets/custom.scss";
const AuthLayoutWithoutSidebar = (props) => {
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

AuthLayoutWithoutSidebar.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(AuthLayoutWithoutSidebar);
