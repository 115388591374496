import React, { useState, useEffect } from "react"
import withRouter from "components/Common/withRouter"
import { Card, CardBody, Col, Progress, Row } from "reactstrap"
import { useSelector , useDispatch} from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import api from "../../constants/api"
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData,
} from "store/AppMaster/actions"
import CreateEditProjectModal from "pages/Project/CreateEditProjectModal"
import messageConstant from "constants/message"

const ProjectTab = props => {

  const dispatch = useDispatch()
  const params = useParams()

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [addFlag, setAddFlag] = useState(false);
  const [employeeProjects, setEmployeeProjects] = useState()
  const [isApiCall, setIsApiCall] = useState(0)
  const [updateProjectListData, setUpdateProjectListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0 && localStorage.getItem("customActiveTab") == "4"){
      getProjectData()
      setIsApiCall(1)
    }
  }, [isApiCall, localStorage.getItem("customActiveTab")])

  const getProjectData = () => {
    setUpdateProjectListData(true)
    dispatch(clearData())
    let filterData = {
      id: params.id,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.employeeProjects))
  }
  if (!isEmpty(data) && !isEmpty(data.projects) && isEmpty(employeeProjects) && updateProjectListData)
   {
    setEmployeeProjects(data.projects)
    setUpdateProjectListData(false)
  }
  function toggle() {
    setModal(!modal)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      name:  "",
      short_name:  "",
      start_date:  "",
      duration:  "",
      background_color:  "",
      logo: "",
      id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please Enter Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s\-,:;''""]+$/, "Must be a valid Project"),
      short_name: Yup.string()
        .required("Please Enter Short Name")
        .max(250, "Must be less than  250 characters")
        .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Project"),
      start_date: Yup.string()
        .required("Please Enter Project Start Date"),
      duration: Yup.string()
        .required("Please Enter Project Duration in hours")
        .matches(/^[0-9\b]+$/, "Enter Only Numbers"),
      background_color: Yup.string().required(messageConstant.BACKGROUND_COLOR_REQUIRED).max(250, messageConstant.BACKGROUND_COLOR_INVALID),

    }),
    onSubmit: values => {
        setAddFlag(true);
        const newData = {
          organization_id: localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
          type :"formData",
          name: values["name"],
          short_name: values["short_name"],
          start_date: values["start_date"],
          duration: values["duration"],
          background_color: values["background_color"],
          logo: values["logo"],
        }
        dispatch(clearData());
        dispatch(onAddNewData(newData, props.router.navigate, api.projectCreate))
    },
  })

  if (!loading && validation.isSubmitting) {
      validation.isSubmitting = false;
  }
            // Project Add
      if (!isEmpty(data) && !isEmpty(data.Project) && addFlag) {
        setAddFlag(false);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        // getListData();
        getProjectData();
      }

  const handleImageChange = (event) => {
    // setImage(event.target.files[0]);
    const file = event.target.files[0]
    validation.setFieldValue("logo", file)
};

function handleclick() {
  setModal(!modal)
  setIsEdit(false)
  // setProject(" ")
}

    return(
        <>
    <div className="p-md-3">
                <Row className="pt-3 pt-md-0">
                  {/* <Col sm="12" className="text-end mb-3">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => {
                        toggle()
                      }}
                      data-toggle="modal"
                      data-target="#myModal"
                      className="btn btn-rounded"
                    >
                      <i className="mdi mdi-plus me-1"></i>
                      Add Projects
                    </Button>
                  </Col> */}
                  {/* { Project View} */}
                  {employeeProjects &&
                    employeeProjects.map((project, index) => {
                      return (
                        <Col md="6" lg="6" xxl="4" key={index}>
                          <Card className="shadow-sm border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 border-light">
                            <CardBody>
                              <h4
                                className="text-muted font-size-15"
                                id="task-budget"
                              >
                                {project.short_name}
                              </h4>
                              <h5 className="font-size-15">
                                <a className="text-dark" href="#">
                                  {project.name}
                                </a>
                              </h5>
                              <div className="d-flex align-items-center gap-md-5 gap-3 mt-4">
                                <div className="text-center">
                                  <h3 className="text-dark mb-0">
                                    {project.todo_task}
                                  </h3>
                                  <h5 className="text-dark font-size-14 mb-0">
                                    Todo
                                  </h5>
                                </div>
                                <div className="text-center">
                                  <h3 className="text-dark mb-0">
                                    {project.in_progress}
                                  </h3>
                                  <h5 className="text-dark font-size-14 mb-0">
                                    In Progress
                                  </h5>
                                </div>
                                <div className="text-center">
                                  <h3 className="text-dark mb-0">
                                    {project.complete}
                                  </h3>
                                  <h5 className="text-dark font-size-14 mb-0">
                                    Completed
                                  </h5>
                                </div>
                              </div>
                              <Row className="align-items-center mt-3">
                                <Col>
                                  <Progress
                                    color="primary"
                                    value={
                                      (project.total_time / project.duration) *
                                      100
                                    }
                                  ></Progress>
                                </Col>
                                <Col sm="auto">
                                  <label
                                    className="font-size-20 mb-0"
                                    htmlFor="example-search-input"
                                  >
                                    <span className="text-primary me-1">
                                      {project.total_time}
                                    </span>
                                    /{" "}
                                    <b className="text-dark">
                                      {project.duration}
                                    </b>
                                  </label>
                                </Col>
                              </Row>
                              <div className="avatar-group mt-2">
                                {project.employee.profile_image &&
                                  project.employee.profile_image.map(
                                    (image, index) => {
                                      return (
                                        <div
                                          className="avatar-group-item"
                                          key={index}
                                        >
                                          <Link
                                            to="#"
                                            className="d-inline-block"
                                          >
                                            <div className="avatar-xs">
                                              <span
                                                className="avatar-title rounded-circle bg-info text-white font-size-16"
                                                style={{
                                                  backgroundImage: `url(${image})`,
                                                  backgroundSize: "cover",
                                                }}
                                              >
                                                {/* {" "} */}
                                              </span>
                                            </div>
                                          </Link>
                                        </div>
                                      )
                                    }
                                  )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}
                </Row>
              </div>
              {/* <CreateEditProjectModal isOpen={modal} toggle={toggle} isEdit={isEdit} validation={validation}  handleImageChange={handleImageChange} buttonOnClick={(e)=>{
                e.preventDefault();
                handleclick();
                }} /> */}
              </>
    )
}

export default withRouter(ProjectTab)