import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";

export function isImageJpgOrPng(dataURL) {
  try {
    return dataURL.startsWith('data:image/jpeg') || dataURL.startsWith('data:image/jpg') || dataURL.startsWith('data:image/png');
  } catch (err) {
    return false;
  }
}

 export function checkImageTypeForMultipart(file) {
  
  const allowedExtensions = ['png', 'jpeg', 'jpg'];
  const fileNameParts = file.name.split('.');
  const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  console.log("file" , extension);
  console.log(allowedExtensions.includes(extension));
  return allowedExtensions.includes(extension);
}
export function isImageSizeValid(dataURL, minWidth, minHeight) {
  const img = new Image();
  img.src = dataURL;

  return new Promise((resolve) => {
    img.onload = () => {
      if (img.width >= minWidth && img.height >= minHeight) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
}
export function isBase64(dataURL) {
  try {
    return btoa(atob(dataURL)) === dataURL;
  } catch (err) {
    return false;
  }
}
export function formateDate(date, format) {
  const dateFormat = format ? format : "DD-MM-YYYY";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};
export function isVideoValidation(dataURL) {
  try {
    return dataURL.startsWith('data:video/');
  } catch (err) {
    return false;
  }
}
export function getDates(startDate, endDate) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
export function formateTime(time, format) {
  const timeFormat = format ? format : "HH:mm";
  const time1 = moment(new Date(time)).format(timeFormat);
  return time1;
}
export function isPDF(base64) {
  const header = base64.substring(0, 25);
  return header.includes('data:application/pdf') || header.includes('data:application/octet-stream');
}

export function isDOC(base64) {
  const header = base64.substring(0, 20);
  console.log(header);
  return header.includes('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
    header.includes('data:application/msword') || header.includes('data:application/msw');
}
export function getCurrentPath() {
  const location = useLocation();
  const currentPath = location.pathname;
  return currentPath;
}
