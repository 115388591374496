const apiConstant = {
    checkEmail: 'v1/check-email',
    sendOtp: 'v1/send-otp',
    signUp: 'v1/organization-signup',
    login: 'v1/login',
    OrganizationProfile:'v1/organization/profile',
    OrganizationProfileEdit:'v1/organization/edit',
    employeeCreate: 'v1/organization/employee/create',
    employeeEdit:'v1/organization/employee/edit',
    employeeList: 'v1/organization/employee/list',
    employeeView:'v1/organization/employee/view',
    employeeTasks: 'v1/organization/employee/tasks',
    employeeProjects:'v1/organization/employee/projects',
    employeeLeaves:'v1/organization/employee/leaves',
    employeeTimeLog:'v1/organization/employee/time-log',
    projectCreate: 'v1/organization/project/create',
    projectEdit: 'v1/organization/project/edit',
    projectList:'v1/organization/project/list',
    sprintCreate : 'v1/organization/sprint/create',
    sprintEdit: 'v1/organization/sprint/edit',
    sprintList:'v1/organization/sprint/list',
    sprintStatusChange:'v1/organization/sprint/change-status',
    addTag: 'v1/organization/task-tag/create',
    tagList: 'v1/organization/task-tag/list',
    updateTag: 'v1/organization/task-tag/edit',
    epicList: 'v1/organization/task-epic/list',
    addEpic: 'v1/organization/task-epic/create',
    updateEpic: 'v1/organization/task-epic/edit',
    tasktimeLogList: 'v1/organization/task-time-log/list',
    TaskTimeLogDelete:'v1/organization/task-time-log/delete',
    LeaveCreate : 'v1/organization/leave-application/create',
    LeaveView : 'v1/organization/leave-application/view', 
    LeaveStatusChange : 'v1/organization/leave-application/change-status',   
    TimeLogEdit: 'v1/organization/task-time-log/edit',
    TaskCreate: 'v1/organization/task/create',
    TagAutoComplete:'v1/organization/task-tag/tags-auto-complete',
    TaskTypeCreate: 'v1/organization/task-type/create',
    TaskTypeEdit: 'v1/organization/task-type/edit',
    TaskTypeList: 'v1/organization/task-type/list',
    PriorityCreate: 'v1/organization/priority/create',
    PriorityEdit: 'v1/organization/priority/edit',
    PriorityList: 'v1/organization/priority/list',
    TaskStoryPointCreate : 'v1/organization/story-point/create',
    TaskStoryPointEdit : 'v1/organization/story-point/edit',
    TaskStoryPointList : 'v1/organization/story-point/list',
    TaskMetaData : 'v1/organization/task/meta-data',
    TaskView: 'v1/organization/task/view',
    ProjectView : 'v1/organization/project/view',
    ProjectSettingEdit : 'v1/organization/project/setting-edit',
    ProjectTimeLogList : 'v1/organization/project/employee-time-log',
    ProjectTaskList : 'v1/organization/project/task-list',
    ProjectSprintList : 'v1/organization/project/sprint-list',
    ProjectTimeLogListExport : 'v1/organization/project/employee-time-log-export',
    DesignationCreate: 'v1/organization/designation/create',
    DesignationEdit: 'v1/organization/designation/edit',
    DesignationList: 'v1/organization/designation/list',
    BoardColumnList : 'v1/organization/task-board-column/list',
    BoardColumnAdd : 'v1/organization/task-board-column/create',
    BoardColumnEdit : 'v1/organization/task-board-column/edit',
    
    BoardWorkFlowAdd : 'v1/organization/board-workflow/create',
    BoardWorkFlowList : 'v1/organization/board-workflow/list',
    BoardWorkFlowDelete : 'v1/organization/board-workflow/delete',

    // Project Board
    ProjectTaskBoardFilterData :'v1/organization/task-board/filter-data',
    ProjectTaskBoardList : 'v1/organization/task-board/list',
    
    // Organization Holiday
    AddHoliday : 'v1/organization/holiday/create',
    EditHoliday : 'v1/organization/holiday/edit',
    HolidayList : 'v1/organization/holiday/list',
}
export default apiConstant
