// Mobile.js 07-05-2024
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { FormFeedback, FormGroup, Label } from "reactstrap"
import labelConstant from "constants/label"
import IntlTelInput from "react-intl-tel-input"
import "react-intl-tel-input/dist/main.css"
import { isEmpty } from "lodash"

const Mobile = ({ validation }) => {
  const inputRef = useRef(null); // Ref for the input element
  const [phoneNumber, setPhoneNumber] = useState("")
  const [country, setCountry] = useState("") // State to hold country code
  const [countryCode, setCountryCode] = useState("") // State to hold country code
  useEffect(() => {
    fetchUserCountry() // Fetch user's country code when component mounts
  }, [])

  useEffect(() => {
    if (!isEmpty(validation.initialValues.mobile)) {
      // If country code is available, update default country in IntlTelInput
      setPhoneNumber(validation.initialValues.mobile)
    }
    if (!isEmpty(validation.initialValues.country_code)) {
      // If country code is available, update default country in IntlTelInput
      setCountryCode(validation.initialValues.country_code)
    }
  }, [validation.initialValues.mobile,validation.initialValues.country_code])

  const fetchUserCountry = () => {
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(data => {
        const country = data.country_calling_code
        setCountry(country) // Set country code in state
      })
      .catch(error => {
        console.error("Error fetching geo location:", error)
      })
  }

  const handleMobileChange = (status, phoneNumber, country) => {
    console.log("country",country);
    setPhoneNumber(phoneNumber.replace(/[^0-9]/g, ''));
    setCountry('');
    validation.setFieldValue('country_code', country.dialCode);
    validation.setFieldValue('mobile', phoneNumber.replace(/[^0-9]/g, ''));
    validation.setFieldValue('mobileStatus', status); 
    
    // let cc=value.split(' ')[0];
    // console.log("cc",cc);
    // let mo=value.split(' ')[1];
    // console.log("mo",mo);   
    // console.log(value);
 
  };


  const callingCodeToCountryCode = {
    '+1': 'us',
    '+91': 'in',
    '+44': 'gb',
    
  }
  const [isLoading, setIsLoading] = useState(true); // State to hold loading status

  useEffect(() => {
    if (!isEmpty(validation.initialValues.country_code)) {
      const callingCode = validation.initialValues.country_code;
      const countryCode = callingCodeToCountryCode[callingCode]; // Convert calling code to country code
      setCountryCode(countryCode); // Set country code in state
      setIsLoading(false); // Update loading status
      const inputElement = document.querySelector('.iti__selected-flag + input[type="tel"]');
      if (inputElement) {
        inputElement.focus();
        const len = inputElement.value.length;
        inputElement.setSelectionRange(len, len);
      }
    }
  }, [validation.initialValues.country_code]);


  return (
    <div className="mb-3">
      <FormGroup className="mb-3">
        <Label className="form-label">
          {labelConstant.MOBILE}
          <span className="text-danger">*</span>
        </Label>

        <IntlTelInput
         
          containerClassName="intl-tel-input"
          inputClassName={
            "form-control" 
            // +
            // (validation.touched.mobile && validation.errors.mobile
            //   ? " is-invalid"
            //   : "")
          }
          type="tel"  
          value={
            phoneNumber.startsWith("0")
              ? phoneNumber.substr(1)
              : phoneNumber
          }
          defaultCountry={countryCode ? countryCode : "in"}

          onPhoneNumberChange={handleMobileChange}
          placeholder="Enter Contact Number"
          style={{ width: "100%" }}
          invalid={
            validation.touched.mobile && validation.errors.mobile ? true : false
          }
          // ref={inputRef} // Assign the ref to the input element
        />

        {validation.touched.mobile && validation.errors.mobile ? (
          <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
        ) : validation.errors.mobileStatus ? (
          <FormFeedback type="invalid">
            {validation.errors.mobileStatus}
          </FormFeedback>
        ) : null}
      </FormGroup>
    </div>
  )
}

Mobile.propTypes = {
  validation: PropTypes.object,
}

export default Mobile
