import PropTypes from 'prop-types';
import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const TaskViewModal = props => {
  const {  modal, handleEyeClick, handleClose, singleTask  } = props;
  if (!singleTask) {
    // Return null if singleTask is false or undefined
    return null;
  }
  return (
    <React.Fragment>
     <Modal size="lg" isOpen={modal} toggle={() => handleEyeClick()} centered >
  <ModalHeader toggle={() => handleClose()}>
        <h4>#{singleTask.id}{" "}Task Details</h4>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <h5>Description</h5>
              <p>{singleTask.description}</p>
            </div>
            <div className="mb-3">
              <h5>Summary</h5>
              <p>{singleTask.summary}</p>
            </div>
          </div>
          <div className="col-md-6">
            <table className="table" border="3">
              <thead><b><h5>Details</h5></b></thead>
              <tbody>
                <tr>
                  <td>Assignee</td>
                  <td>
                    <img 
                      src={singleTask && singleTask.assignee.profile_image_path}
                      alt=""
                      className="rounded-circle avatar-xs"
                    />{" "}
                    {singleTask && singleTask.assignee.name}
                    </td>
                </tr>
                <tr>
                  <td>Reporter</td>
                  <td>
                  <img 
                      src={singleTask && singleTask.reporter.profile_image_path}
                      alt=""
                      className="rounded-circle avatar-xs"
                    />{" "}
                    {singleTask && singleTask.reporter.name}
                    </td>
                </tr>
                <tr>
                  <td>Storypoint</td>
                  <div> 
                  <td className='badge rounded-pill font-size-5 badge-soft-secondary'>{singleTask && singleTask.story_points}</td>
                   </div>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{singleTask && singleTask.status}</td>
                </tr>
                <tr>
                  <td>Epic</td>
                  <td >
                     <span style={{backgroundColor : `${singleTask && singleTask.task_epic.background_color}` , color : "white"}}>
                    {singleTask && singleTask.task_epic.name}
                    </span> 
                    </td>
                </tr>
                <tr>
                  <td>Sprint</td>
                  <td>{singleTask && singleTask.sprint.name}</td>
                </tr>
                <tr>
                  <td>Priority</td>
                  <td>
                  <img 
                      src={singleTask && singleTask.priority.priority_icon_path}
                      alt=""
                    />{" "}
                    {singleTask && singleTask.priority.name}
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
      <button className="btn btn-primary" onClick={() => handleClose()}>Close</button>
      </ModalFooter>
    </Modal>
    </React.Fragment>
  );
};

TaskViewModal.propTypes = {
  singleEmployee: PropTypes.object
};

export default TaskViewModal;
