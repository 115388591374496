const messageConstant = {
    EMAIL_REQUIRED: 'Email is required',
    EMAIL_INVALID: 'Email is invalid',
    OTP_REQUIRED: 'OTP is required',
    OTP_INVALID: 'OTP is invalid',
    NAME_REQUIRED: 'Name is required',
    NAME_INVALID: 'Name is invalid',
    BACKGROUND_COLOR_INVALID : 'Background Color is invalid',
    MOBILE_REQUIRED: 'Mobile is required',
    MOBILE_INVALID: 'Mobile is invalid',
    LOGO_REQUIRED: 'Logo is required',
    LOGO_TYPE: 'Logo type should be jpg or png',
    LOGO_DIMENSION: 'Logo Image dimensions should be at least 500 pixels.',
    DATE_REQUIRED: 'Date is required',
    SALARY_REQUIRED: 'Salary is required',
    BACKGROUND_COLOR_REQUIRED : 'Background Color is required',
    COLUMN_NAME_REQUIRED: 'Column Name is required',

}
export default messageConstant
