import Breadcrumbs from "components/Common/Breadcrumb2";
import Name from "components/Common/PageComponent/Name";
import ToggleSubmitCancelButton from "components/Common/PageComponent/ToggleSubmitCancelButton";
import TableContainer from "components/Common/TableContainer";
import withRouter from "components/Common/withRouter";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import {
    clearData,
    addNewData as onAddNewData,
    getList as onGetList,
    updateData as onUpdateData
} from "store/AppMaster/actions";
import * as Yup from "yup";
import configJs from '../../../../config';
import api from '../../../../constants/api';
import messageConstant from "constants/message";
import AddButton from "components/Common/PageComponent/AddButton";
import BackGroundColor from "components/Common/PageComponent/BackGroundColor";
const Epic = (props) => {
    document.title = "Epic | " + configJs.app_name.REACT_APP_NAME;
    const dispatch = useDispatch();
    const [adminEpic, setEpic] = useState('');
    const [editEpic, setEditEpic] = useState(false);
    const [addEpic, setAddEpic] = useState(false);
    /**Epic List*/
    const [listCount, setListCount] = useState(0);
    const [filterData, setFilterData] = useState({
        start: configJs.api_parameter.START,
        limit: 10,
        search: '',
        sort_by: configJs.api_parameter.SORTBY,
        sort_order: configJs.api_parameter.SORTORDER,
    });
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }));
    const [isApiCall, setIsApiCall] = useState(0);
    const [epicList, setEpicList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [updateListData, setUpdateListData] = useState(false);


    useEffect(() => {
        if (isApiCall == 0) {
            getListData();
            setIsApiCall(1);
        }

    }, [isApiCall]);
    const getListData = () => {
        setUpdateListData(true);
        dispatch(clearData());
        dispatch(onGetList(filterData, props.router.navigate, api.epicList))
    }
    if (!isEmpty(data) && !isEmpty(data.task_epic) && updateListData) {
        setEpicList(data.task_epic);
        setListCount(data.filter_count);
        setUpdateListData(false);
    }

    const toggle = () => {
        setModal(!modal);
    };
    const handleClicks = () => {
        validation.resetForm();
        setEpic();
        setIsEdit(false);
        toggle();
    };
    const handleEditClick = arg => {
        const adminEpic = arg;
        setEpic({
            id: adminEpic.id,
            name: adminEpic.name,
            background_color: adminEpic.background_color,
        });
        setIsEdit(true);
        toggle();
    };

    /**Add Edit Epic*/
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: (adminEpic && adminEpic.name) || "",
            background_color: (adminEpic && adminEpic.background_color) || "",
            id: (adminEpic && adminEpic.id) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(messageConstant.NAME_REQUIRED).max(250, messageConstant.NAME_INVALID)
                .matches(
                    /^[a-zA-Z0-9 \s]+$/,
                    "Must be a valid Epic"
                ),
            background_color: Yup.string().required(messageConstant.BACKGROUND_COLOR_REQUIRED).max(250, messageConstant.BACKGROUND_COLOR_INVALID)
        }),
        onSubmit: values => {
            if (isEdit) {
                setEditEpic(true);
                const updateData = {
                    id: adminEpic.id,
                    name: values["name"],
                    background_color: values["background_color"]
                };
                dispatch(clearData());
                dispatch(onUpdateData(updateData, props.router.navigate, api.updateEpic))

            } else {
                setAddEpic(true);
                const newData = {
                    name: values["name"],
                    background_color: values["background_color"]
                };
                dispatch(clearData());
                dispatch(onAddNewData(newData, props.router.navigate, api.addEpic))

            }
        },
    });
    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false;
    }
    if (!isEmpty(data) && !isEmpty(data.task_epic) && addEpic) {
        setAddEpic(false);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        getListData();
    }
    if (!isEmpty(data) && !isEmpty(data.task_epic) && editEpic) {
        setEditEpic(false);
        validation.resetForm();
        setIsEdit(false);
        toggle();
        dispatch(clearData());
        getListData();
    }
    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Name",
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Background Color",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    return (
                        <>
                            <div className="d-flex gap-3">
                                <div
                                    style={{
                                        height: "19px",
                                        width: "19px",
                                        background: cell.row.original.background_color
                                    }}
                                />
                                <div>{cell.row.original.background_color}</div>
                            </div>
                        </>
                    );
                }
            },
            {
                header: "Action",
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#"
                                className="text-secondary"
                                onClick={() => {
                                    const editData = cellProps.row.original;
                                    handleEditClick(editData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="editTeamTooltip" />
                                <UncontrolledTooltip placement="top" target="editTeamTooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );
    const breadcrumbItems = [
        { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
        { title: "Task Epic", path: `/${props.router.organization_slug}/organization/task-epic` },
    ];
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Epic" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col md={12}>

                            <>
                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={(epicList) ? epicList : []}
                                                    isGlobalFilter={true}
                                                    isCustomPageSize={true}
                                                    isPagination={true}
                                                    SearchPlaceholder={listCount > 0 ? "Search in " + listCount + " records..." : "Search in records..."}
                                                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    isAddButton={true}
                                                    buttonName="Add Epic"
                                                    handleClicks={handleClicks}
                                                    filterData={filterData}
                                                    getListData={getListData}
                                                    listCount={listCount}
                                                    isApiCall={isApiCall}
                                                />

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Modal isOpen={modal} toggle={toggle} centered>
                                    <ModalHeader toggle={toggle} epic="h4">
                                        {!!isEdit ? "Edit Epic" : "Add Epic"}
                                    </ModalHeader>

                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <ModalBody>
                                            <Row>
                                                <Name validation={validation} />
                                            </Row>
                                            <Row>
                                                <BackGroundColor validation={validation} showStrike={true} />
                                            </Row>
                                        </ModalBody>

                                        <div className="modal-footer">
                                            <ToggleSubmitCancelButton validation={validation} cancelToggle={() => toggle()} />
                                        </div>
                                    </Form>

                                </Modal>
                            </>



                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withRouter(Epic);