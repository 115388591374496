import React, { useState, useEffect } from "react"
import withRouter from "components/Common/withRouter"
import { Button, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import { useDispatch , useSelector} from "react-redux"
import { useParams } from "react-router-dom"
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  UpdateFormData as onUpdateFormData,
  clearData
} from "store/AppMaster/actions";
import api from '../../constants/api';
import { isEmpty } from "lodash"
import moment from "moment"
import * as Yup from "yup";
import { useFormik } from "formik";
import labelConstant from "constants/label";
import AnotherButton from "components/Common/PageComponent/AnotherButton"
import LoginButton from "components/Common/PageComponent/LoginButton"
import Name from "components/Common/PageComponent/Name"
import Email from "components/Common/PageComponent/Email"
import Mobile from "components/Common/PageComponent/Mobile"
import { checkImageTypeForMultipart } from "common/commonFunction"


const OrganizationProfile = (props) => {
  document.title = "Profile | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch();
  const params = useParams();
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
}));
const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
}));
  const breadcrumbItem = [
    { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
    { title: "Profile", path: `/${props.router.organization_slug}/organization/profile` }
];
    const [OrganizationDetails, setOrganizationDetails] = useState({});
    const [isApiCall, setIsApiCall] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editFlag, setEditFlag] = useState(false);

    useEffect(() => {
      if (isApiCall == 0) {
          getUserData();
          setIsApiCall(1);
      }
    }, [isApiCall]);

    const getUserData = () => {
      dispatch(clearData());
      setOrganizationDetails('');
      const filterData = {
       id : localStorage.getItem("projectNayaOrganization")
       ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
       : 0,
      }
      dispatch(onGetList(filterData, props.router.navigate, api.OrganizationProfile))
    }
    const [mobile,setMobile] = useState();
    if (!isEmpty(data) && !isEmpty(data.organization) && isEmpty(OrganizationDetails)) {
      setOrganizationDetails(data.organization);
      // setMobile(data.organization.country_code+data.organization.mobile)
    }
    const toggle = () =>{
      setModalOpen(!modalOpen)
      }
    const handleClick = () => {
      // setEmployee(' ');
      setModalOpen(!modalOpen);
      setIsEdit(false);
    }

    const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
          organization_id:localStorage.getItem("projectNayaOrganization")
          ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
          : 0,
          name: (OrganizationDetails && OrganizationDetails.name) || "",
          email: (OrganizationDetails && OrganizationDetails.email) || "",
          mobile: (OrganizationDetails && OrganizationDetails.mobile) || "",
          // mobile: mobile || "",
          mobileStatus: "",
          // country_code: (employee && employee.country_code) || "",
          logo: "",
      },
      validationSchema: Yup.object({
          name: Yup.string().required("Please Enter Name").max(250, "Must be less than  250 characters")
              .matches(
                  /^[a-zA-Z\s]+$/,
                  "Only Alphabets are allowed for Name"),
          email: Yup.string().email("Email is required and format should be john@doe.com.").required("Please Enter Email").max(250, "Must be less than  250 characters"),
          mobile: Yup.string().required("Mobile Number is Required")
          .matches(/^[+0-9]+$/i, "Please Enter Valid Mobile")
          .min(9, "Please Enter Valid Mobile")
          .max(12, "Please Enter Valid Mobile"),
          mobileStatus: Yup.boolean().oneOf([true], "Please Enter Valid Mobile"),
          logo: Yup.mixed()
          .test("fileType", "Only JPG, JPEG, and PNG file formats are accepted.", (value) => {
              if (!value) {
                  return true;
              }
              return checkImageTypeForMultipart(value);
          }),
      }),
      onSubmit: values => {
              setEditFlag(true)
              setIsEdit(true);
              const updateData = {
                id:localStorage.getItem("projectNayaOrganization")
                  ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
                  : 0,
                  type :"formData",
                  name: values["name"],
                  email: values["email"],
                  mobile: values["mobile"],
                  logo:values["logo"]
              };
              dispatch(clearData());
              dispatch(onUpdateFormData(updateData, props.router.navigate, api.OrganizationProfileEdit))   
      },
  });

  if (!loading && validation.isSubmitting) {
      validation.isSubmitting = false;
  }

  if (!isEmpty(data) && !isEmpty(data.organization) && editFlag) {
    setEditFlag(false);
    validation.resetForm();
    setIsEdit(false);
    toggle();
    dispatch(clearData());
    getUserData();
}
  const handleImageChange = (event) => {
    const file = event.target.files[0]
    validation.setFieldValue("logo",file)
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Organization Profile" breadcrumbItems={breadcrumbItem} />
          <Row className="mb-4">
              <div className="col-sm-12">
                  <div className="text-end">
                  <button type="button" className="me-2 btn btn-md btn-outline-primary" onClick={()=>{toggle()}}>
               <i className="mdi mdi-pencil me-1" />Edit
            </button>  
              </div>
                        </div>
                    </Row>
          <div className="row">
            <div className="col-lg-12">
              
              <div className="card">
                  <div className="card-body">
                  <div className="align-items-center row">
                    <div className="mb-2 mb-md-0 col-12 col-md-2 col-xl-auto">
                      <div className="">
                        <img
                          className="avatar-md rounded-circle d-block mx-auto"
                          src={OrganizationDetails.logo}
                          alt=""
                        ></img>
                      </div>
                    </div>
                    <div className="col-md-10 col-xl-10 col-xxl-11">
                      <div className="row">
                        <div className="mb-2 mb-md-0 col-md-4">
                          <h5 className="mb-0">{OrganizationDetails.name}</h5>
                        </div>
                        <div className="mb-2 mb-md-0 col-md-4">
                          <i className="fas fa-envelope"></i>{" "}
                          {OrganizationDetails.email}
                        </div>
                        <div className="col-md-4">
                          <i className="fas fa-phone-alt"></i> 
                            {" "}
                          {OrganizationDetails.mobile}
                        </div>
                      </div>
                      <div className="mt-2 row">
                        <div className="mb-2 mb-md-0 col-md-4">
                          <span className="badge bg-success">{OrganizationDetails.status}</span>
                        </div>
                        <div className="col-md-5 col-xl-4">
                          <span className="fw-medium">Created Date: </span>
                          {moment(OrganizationDetails.created_at).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
                
              </div>
            </div>
          </div>

          {/* Edit Profile Modal */}
          <Modal size="lg" isOpen={modalOpen} toggle={toggle} >
        <ModalHeader  toggle={()=>handleClick() } ><h4>Edit Profile</h4></ModalHeader>
              <div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit()
                  return false
                }}
              >
                 <ModalBody>
                <Row>
                    <Col md="6">
                    <Name validation={validation} />
                    </Col>
                    <Col md="6">
                    <Email validation={validation} />
                    </Col>
                    <Col md="6">
                    <Mobile validation={validation} />
                    </Col>
                    <Col md="6">
                    <Label className="form-label">{labelConstant.PROFILEIMAGE}</Label>
                    <Input
                    name="logo"
                    type="file"
                    accept="image/*"
                    invalid={
                        validation.touched.logo && validation.errors.logo ? true : false
                    }
                    onChange={handleImageChange} />
                   { validation.touched.logo && validation.errors.logo ? (
                <FormFeedback type="invalid">{validation.errors.logo}</FormFeedback>
            ) : null}
                    </Col>
                </Row>
              {/* </Form> */}
              </ModalBody>
            <ModalFooter>
              <div className="d-flex align-items-center gap-2">
                <AnotherButton  className="btn btn-secondary" onClick={
                    (e)=>{
                        e.preventDefault();
                        handleClick()}}
                buttonName="Cancel"
                />{' '}
                <LoginButton
                buttonName="Submit"
                />
              </div>
            </ModalFooter>
            </Form>
            </div>
            
    </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(OrganizationProfile)
