import moment from 'moment';
import PropTypes from 'prop-types';
import React,{useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Progress,
  Row,
} from "reactstrap";

const CardSprint = props => {
  const { singleSprint , handleEditClick , handleStatusChange } = props;
  console.log("singleSprint",singleSprint);
  let startDate = singleSprint.start_date;
  let endDate = singleSprint.end_date;
  return (
    <React.Fragment>
      <Col xs={12} md={6} xl={3}>
    <Card className="border-5 rounded-4 border-end-0 border-top-0 border-bottom-0">
        <CardBody>
          <div className="d-flex align-items-start align-items-md-center justify-content-between gap-2">
            
            <h3 className="font-size-15">
            {singleSprint.name}            
            </h3>

            <Link to="#" className="text-dark" onClick={() => {
                                    handleEditClick(singleSprint);
                                }}>
                <i className="fas fa-pencil-alt" />
                
                </Link>
                <span className="bg-success badge me-1 text-end" style={{cursor : "pointer"}} onClick={()=>{handleStatusChange(singleSprint.id)}} >{singleSprint.status}</span>
                </div>
            <div className="mt-4">
              <span className="font-size-16">
              <i className=" mdi mdi-calendar me-1" />
                {
                 "Start Date: "+ moment(startDate).format('MMM DD YYYY') 
                } 
                </span>
                <p className='font-size-16 mt-1'>
                  <i className=" mdi mdi-calendar me-1" />
                {
                 "End Date: "+ moment(endDate).format('MMM DD YYYY') 
                } 
                </p>
              </div>
            <Row className="mt-4">
                <Col md="6" xs="6">
                    <span className="font-size-12 badge-soft-danger badge bg-secondary">Issue<span className="badge bg-danger rounded-pill ms-2">{singleSprint.bugs}</span></span>
                             </Col>
                <Col md="6" xs="6"  className="text-end">
                    <span className="font-size-12 bg-light badge">ToDo<span className="badge bg-dark-subtle text-dark rounded-pill ms-2">{singleSprint.todo}</span></span>
                </Col>
                <Col md="6" xs="6" className="mt-2">
                    <span className="font-size-12 bg-info-subtle text-info badge">In Progress<span className="badge bg-info rounded-pill ms-2">{singleSprint.inProgress}</span></span>
                </Col>
                <Col md="6" xs="6" className="text-end mt-2">
                    <span className="font-size-12 bg-success-subtle text-success badge">Completed<span className="badge bg-success rounded-pill ms-2">{singleSprint.completed}</span></span>
                </Col>
            </Row>

        </CardBody>
    </Card>
      </Col>
    </React.Fragment>
  );
};

CardSprint.propTypes = {
  singleEmployee: PropTypes.object
};

export default CardSprint;
