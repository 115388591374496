import Breadcrumbs from "components/Common/Breadcrumb2";
import Name from "components/Common/PageComponent/Name";
import ToggleSubmitCancelButton from "components/Common/PageComponent/ToggleSubmitCancelButton";
import TableContainer from "components/Common/TableContainer";
import withRouter from "components/Common/withRouter";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {Card,CardBody,Col,Container,Form,FormFeedback,Input,Label,Modal,ModalBody,ModalHeader,Row,UncontrolledTooltip} from "reactstrap";
import {
    clearData,
    addNewData as onAddNewData,
    getList as onGetList,
    updateData as onUpdateData
} from "store/AppMaster/actions";
import * as Yup from "yup";
import configJs from '../../../../config';
import api from '../../../../constants/api';
import messageConstant from "constants/message";
import AddButton from "components/Common/PageComponent/AddButton";
import BackGroundColor from "components/Common/PageComponent/BackGroundColor";
const TaskStoryPoint = (props) => {
    document.title = "Story Point | " + configJs.app_name.REACT_APP_NAME;
    const dispatch = useDispatch();
    const [adminPriority, setPriority] = useState('');
    const [editTag, setEditTag] = useState(false);
    const [addTag, setAddTag] = useState(false);
    /**Tag List*/
    const [listCount, setListCount] = useState(0);
    const [filterData, setFilterData] = useState({
        start: configJs.api_parameter.START,
        limit: configJs.api_parameter.LIMIT,
        search: '',
        sort_by: configJs.api_parameter.SORTBY,
        sort_order: configJs.api_parameter.SORTORDER,
    });
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }));
    const [isApiCall, setIsApiCall] = useState(0);
    const [priorityList, setPriorityList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [updateListData, setUpdateListData] = useState(false);

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Name",
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Description",
                accessorKey: 'description',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Action",
                cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#"
                                className="text-secondary"
                                onClick={() => {
                                    const editData = cellProps.row.original;
                                    handleEditClick(editData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="editTeamTooltip" />
                                <UncontrolledTooltip placement="top" target="editTeamTooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );
    useEffect(() => {
        if (isApiCall == 0) {
            getListData();
            setIsApiCall(1);
        }
    }, [isApiCall]);
    const getListData = () => {
        setUpdateListData(true);
        dispatch(clearData());
        dispatch(onGetList(filterData, props.router.navigate, api.TaskStoryPointList))
    }
    if (!isEmpty(data) && !isEmpty(data.story_point) && updateListData) {
        setPriorityList(data.story_point);
        setListCount(data.filter_count);
        setUpdateListData(false);

    }

    const toggle = () => {
        setModal(!modal);
    };
    const handleClicks = () => {
        validation.resetForm();
        setPriority();
        setIsEdit(false);
        toggle();
    };
    const handleEditClick = arg => {
        const adminPriority = arg;
        // console.log("arg",arg);
        setPriority({
            id: adminPriority.id,
            name: adminPriority.name,
            description: adminPriority.description,
            // background_color : adminPriority.background_color,
        });
        setIsEdit(true);
        toggle();
    };

    /**Add Edit Priority*/
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: (adminPriority && adminPriority.name) || "",
            description: (adminPriority && adminPriority.description) || "",
            id: (adminPriority && adminPriority.id) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(messageConstant.NAME_REQUIRED).max(250, messageConstant.NAME_INVALID)
                .matches(
                    /^[0-9\s]+$/,
                    "Must be a valid Story Point"
                ),
            description: Yup.string()
            .matches(/^[a-zA-Z0-9\s]+$/, "Must be a valid Description")
            .max(250, "Must be less than  250 characters"),
                
        }),
        onSubmit: values => {
            if (isEdit) {
                setEditTag(true);
                const updateData = {
                    id: adminPriority.id,
                    name: values["name"],
                    description: values["description"],
                };
                dispatch(clearData());
                dispatch(onUpdateData(updateData, props.router.navigate, api.TaskStoryPointEdit))

            }
            else {
                setAddTag(true);
                const newData = {
                    name: values["name"],
                    description: values["description"],
                };
                dispatch(clearData());
                dispatch(onAddNewData(newData, props.router.navigate, api.TaskStoryPointCreate))

            }
        },
    });
    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false;
    }
    if (!isEmpty(data) && !isEmpty(data.story_point) && addTag) {
        setAddTag(false);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        getListData();
    }
    if (!isEmpty(data) && !isEmpty(data.story_point) && editTag) {
        setEditTag(false);
        validation.resetForm();
        setIsEdit(false);
        toggle();
        dispatch(clearData());
        getListData();
    }
    const breadcrumbItems = [
        { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
        { title: "Story Point", path: `/${props.router.organization_slug}/organization/task-Story-Point` },
    ];
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Story Point" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col md={12}>

                            <>

                                <Row>
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={(priorityList) ? priorityList : []}
                                                    isGlobalFilter={true}
                                                    isCustomPageSize={true}
                                                    isPagination={true}
                                                    SearchPlaceholder={listCount > 0 ? "Search in " + listCount + " records..." : "Search in records..."}
                                                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    isAddButton={true}
                                                    buttonName="Add Story Point"
                                                    handleClicks={handleClicks}
                                                    filterData={filterData}
                                                    getListData={getListData}
                                                    listCount={listCount}
                                                    isApiCall={isApiCall}
                                                />

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Modal isOpen={modal} toggle={toggle} centered>
                                    <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit ? "Edit Story Point" : "Add Story Point"}
                                    </ModalHeader>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <ModalBody>
                                            <Row>
                                                <Col sm={12}>
                                                <Name validation={validation} />
                                                
                                                <Label>Description</Label>
                                                <Input
                                                type="text"
                                                name="description"
                                                placeholder="Enter Description Here"
                                                value={validation.values.description}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                
                                                />
                                                {validation.errors.description ? (
                                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                ) : null}
                                                </Col>
                                                <Col className="mt-3">
                                                    <ToggleSubmitCancelButton validation={validation} cancelToggle={() => toggle()} />
                                                </Col>
                                            </Row>
                                            </ModalBody>
                                        </Form>
                                </Modal>
                            </>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withRouter(TaskStoryPoint);