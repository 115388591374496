import PropTypes from 'prop-types';
import React,{useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Progress,
  Row,
} from "reactstrap";

const CardProject = props => {
  const { singleProject , handleEditClick } = props;
  const [authData, setAuthData] = useState(JSON.parse(localStorage.getItem("projectNayaOrganization")));

  return (
    <React.Fragment>
      <Col sm={4}>
              <Card className="border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 project-card">
                <CardBody>
                  <div className="d-flex">
                    <div className="avatar-md me-4">
                      <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                          {/* avatar-md rounded-circle img-thumbnail */}
                        <img className='img img-fluid' src={singleProject.logo} alt="" height="30" />
                      </span>
                      
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                    <div className="d-flex justify-content-between">
                    <h3 className="text-truncate font-size-15">
                        <Link to={`/${authData.slug}/organization/project/view/${singleProject.id}`} className="text-dark">
                        {singleProject.name}
                        </Link>
                      </h3>
                      <Link to="#" className="text-dark" onClick={() => {
                                    handleEditClick(singleProject);
                                }}>
                      <i className="mdi mdi-pencil font-size-18 ms-3" ></i>
                      </Link>
                    </div>

                      <h3 className="text-muted font-size-15">{singleProject.short_name}</h3>
                      <div className="avatar-group mt-1">
                      {singleProject.employee.profile_image &&
                                  singleProject.employee.profile_image.map(
                                    (image, index) => {
                                      return (
                                        <div
                                          className="avatar-group-item"
                                          key={index}
                                        >
                                          <Link
                                            to="#"
                                            className="d-inline-block"
                                          >
                                            <div className="avatar-xs">
                                              <span
                                                className="avatar-title rounded-circle bg-info text-white font-size-16"
                                                style={{
                                                  backgroundImage: `url(${image})`,
                                                  backgroundSize: "cover",
                                                }}
                                              >
                                                {/* {" "} */}
                                              </span>
                                            </div>
                                          </Link>
                                        </div>
                                      )
                                    }
                                  )}
                        {/* <div className="avatar-group-item">
                          <Link to="#" className="d-inline-block">
                            <div className="avatar-xs">
                              <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                              {singleProject.employee.profile_image}
                              
                              <img src={singleProject.employee.profile_image}/>
                              </span>
                            </div>
                          </Link>
                        </div> */}
                        {/* <div className="avatar-group-item">
                          <Link to="#" className="d-inline-block">
                            <div className="avatar-xs">
                              <span className="avatar-title rounded-circle bg-danger text-white font-size-16">
                                {singleProject.employee.employee_count}+
                              </span>
                            </div>
                          </Link>
                        </div>  */}
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col sm={12} className="text-end">
                      <label htmlFor="example-search-input">
                        <span className="bg-success badge me-1">{singleProject.status}</span>{" "}
                        {singleProject.duration}h
                      </label>
                    </Col>
                    <Col sm={12}>
                      <Progress color="primary" value={(singleProject.total_time/singleProject.duration)*100}></Progress>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
    </React.Fragment>
  );
};

CardProject.propTypes = {
  singleEmployee: PropTypes.object
};

export default CardProject;
