import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { size } from "lodash";

const CardEmployee = props => {
  const { singleEmployee, handleEditClick  } = props;
  const [authData, setAuthData] = useState(JSON.parse(localStorage.getItem("projectNayaOrganization")));
  
  return (
    <React.Fragment>
      <Col xl={3} sm={6}>
        <Card className="text-center">
          <CardBody>
            {!singleEmployee.profile_image ? (
              <div className="avatar-sm mx-auto mb-4">
                <span className={"avatar-title rounded-circle bg-" + singleEmployee.color + "-subtle text-" + singleEmployee.color + " font-size-16"} >
                  {singleEmployee.name.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img className="rounded-circle avatar-sm" src={singleEmployee.profile_image_path} alt="" />
              </div>
            )}
            {/* employee view */}
            
              <Link to= {`/${authData.slug}/organization/employee/view/${singleEmployee.id}`} state={singleEmployee.id} onClick={(e)=>{
                // console.log(e,singleEmployee);
                }} className="text-dark"><h5 className="font-size-15 mb-1">{singleEmployee.name}</h5></Link>
            
            <p>{singleEmployee.email}</p>
            <p className="badge bg-primary font-size-11">{singleEmployee.designation}</p>
          
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              {/* <div className="flex-fill">
                <Link to="#" id={"message" + singleEmployee.id}>
                  <i className="bx bx-message-square-dots" />
                  <UncontrolledTooltip placement="top" target={"message" + singleEmployee.id} > Message</UncontrolledTooltip>
                </Link>
              </div> */}

                    {/* Employee Projects Button */}
              {/* <div className="flex-fill">
                <Link to="#" id={"project" + singleEmployee.id}>
                  <i className="bx bx-pie-chart-alt" />
                  <UncontrolledTooltip placement="top" target={"project" + singleEmployee.id} >Projects</UncontrolledTooltip>
                </Link>
              </div> */}
                    {/* Employee Edit Button */}
              <div className="flex-fill">
                <Link to="#" id={"edit" + singleEmployee.id} onClick={() => {
                                    handleEditClick(singleEmployee);
                                }}>
                  <i className="bx bx-edit" />
                  <UncontrolledTooltip placement="top" target={"edit" + singleEmployee.id} >Edit</UncontrolledTooltip>
                </Link>
              </div>
                    {/* Employee Profile Button */}
              <div className="flex-fill">
                <Link to= {`/${authData.slug}/organization/employee/view/${singleEmployee.id}`} id={"profile" + singleEmployee.id}>
                  <i className="bx bx-user-circle" />
                  <UncontrolledTooltip placement="top" target={"profile" + singleEmployee.id} > Profile </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardEmployee.propTypes = {
  singleEmployee: PropTypes.object
};

export default CardEmployee;
