// CustomSelect.js
import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { FormFeedback, Label } from "reactstrap"

const CustomSelect = ({
  label,
  showStrike,
  value,
  options,
  name,
  className,
  onChange,
  validation,
}) => {
  const handleSelectChange = selectedOption => {
    
    onChange(selectedOption.value,name)
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "#CED4DA",
    }),
    menu: base => ({
      ...base,
      borderRadius: 0.25,
      marginTop: 0,
      borderColor: "#CED4DA",
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      backgroundColor: "#fff",
      borderColor: "#CED4DA",
    }),
  }
  return (
    <div>
      <Label>{label}{showStrike && (
          <span style={{  color: 'red' }}>*</span>
        )}</Label>
      <Select
        // value={validation.values[name]}
        value={options.find(option => option.value === value)}
        name={name}
        className={className}
        onChange={handleSelectChange}
        styles={customStyles}
        options={options}
      >
      </Select>

      {validation.touched[name] && validation.errors[name] ? (
                <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
            ) : null}
    </div>
  )
}

CustomSelect.propTypes = {
  validation: PropTypes.object,
}

export default CustomSelect
