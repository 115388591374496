import React, { useState, useEffect, useRef } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    Label,
    Input,
    FormFeedback,
    InputGroup,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"


//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AddTeamMember } from "common/data"
import SimpleBar from "simplebar-react"
import moment from "moment"
import { ToastContainer } from "react-toastify"
import classnames from "classnames";
import Dropzone from "react-dropzone";
import Select from "react-select";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/pd_logo.png"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"
import avatar8 from "../../assets/images/users/avatar-8.jpg"

import img1 from "../../assets/images/companies/img-1.png";
import img2 from "../../assets/images/companies/img-2.png";
import img3 from "../../assets/images/companies/img-3.png";

import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";


const ActiveSprint = () => {
    document.title = "Active Sprint | Project Naya"

    const tasks = [
        {
            id: "1",
            name: "ToDo",
            cards: [
                {
                    id: "1",
                    title: "Topnav layout design",
                    date: "14 Oct, 2019",
                    badgeText: "3",
                    badgeColor: "secondary",
                    budget: "PRN-2022",
                    kanbanImgtextColor: "bg-info",
                    userImages: [{ id: 4, img: avatar4 }],
                    taskdesc: "create banbun board"
                },
                {
                    id: "2",
                    title: "Create a New landing UI",
                    date: "15 Oct, 2021",
                    badgeText: "4",
                    badgeColor: "primary",
                    taskdesc: "Separate existence is a myth.",
                    taskdesc1: "For music, sport, etc",
                    budget: "PRN-2022",
                    imageTextColor: "bg-success",
                    userImages: [{ id: 1, img: avatar1 }],
                    taskdesc: "learning react"
                },
                {
                    id: "3",
                    title: "Create a Skote Logo",
                    date: "15 Oct, 2019",
                    badgeText: "6",
                    badgeColor: "secondary",
                    budget: "PRN-2022",
                    imageTextColor: "bg-warning",
                    kanbanImgtextColor: "bg-danger",
                    userImages: [{ id: 4, img: avatar4 }],
                    taskdesc: "solved issue"
                },
            ],
        },
        {
            id: "4",
            name: "In Progress",
            cards: [
                {
                    id: "5",
                    title: "Brand logo design",
                    date: "12 Oct, 2019",
                    badgeText: "8",
                    badgeColor: "success",
                    budget: "PRN-2022",
                    userImages: [{ id: 7, img: avatar7 }],
                    brandLogo: [{ id: 1, img: img1 }],
                    taskdesc: "design logo"
                },
                {
                    id: "6",
                    title: "Create a Blog Template UI",
                    date: "13 Oct, 2019",
                    badgeText: "9",
                    badgeColor: "warning",
                    budget: "PRN-2022",
                    imageTextColor: "bg-success",
                    kanbanImgtextColor: "bg-info",
                    userImages: [{ id: 1, img: avatar1 }],
                    taskdesc: "Create a Blog "
                },
                {
                    id: "7",
                    title: "Create a Blog Template UI",
                    date: "13 Oct, 2019",
                    badgeText: "2",
                    badgeColor: "warning",
                    budget: "PRN-2022",
                    imageTextColor: "bg-success",
                    kanbanImgtextColor: "bg-primary",
                    userImages: [{ id: 1, img: avatar1 }],
                    taskdesc: "Create a Blog "
                },
            ],
        },
        {
            id: "8",
            name: "Review",
            cards: [
                {
                    id: "9",
                    title: "Redesign - Landing page",
                    date: "10 Oct, 2019",
                    badgeText: "4",
                    badgeColor: "secondary",
                    imageTextColor: "",
                    budget: "PRN-2022",
                    imageTextColor: "bg-danger",
                    userImages: [{ id: 1, img: avatar1 }],
                    taskdesc: "Redesign - Landing"
                },
                {
                    id: "10",
                    title: "Multipurpose Landing",
                    date: "09 Oct, 2019",
                    badgeText: "7",
                    badgeColor: "secondary",
                    budget: "PRN-2022",
                    kanbanImgtextColor: "bg-pink",
                    userImages: [{ id: 4, img: avatar4 }],
                    brandLogo: [{ id: 1, imges: img4 }],
                    taskdesc: "create a Multipurpose Landing"
                },
                {
                    id: "11",
                    title: "Skote landing Psd",
                    date: "15 Oct, 2019",
                    badgeText: "9",
                    badgeColor: "secondary",
                    budget: "PRN-2022",
                    imageTextColor: "bg-danger",
                    kanbanImgtextColor: "bg-info",
                    userImages: [{ id: 7, img: avatar7 }],
                    taskdesc: "testing Skote landing"
                },
            ],
        },
        {
            id: "12",
            name: "Done",
            cards: [
                {
                    id: "13",
                    title: "Redesign - Landing page",
                    date: "10 Oct, 2019",
                    badgeText: "6",
                    badgeColor: "secondary",
                    imageTextColor: "",
                    budget: "PRN-2022",
                    imageTextColor: "bg-danger",
                    userImages: [{ id: 1, img: avatar1 }],
                    taskdesc: "Redesign - Landing"
                },
                {
                    id: "14",
                    title: "Multipurpose Landing",
                    date: "09 Oct, 2019",
                    badgeText: "7",
                    badgeColor: "secondary",
                    budget: "PRN-2022",
                    kanbanImgtextColor: "bg-pink",
                    userImages: [{ id: 4, img: avatar4 }],
                    taskdesc: "create a Multipurpose Landing"
                },
                {
                    id: "15",
                    title: "Skote landing Psd",
                    date: "15 Oct, 2019",
                    badgeText: "8",
                    badgeColor: "secondary",
                    budget: "PRN-2022",
                    imageTextColor: "bg-danger",
                    kanbanImgtextColor: "bg-info",
                    userImages: [{ id: 7, img: avatar7 }],
                    taskdesc: "testing Skote landing"
                },
            ],
        },
    ]

    const AddTeamMember = [
        { id: 1, img: avatar1, name: 'Albert Rodarte' },
        { id: 2, img: avatar2, name: 'Hannah Glover' },
        { id: 3, img: avatar3, name: 'Adrian Rodarte' },
        { id: 4, img: avatar4, name: 'Frank Hamilton' },
        { id: 5, img: avatar5, name: 'Justin Howard' },
        { id: 6, img: avatar6, name: 'Michael Lawrence' },
        { id: 7, img: avatar7, name: 'Oliver Sharp' },
        { id: 8, img: avatar8, name: 'Richard Simpson' }
    ]

    const series = [
        {
            name: "Complete Tasks",
            type: "column",
            data: [23, 11, 22, 27, 13, 22, 52, 21, 44, 22, 30],
        },
        {
            name: "All Tasks",
            type: "line",
            data: [23, 11, 34, 27, 17, 22, 62, 32, 44, 22, 39],
        },
    ]

    const options = {
        chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
        stroke: { width: [0, 2, 5], curve: "smooth" },
        plotOptions: { bar: { columnWidth: "20%", endingShape: "rounded" } },
        colors: ["#556ee6", "#34c38f"],
        fill: {
            gradient: {
                inverseColors: !1,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
        ],
        markers: { size: 0 },
        yaxis: { min: 0 },
    }

    const recentTasksData = [
        {
            id: 1, taskName: "Brand logo design",
            avatars: [
                { id: 1, src: avatar4 },
                { id: 2, src: avatar5 }
            ]
        },
        {
            id: 2, taskName: "Create a Blog Template UI",
            avatars: [
                { id: 1, src: avatar1 },
                { id: 2, src: avatar2 },
                { id: 3, src: avatar3 },
                { id: 4, avatarTitle: "D", bgColor: "bg-info", text: "D" }
            ]
        },
        {
            id: 1, taskName: "Redesign - Landing page",
            avatars: [
                { id: 1, src: avatar8 },
                { id: 2, src: avatar7 },
                { id: 3, avatarTitle: "P", bgColor: "bg-danger", text: "P" }
            ]
        }
    ]

    const statusClasses = {
        waiting: "badge-soft-secondary",
        approved: "badge-soft-primary",
        complete: "badge-soft-success",
        pending: "badge-soft-warning",
    }

    const dispatch = useDispatch()
    const [images, setImages] = useState([])

    const [modal, setModal] = useState(false)
    const toggle = () => {
        if (modal) {
            setModal(false)
            setImages([])
            setCard(null)
        } else {
            setModal(true)
        }
    }

    const TasksKanbanProperties = createSelector((state) => state.tasks,
        (Tasks) => ({
            kanbanTasks: tasks,
        }))

    const { kanbanTasks } = useSelector(TasksKanbanProperties)


    const [cards, setCards] = useState()
    const [kanbanTasksCards, setKanbanTasksCards] = useState([])

    useEffect(() => {
        setCards(kanbanTasks)
    }, [kanbanTasks])

    const onClickDelete = card => {
        if (card && card.id) {
            dispatch(OnDeleteKanban(card.id))
        }
    }

    const [isEdit, setIsEdit] = useState(false)
    const [card, setCard] = useState(null)
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (card && card.cardId) || "",
            cardTitle: (card && card.cardTitle) || "",
            taskdesc: (card && card.taskdesc) || "",
            budget: (card && card.budget) || "",
            userImages: (card && card.userImages) || [],
            badgeText: (card && card.badgeText) || "",
            brandLogo: (card && card.brandLogo) || "",
            taskdesc1: (card && card.taskdesc1) || "",
        },
        validationSchema: Yup.object({
            cardTitle: Yup.string().required("Please Enter Your Job Title"),
            taskdesc: Yup.string().required("Please Enter Your Task Description"),
            budget: Yup.string().required("Please Enter Your budget"),
            badgeText: Yup.string().required("Please Enter Your Status"),
            userImages: Yup.array().required("Select at least one team member")
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updatedCards = {
                    id: card ? card.id : 0,
                    kanId: kanbanTasksCards,
                    cardId: values.id,
                    title: values.cardTitle,
                    taskdesc: values.taskdesc,
                    budget: values.budget,
                    date: moment(new Date()).format("DD MMMM , YYYY"),
                    badgeText: values.badgeText,
                    badgeColor: values.badgeColor,
                    userImages: values.userImages,
                    brandLogo: card.brandLogo,
                    taskdesc1: card.taskdesc1,
                }
                // update Job
                dispatch(onUpdateCardData(updatedCards))
                validation.resetForm()
            } else {
                const newCardData = {
                    id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    kanId: kanbanTasksCards,
                    cardId: values["id"],
                    title: values["cardTitle"],
                    taskdesc: values["taskdesc"],
                    budget: values["budget"],
                    date: moment(new Date()).format("DD MMMM , YYYY"),
                    userImages: values["userImages"],
                    badgeText: values["badgeText"],
                    badgeColor: values["badgeColor"],
                }
                dispatch(onAddCardData(newCardData))
                validation.resetForm()
            }
            toggle()
        },
    })

    const handleCardEdit = (arg, line) => {
        setModal(true)
        setCard(arg)

        let card = arg
        setCard({
            id: card.id,
            cardTitle: card.title,
            taskdesc: card.taskdesc,
            date: card.date,
            budget: card.budget,
            userImages: card.userImages,
            badgeText: card.badgeText,
            badgeColor: card.badgeColor,
            brandLogo: card.brandLogo,
            taskdesc1: card.taskdesc1,
        })

        setKanbanTasksCards(line.id)
        setIsEdit(true)

        toggle()
    }
    const handleImage = (image) => {
        const updatedImages = images.includes(image)
            ? images.filter(item => item !== image)
            : [...images, image];

        setImages(updatedImages);
        validation.setFieldValue('userImages', updatedImages)

    }

    useEffect(() => {
        if (card) {
            setImages([...card?.userImages])
        }
    }, [card])

    const handleAddNewCard = line => {
        setCard("")
        setIsEdit(false)
        toggle()
        setKanbanTasksCards(line.id)
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return // If dropped outside a valid drop area, do nothing

        const { source, destination } = result
        // Reorder cards within the same card line
        if (source.droppableId === destination.droppableId) {
            const line = cards.find(line => line.id === source.droppableId)
            const reorderedCards = Array.from(line.cards)
            const [movedCard] = reorderedCards.splice(source.index, 1)
            reorderedCards.splice(destination.index, 0, movedCard)

            const updatedLines = cards.map(line => {
                if (line.id === source.droppableId) {
                    return { ...line, cards: reorderedCards }
                }
                return line
            })

            setCards(updatedLines)
        } else {
            // Move card between different card lines
            const sourceLine = cards.find(line => line.id === source.droppableId)
            const destinationLine = cards.find(
                line => line.id === destination.droppableId
            )
            const sourceCards = Array.from(sourceLine.cards)
            const destinationCards = Array.from(destinationLine.cards)
            const [movedCard] = sourceCards.splice(source.index, 1)
            destinationCards.splice(destination.index, 0, movedCard)

            const updatedLines = cards.map(line => {
                if (line.id === source.droppableId) {
                    return { ...line, cards: sourceCards }
                } else if (line.id === destination.droppableId) {
                    return { ...line, cards: destinationCards }
                }
                return line
            })

            setCards(updatedLines)
        }
    }

    const getBadgeColor = (text) => {
        switch (text) {
            case "Waiting":
                return 'secondary';
            case "Approved":
                return 'primary';
            case "Pending":
                return 'warning';
            default:
                return 'success';
        }
    }
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };
    const [activeTab, setactiveTab] = useState("1");
    const [col5, setcol5] = useState(true);
    const [col6, setcol6] = useState(true);
    const [col7, setcol7] = useState(true);
    const [col8, setcol8] = useState(false);
    const t_col5 = () => {
        setcol5(!col5);
    };
    const t_col6 = () => {
        setcol6(!col6);
    };
    const t_col7 = () => {
        setcol7(!col7);
    };

    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }

    const flatpickrRef = useRef(null);

    const showValue = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.open();
        }
    }

    const closeValue = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.close();
        }
    }
    const optionGroup = [
        {
            label: "Picnic",
            options: [
                { label: "Mustard", value: "Mustard" },
                { label: "Ketchup", value: "Ketchup" },
                { label: "Relish", value: "Relish" }
            ]
        },
        {
            label: "Camping",
            options: [
                { label: "Tent", value: "Tent" },
                { label: "Flashlight", value: "Flashlight" },
                { label: "Toilet Paper", value: "Toilet Paper" }
            ]
        }
    ];
    const [selectedGroup, setselectedGroup] = useState(null);
    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Active Sprint" breadcrumbItem="Active Sprint" />
                    <Row>
                        <Col sm="12" className="mb-3">
                            <h3 className="mb-0 font-size-20">BSP 2022-23 Sprint 47</h3>
                        </Col>
                        <Col sm="12">
                            <Row>
                                <Col xl="2">
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            className="form-control rounded"
                                            placeholder="Search ..."
                                            aria-label="Recipient's username"
                                            onChange={(e) => hendeleSearch(e.target)}
                                        />
                                        <i className="mdi mdi-magnify font-size-16 position-absolute end-0 top-0 bottom-0 d-flex align-items-center m-auto pe-2 z-index"></i>
                                    </InputGroup>
                                </Col>
                                <Col xl="auto">
                                    <div className="avatar-group">
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-primary text-white font-size-16">K</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-danger text-white font-size-16">M</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-warning text-white font-size-16">N</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-success text-white font-size-16">A</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="avatar-group-item">
                                            <a className="d-inline-block" id="member1" href="#">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-info text-white font-size-16">+2</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Epic <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Type <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Project Name
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Project Name
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Quick Filters <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Only My Issues
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Recently Updated
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <div className="dropdown-divider"></div>
                                            <DropdownItem href="#">
                                                <div
                                                    className="form-check form-switch"

                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customSwitchsizesm"
                                                        defaultChecked
                                                        onClick={e => {
                                                            settoggleSwitchSize(!toggleSwitchSize)
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customSwitchsizesm"
                                                    >
                                                        Quick Filters
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    {(cards || []).map(line => (
                                        <Col lg={3} key={line.id}>
                                            <Card className="bg-light">
                                                <CardBody>
                                                    <UncontrolledDropdown className="float-end">
                                                        <DropdownToggle
                                                            className="arrow-none"
                                                            tag="a"
                                                            color="white"
                                                        >
                                                            <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>Edit</DropdownItem>
                                                            <DropdownItem>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <h4 className="card-title mb-4">{line.name}</h4>
                                                    <Droppable droppableId={line.id}>
                                                        {provided => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                {line.cards.map((card, index) => {
                                                                    const badgeColor = getBadgeColor(card.badgeText)
                                                                    return (
                                                                        <Draggable
                                                                            key={card.id}
                                                                            draggableId={card.id}
                                                                            index={index}
                                                                        >
                                                                            {provided => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    // className="card task-list"
                                                                                    className="pb-1 task-list"
                                                                                    id={line.name + "-task"}
                                                                                >
                                                                                    <div className="card task-box border-5 rounded-4 border-end-0 border-top-0 border-bottom-0 border-danger shadow-sm mb-2" id="uptask-1">
                                                                                        <CardBody>
                                                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                                                <h4 className="text-muted font-size-13 mb-0"
                                                                                                    id="task-budget">
                                                                                                    {card.budget}
                                                                                                </h4>
                                                                                                <span
                                                                                                    className="badge rounded-pill font-size-12 badge-soft-secondary"
                                                                                                    id="task-status">
                                                                                                    {card.badgeText}
                                                                                                </span>
                                                                                            </div>
                                                                                            
                                                                                                <Link
                                                                                                    to="#"
                                                                                                    className="text-dark"
                                                                                                    id="task-name"
                                                                                                >
                                                                                                    <h5 className="font-size-13">
                                                                                                    {card.title}
                                                                                                    </h5>
                                                                                                    
                                                                                                </Link>
                                                                                            
                                                                                            <span className="badge bg-secondary font-size-12 mt-2">Project Name</span>
                                                                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <div className="avatar-group task-assigne">
                                                                                                        {
                                                                                                            card.userImages && card.userImages.map(
                                                                                                                (usrimg, key) => (
                                                                                                                    usrimg.img &&
                                                                                                                    <div key={key}
                                                                                                                        className="avatar-group-item">
                                                                                                                        <Link
                                                                                                                            to="#"
                                                                                                                            className="d-inline-block"
                                                                                                                            defaultValue="member-4">
                                                                                                                            <img src={usrimg.img} alt="" className="rounded-circle avatar-xs" />
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            card.kanbanImgtext && card.kanbanImgtext.map((imgtext, inx) => (
                                                                                                                <div key={inx}
                                                                                                                    className="avatar-group-item">
                                                                                                                    <Link to="#" className="d-inline-block" defaultValue="member-4">
                                                                                                                        <div className="avatar-xs">
                                                                                                                            <span className={`avatar-title rounded-circle ${card.kanbanImgtextColor} text-white font-size-16`}>
                                                                                                                                {imgtext.imageText}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </Link>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }

                                                                                                    </div>
                                                                                                    <h4 className="font-size-14 mb-0 ms-1">
                                                                                                        Hitesh
                                                                                                    </h4>
                                                                                                </div>
                                                                                                <span className="bg-light badge me-1">
                                                                                                    <i className="fas fa-angle-up text-danger me-1"></i>High</span>
                                                                                            </div>

                                                                                        </CardBody>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                })}
                                                                {provided.placeholder}
                                                                {/* <div className="text-center d-grid">
                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-primary waves-effect waves-light addtask-btn"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target=".bs-example-modal-lg"
                                                                        data-id="#upcoming-task"
                                                                        onClick={() => handleAddNewCard(line)}
                                                                    >
                                                                        <i className="mdi mdi-plus me-1"></i> Add New
                                                                    </Link>
                                                                </div> */}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </DragDropContext>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal id="modalForm" isOpen={modal} toggle={toggle} centered={true} size="lg">
                <ModalHeader toggle={toggle}>
                    {!!isEdit ? "Update Task" : "Add New Task"}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >
                        <div className="form-group mb-3">
                            <Label htmlFor="taskname" className="col-form-label">
                                Task Name<span className="text-danger">*</span>
                            </Label>
                            <Col lg={12}>
                                <Input
                                    id="taskname"
                                    name="cardTitle"
                                    type="text"
                                    className="form-control validate"
                                    placeholder="Enter Task Name..."
                                    validate={{ required: { value: true } }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.cardTitle || ""}
                                    invalid={
                                        validation.touched.cardTitle && validation.errors.cardTitle
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.cardTitle && validation.errors.cardTitle ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.cardTitle}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </div>
                        <div className="form-group mb-3">
                            <label className="col-form-label">Task Description</label>
                            <Col lg={12}>
                                <textarea
                                    id="taskdesc"
                                    className="form-control"
                                    placeholder="Enter Task Description"
                                    name="taskdesc"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.taskdesc || ""}
                                ></textarea>
                                {validation.touched.taskdesc &&
                                    validation.errors.taskdesc ? (
                                    <FormFeedback type="invalid" className="d-block">
                                        {validation.errors.taskdesc}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </div>

                        <div className="form-group mb-3">
                            <label className="col-form-label">
                                Add Team Member<span className="text-danger">*</span>
                            </label>
                            <SimpleBar style={{ height: "200px" }}>
                                <ul
                                    className="list-unstyled user-list validate"
                                    id="taskassignee"
                                >
                                    {(AddTeamMember || []).map((image, index) => {
                                        const isChecked = images.some(item => item.id === image.id);
                                        return (
                                            <li key={index}>
                                                <div className="form-check form-check-primary mb-2 d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={"member" + image.id}
                                                        name="userImages"
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.userImages || ''}
                                                        onChange={() => handleImage(image)}
                                                        checked={isChecked}
                                                    />
                                                    <label className="form-check-label ms-2" htmlFor={"member" + image.id}>
                                                        {image.name}
                                                    </label>
                                                    <img
                                                        src={image.img}
                                                        className="rounded-circle avatar-xs m-1"
                                                        alt=""
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })}
                                    {validation.touched.userImages &&
                                        validation.errors.userImages ? (
                                        <FormFeedback type="invalid" className="d-block">
                                            {validation.errors.userImages}
                                        </FormFeedback>
                                    ) : null}
                                </ul>
                            </SimpleBar>
                        </div>

                        <div className="form-group mb-4">
                            <label className="col-form-label">
                                Status<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-12">
                                <select
                                    className="form-select validate"
                                    id="TaskStatus"
                                    name="badgeText"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.badgeText}
                                >
                                    <option defaultValue="">Choose..</option>
                                    <option defaultValue="secondary">Waiting</option>
                                    <option defaultValue="primary">Approved</option>
                                    <option defaultValue="warning">Pending</option>
                                    <option defaultValue="success">Complete</option>
                                </select>
                                {validation.touched.badgeText &&
                                    validation.errors.badgeText ? (
                                    <FormFeedback type="invalid" className="d-block">
                                        {validation.errors.badgeText}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="taskbudget" className="col-form-label">
                                Budget<span className="text-danger">*</span>
                            </label>
                            <Col lg={12}>
                                <input
                                    id="taskbudget"
                                    name="budget"
                                    type="number"
                                    placeholder="Enter Task Budget..."
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.budget || ""}
                                />
                                {validation.touched.budget && validation.errors.budget ? (
                                    <FormFeedback type="invalid" className="d-block">
                                        {validation.errors.budget}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </div>
                        <Row>
                            <Col lg={10}>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="updatetaskdetail"
                                >
                                    {!!isEdit ? "Update Task" : "Create Task"}
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            {/* setting edit modal */}
            <Modal centered={true}
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Edit
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Name</Label>
                        <Input
                            type="text"
                            name="firstname"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Name"
                        />

                    </div>
                    <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Key</Label>
                        <Input
                            type="text"
                            name="firstname"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Key"
                        />
                    </div>
                    <Form>
                        <Dropzone
                            onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{
                                    minHeight: 70,
                                }}>
                                    <div
                                        className="dz-message needsclick p-2"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="mb-1">
                                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary "
                    >
                        Save
                    </button>
                </div>
            </Modal>
            <ToastContainer />
        </React.Fragment>
    )
}

export default ActiveSprint;