import PropTypes from 'prop-types';
import React from "react";
const LoginButton = ({ buttonName }) => {

    return (
        <div className="d-grid">
            <button
                className="btn btn-primary btn-block "
                type="submit"
    
            >
                {buttonName}
            </button>
        </div>

    )
}

LoginButton.propTypes = {
    buttonName: PropTypes.string,
}

export default LoginButton
