import React, { useRef, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Paginations from "components/Common/Pagination";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import task from "../../assets/images/Task.png";
import avatar1 from "../../assets/images/users/avatar-1.jpg"


import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    Modal,
    Collapse,
    InputGroup,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table

} from "reactstrap";

const Backlog = props => {
    document.title = "Backlog | Project Naya"
    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const perPageData = 6;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
    const currentdata = useMemo(() => (indexOfFirst, indexOfLast), [indexOfFirst, indexOfLast])
    const [modal_standard, setmodal_standard] = useState(false);
    const [col5, setcol5] = useState(true);
    const [toggleSwitchSize, settoggleSwitchSize] = useState(true);

    const breadcrumbItem = [
        { title: "Dashboard", link: "dashboard" },
        { title: "Backlog", link: "#" }
    ];

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    const t_col5 = () => {
        setcol5(!col5);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Backlog" breadcrumbItems={breadcrumbItem} />
                    <Row className="mt-3">
                        <Col sm="12">
                            <Row className="mb-4">
                                <Col xl="2">
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            className="form-control rounded"
                                            placeholder="Search ..."
                                            aria-label="Recipient's username"
                                            onChange={(e) => hendeleSearch(e.target)}
                                        />
                                        <i className="mdi mdi-magnify font-size-16 position-absolute end-0 top-0 bottom-0 d-flex align-items-center m-auto pe-2 z-index"></i>
                                    </InputGroup>
                                </Col>
                                <Col xl="auto">
                                    <div className="avatar-group">
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-primary text-white font-size-16">K</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-danger text-white font-size-16">M</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-warning text-white font-size-16">N</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-success text-white font-size-16">P</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="avatar-group-item">
                                            <Link to="#" className="d-inline-block">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-info text-white font-size-16">+2</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="auto">
                                    <div className="avatar-group-item">
                                        <Link to="#" className="d-inline-block">
                                            <div className="avatar-xs">
                                                <span className="avatar-title rounded-circle  bg-light text-muted font-size-16">
                                                    <i className="fas fa-user-plus"></i>

                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            version <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Issue Without Version
                                                        </label>

                                                    </div>
                                                </div>
                                            </DropdownItem>

                                            <div className="dropdown-divider"></div>
                                            <DropdownItem href="#">
                                                <div
                                                    className="form-check form-switch"

                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customSwitchsizesm"
                                                        defaultChecked
                                                        onClick={e => {
                                                            settoggleSwitchSize(!toggleSwitchSize)
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customSwitchsizesm"
                                                    >
                                                        Quick Filters
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Epic <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <div className="ms-3">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            Project Name
                                                        </label>
                                                        <p className="form-check-label">BSP-22</p>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Type <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Project Name
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Project Name
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                <Col xl="auto">
                                    <UncontrolledDropdown>
                                        <DropdownToggle type="button" className="btn btn-light">
                                            Quick Filters <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Only My Issues
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="defaultCheck1"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        Recently Updated
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                            <div className="dropdown-divider"></div>
                                            <DropdownItem href="#">
                                                <div
                                                    className="form-check form-switch"

                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customSwitchsizesm"
                                                        defaultChecked
                                                        onClick={e => {
                                                            settoggleSwitchSize(!toggleSwitchSize)
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customSwitchsizesm"
                                                    >
                                                        Quick Filters
                                                    </label>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="12">
                            <div className="bg-light p-3 rounded-4">
                                <div onClick={t_col5} className="px-2" style={{ cursor: "pointer" }}>
                                    <Row>
                                        <Col>
                                            <div className="d-flex align-items-center gap-2">
                                                <h4 className="font-size-15 mb-0">BSP 2023-24</h4>
                                                <h5 className="font-size-15 mb-0">Sprint 45</h5>
                                                <h6 className="text-muted font-size-14 mb-0">19 Jan - 20Jan</h6>
                                                <h6 className="text-muted font-size-14 mb-0">issue
                                                    <span className="badge rounded-pill font-size-12 badge-soft-secondary ms-1">3</span></h6>
                                            </div>
                                        </Col>
                                        <Col xs="auto">
                                            <span className="badge rounded-pill font-size-12 badge-soft-secondary me-1">9</span>
                                            <span className="badge rounded-pill font-size-12 badge-soft-info me-1">4</span>
                                            <span className="badge rounded-pill font-size-12 badge-soft-success">7</span>
                                        </Col>
                                    </Row>
                                </div>
                                <Collapse isOpen={col5}>
                                    <Row className="mt-2">
                                        <Col>
                                            <div className="d-flex align-items-center gap-2 px-2">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-primary text-white">H</span>
                                                </div>
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle  bg-success text-white">P</span>
                                                </div>
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-warning text-white">K</span>
                                                </div>
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-info text-white">V</span>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" onClick={() => {
                                                        tog_standard();
                                                    }}
                                                        data-toggle="modal"
                                                        data-target="#myModal">
                                                        <span className="avatar-title rounded-2 bg-light text-dark">
                                                            <i className="mdi mdi-dots-horizontal font-size-20 text-muted"></i>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card className="border mb-0 mt-3">
                                        <CardBody>
                                            <div className="table-responsive">
                                                <Table className="table table-hover mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Link to="#">
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <h4 className="text-muted font-size-14 mb-0" >
                                                                                    <img src={task} alt="" className="me-1" style={{ width: 15 }} />BSP-2022
                                                                                </h4>

                                                                                <Link to="#" className="text-dark" href="#">
                                                                                    <h5 className="font-size-14 mb-0">
                                                                                        Topnav layout design
                                                                                    </h5>
                                                                                </Link>

                                                                                <i className="mdi mdi-pencil font-size-16 text-muted"></i>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl="auto">
                                                                            <Row className="align-items-center">
                                                                                <Col xl="auto">
                                                                                    <span className=" bg-secondary badge bg-secondary">Project Name</span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span className="bg-light badge me-1">
                                                                                        Open <i className="fas fa-angle-down ms-1">
                                                                                        </i></span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span
                                                                                        className="badge rounded-pill font-size-12 badge-soft-secondary"
                                                                                    >
                                                                                        3
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span className="bg-light badge me-1">
                                                                                        <i className="fas fa-angle-up text-danger me-1"></i>
                                                                                        High
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <div className="avatar-xs">
                                                                                        <span className="avatar-title rounded-circle  bg-primary text-white font-size-14">H</span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Link to="#">
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="d-flex align-items-center gap-2">
                                                                                <h4 className="text-muted font-size-14 mb-0" >
                                                                                    <img src={task} alt="" className="me-1" style={{ width: 15 }} />BSP-2023
                                                                                </h4>
                                                                                <Link to="#" className="text-dark" href="#">
                                                                                    <h5 className="font-size-14 mb-0">
                                                                                        Topnav layout design
                                                                                    </h5>
                                                                                </Link>
                                                                                <i className="mdi mdi-pencil font-size-16 text-muted"></i>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl="auto">
                                                                            <Row className="align-items-center">
                                                                                <Col xl="auto">
                                                                                    <span className=" bg-secondary badge bg-secondary">Project Name</span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span className="bg-light badge me-1">
                                                                                        Open <i className="fas fa-angle-down ms-1">
                                                                                        </i></span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span
                                                                                        className="badge rounded-pill font-size-12 badge-soft-secondary"
                                                                                    >
                                                                                        3
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span className="bg-light badge me-1">
                                                                                        <i className="fas fa-angle-up text-danger me-1"></i>
                                                                                        High
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <div className="avatar-xs">
                                                                                        <span className="avatar-title rounded-circle bg-warning text-white font-size-14">K</span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Link to="#">
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="d-flex align-items-center gap-2">
                                                                                <h4 className="text-muted font-size-14 mb-0" >
                                                                                    <img src={task} alt="" className="me-1" style={{ width: 15 }} />BSP-2024
                                                                                </h4>
                                                                                <Link to="#" className="text-dark" href="#">
                                                                                    <h5 className="font-size-14 mb-0">
                                                                                        Topnav layout design
                                                                                    </h5>
                                                                                </Link>
                                                                                <i className="mdi mdi-pencil font-size-16 text-muted"></i>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl="auto">
                                                                            <Row className="align-items-center">
                                                                                <Col xl="auto">
                                                                                    <span className=" bg-secondary badge bg-secondary">Project Name</span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span className="bg-light badge me-1">
                                                                                        Open <i className="fas fa-angle-down ms-1">
                                                                                        </i></span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span
                                                                                        className="badge rounded-pill font-size-12 badge-soft-secondary"
                                                                                    >
                                                                                        3
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <span className="bg-light badge me-1">
                                                                                        <i className="fas fa-angle-up text-danger me-1"></i>
                                                                                        High
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xl="auto">
                                                                                    <div className="avatar-xs">
                                                                                        <span className="avatar-title rounded-circle  bg-danger text-white font-size-14">M</span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*create Modal */}
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Workload by assignee - BSP 2022-23 Sprint 47
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <div
                                className="form-check form-switch"

                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizesm"
                                    defaultChecked
                                    onClick={e => {
                                        settoggleSwitchSize(!toggleSwitchSize)
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizesm"
                                >
                                    Include subtasks in estimates
                                </label>
                            </div>
                        </Col>
                        <Col sm="12" className="mt-3">
                            <div className="table-responsive">
                                <Table className="table table-borderless mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Assignee</th>
                                            <th>Issues</th>
                                            <th>Story points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-xs">
                                                        <img src={avatar1} alt="" className="rounded-circle avatar-xs" />
                                                    </div>
                                                    <h5 className="font-size-15 mb-0 ms-2">Hitesh</h5>
                                                </div>
                                            </td>
                                            <td>30</td>
                                            <td>
                                                <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                                                    3
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-success text-white">P</span>
                                                    </div>
                                                    <h5 className="font-size-15 mb-0 ms-2">Pankaj</h5>
                                                </div>
                                            </td>
                                            <td>40</td>
                                            <td>
                                                <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                                                    3
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-warning text-white">K</span>
                                                    </div>
                                                    <h5 className="font-size-15 mb-0 ms-2">Kartik</h5>
                                                </div>
                                            </td>
                                            <td>50</td>
                                            <td>
                                                <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                                                    3
                                                </span>
                                            </td>
                                        </tr>
                                        <tr className="border-top border-2 border-start-0 border-end-0 border-bottom-0">
                                            <th>Total:</th>
                                            <td>120</td>
                                            <td>
                                                <span className="badge rounded-pill font-size-12 badge-soft-secondary">
                                                    9
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-danger "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </Modal>

            <ToastContainer />

        </React.Fragment>
    );
}
export default withRouter(Backlog);