import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  InputGroup,
  Button,
  Label,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import { getList as onGetList, clearData } from "store/AppMaster/actions"
import { isEmpty } from "lodash"
import api from "../../constants/api"
import withRouter from "components/Common/withRouter"
import moment from "moment"

const TimeLogTab = props => {
  document.title = "Project view | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()

  const { data, loading } = useSelector(state => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }))
  const [isApiCall, setIsApiCall] = useState(0)
  const [employee, setEmployee] = useState(null)
  const [timeLog, setTimeLog] = useState(null)
  const [timeLogExport, setTimeLogExport] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const [updateTimeLogList, setUpdateTimeLogList] = useState(false)
  const [updateTimeLogExportList, setUpdateTimeLogExportList] = useState(false)
  const [selectedGroup, setselectedGroup] = useState([])
  const [selectedValue, setSelectedValue] = useState("")
  const [selectedExportValue, setSelectedExportValue] = useState("")
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [filterData, setFilterData] = useState({
    project_id: params.id,
    from_date: '' ,
    to_date: '',
    employee_id: selectedValue
  })
  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.file) && updateTimeLogExportList) {
      setTimeLogExport(data.file)
    }
  }, [data, updateTimeLogExportList])
  
  const handleDropdownSelect = value => {
    setUpdateTimeLogExportList(true)
    dispatch(clearData())
    let filterDataForExport = {
      project_id: params.id,
      from_date: filterData.from_date,
      to_date: filterData.to_date,
      employee_id: selectedValue,
      type: value,
    }
    dispatch(
      onGetList(filterDataForExport, props.router.navigate, api.ProjectTimeLogListExport)
    )
    if (!isEmpty(data) && !isEmpty(data.file) && updateTimeLogExportList) {
      setTimeLogExport(data.file)
      setUpdateTimeLogExportList(false)
      downloadFile(data.file)
    }
  }

  const downloadFile = (fileUrl) => {
    // Create a temporary link with file URL and click it
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "exported_file"; // You can provide a custom file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isApiCall === 0) {
      getListData()
      getTimeLogListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
    }
    dispatch(onGetList(filterData, props.router.navigate, api.TaskMetaData))
  }

  if (!isEmpty(data) && !isEmpty(data.employee) && updateListData) {
    setEmployee(data.employee)
    setUpdateListData(false)
    data.employee.map((item, index) => {
      selectedGroup.push({ label: item.name, value: item.id })
    })
  }

  const ExportOption = [
    {
      options: [
        { label: "Excel", value: "excel" },
        { label: "CSV", value: "csv" },
      ],
    },
  ]

  function handleSelectGroup(selectedGroup) {
    setSelectedValue(selectedGroup)
    filterData.employee_id = selectedGroup.value
  }

  const getTimeLogListData = () => {
    setTimeLog(null)
    setUpdateTimeLogList(true)
    dispatch(clearData());
    filterData.from_date = filterData.from_date ? moment(filterData.from_date).format('DD-MM-YYYY') : '';
    filterData.to_date = filterData.to_date ? moment(filterData.to_date).format('DD-MM-YYYY') : '';
    dispatch(
      onGetList(filterData, props.router.navigate, api.ProjectTimeLogList)
    )
  }

  if (!isEmpty(data) && !isEmpty(data.employeeTimeLog) && updateTimeLogList) {
    setTimeLog(data.employeeTimeLog)
    setUpdateTimeLogList(false)
  }

  return (
    <div>
      <Row>
        <Col sm="12">
          <div className="d-flex align-items-center justify-content-end">
            <UncontrolledDropdown>
              <DropdownToggle type="button" className="btn btn-light">
                <i className="fas fa-download me-1"></i> Export{" "}
                <i className="mdi mdi-chevron-down ms-3"></i>
              </DropdownToggle>
              <DropdownMenu>
                <div className="dropdown-divider my-0"></div>

                {/* Mapping over ExportOption to generate DropdownItems */}
                {ExportOption.map((optionGroup, index) => (
                  <React.Fragment key={index}>
                    {optionGroup.options.map((option, idx) => (
                      <DropdownItem
                        key={idx}
                        onClick={() => handleDropdownSelect(option.value)}
                      >
                        <h5 className="mb-0">
                          <i className="mdi mdi-cloud-download me-2"></i>
                          {option.label}
                        </h5>
                      </DropdownItem>
                    ))}
                    {index !== ExportOption.length - 1 && (
                      <div className="dropdown-divider my-0"></div>
                    )}
                  </React.Fragment>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
        <Col sm="12 mt-3">
          <Card className="rounded-4 mb-0">
            <CardBody>
              <Row className="align-items-center">
                <Col sm="3">
                  <Label>From Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd-mm-YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "d-m-Y",
                      }}
                      name="from_date"
                      onChange={date => {
                        console.log("Date", date)
                        setFromDate(date[0])
                        filterData.from_date = date[0];
                      }}
                      value={fromDate !== null ? fromDate : ""}
                    />
                  </InputGroup>
                </Col>
                <Col sm="3">
                  <Label>To Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd-mm-YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "d-m-Y",
                      }}
                      value={toDate !== null ? toDate : ""}
                      onChange={date => {
                        if (date && date[0]) {
                          setToDate(date[0])
                          filterData.to_date = date[0];
                        }
                      }}
                      name="to_date"
                      id="to_date"
                    />
                  </InputGroup>
                </Col>
                <Col sm="3">
                  <Label>People</Label>
                  <Select
                    value={selectedValue !== null ? selectedValue : ""}
                    onChange={handleSelectGroup}
                    options={selectedGroup}
                    className="select2-selection"
                  />
                </Col>
                <Col sm="3" className="text-end mt-md-4">
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    onClick={getTimeLogListData}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {timeLog
          ? timeLog.map((time, index) => 
            
            {
              // console.log(time);
              return (
              <Col key={index} sm="12" className="mt-3">
                <Card className="rounded-4 mb-2">
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="avatar-md me-4">
                          <img
                            src={time.profile_image}
                            alt=""
                            className="rounded-circle avatar-md"
                          />
                        </div>
                        <div>
                          <h4 className="mb-0">{time.name}</h4>
                          <h5 className="text-muted font-size-15 mb-0 mt-1">
                            {time.department}
                          </h5>
                        </div>
                      </div>
                      <span className="text-muted fs-1 fw-bold">
                        {time.total_time.slice(0, -1)}
                        <small className="font-size-12">hrs</small>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )})
          : ""}
      </Row>
    </div>
  )
}

export default withRouter(TimeLogTab)
