import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Label,
  Input,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useParams, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AddTeamMember } from "common/data"
import SimpleBar from "simplebar-react"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import Select from "react-select"

import BoardTab from "./BoardTab"
import SprintTab from "./SprintTab"
import TimeLogTab from "./TimeLogTab"
import TaskTab from "./TaskTab"
import SettingTab from "./SettingTab"

const TasksKanban = props => {
  document.title = "Project view | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate();

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))

  const [images, setImages] = useState([])

  const [modal, setModal] = useState(false)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setImages([])
      setCard(null)
    } else {
      setModal(true)
    }
  }


  const [isEdit, setIsEdit] = useState(false)
  const [card, setCard] = useState(null)
  // validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (card && card.cardId) || "",
      cardTitle: (card && card.cardTitle) || "",
      taskdesc: (card && card.taskdesc) || "",
      budget: (card && card.budget) || "",
      userImages: (card && card.userImages) || [],
      badgeText: (card && card.badgeText) || "",
      brandLogo: (card && card.brandLogo) || "",
      taskdesc1: (card && card.taskdesc1) || "",
    },
    validationSchema: Yup.object({
      cardTitle: Yup.string().required("Please Enter Your Job Title"),
      taskdesc: Yup.string().required("Please Enter Your Task Description"),
      budget: Yup.string().required("Please Enter Your budget"),
      badgeText: Yup.string().required("Please Enter Your Status"),
      userImages: Yup.array().required("Select at least one team member"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedCards = {
          id: card ? card.id : 0,
          kanId: kanbanTasksCards,
          cardId: values.id,
          title: values.cardTitle,
          taskdesc: values.taskdesc,
          budget: values.budget,
          date: moment(new Date()).format("DD MMMM , YYYY"),
          badgeText: values.badgeText,
          badgeColor: values.badgeColor,
          userImages: values.userImages,
          brandLogo: card.brandLogo,
          taskdesc1: card.taskdesc1,
        }
        // update Job
        dispatch(onUpdateCardData(updatedCards))
        validation.resetForm()
      } else {
        const newCardData = {
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          kanId: kanbanTasksCards,
          cardId: values["id"],
          title: values["cardTitle"],
          taskdesc: values["taskdesc"],
          budget: values["budget"],
          date: moment(new Date()).format("DD MMMM , YYYY"),
          userImages: values["userImages"],
          badgeText: values["badgeText"],
          badgeColor: values["badgeColor"],
        }
        dispatch(onAddCardData(newCardData))
        validation.resetForm()
      }
      toggle()
    },
  })

  const handleImage = image => {
    const updatedImages = images.includes(image)
      ? images.filter(item => item !== image)
      : [...images, image]

    setImages(updatedImages)
    validation.setFieldValue("userImages", updatedImages)
  }

  useEffect(() => {
    if (card) {
      setImages([...card?.userImages])
    }
  }, [card])


  const handleDragEnd = result => {
    if (!result.destination) return // If dropped outside a valid drop area, do nothing

    const { source, destination } = result
    // Reorder cards within the same card line
    if (source.droppableId === destination.droppableId) {
      const line = cards.find(line => line.id === source.droppableId)
      const reorderedCards = Array.from(line.cards)
      const [movedCard] = reorderedCards.splice(source.index, 1)
      reorderedCards.splice(destination.index, 0, movedCard)
      
      const updatedLines = cards.map(line => {
        if (line.id === source.droppableId) {
          return { ...line, cards: reorderedCards }
        }
        return line
      })

      setCards(updatedLines)
    } else {
      // Move card between different card lines
      const sourceLine = cards.find(line => line.id === source.droppableId)
      const destinationLine = cards.find(
        line => line.id === destination.droppableId
      )
      const sourceCards = Array.from(sourceLine.cards)
      const destinationCards = Array.from(destinationLine.cards)
      const [movedCard] = sourceCards.splice(source.index, 1)
      destinationCards.splice(destination.index, 0, movedCard)

      const updatedLines = cards.map(line => {
        if (line.id === source.droppableId) {
          return { ...line, cards: sourceCards }
        } else if (line.id === destination.droppableId) {
          return { ...line, cards: destinationCards }
        }
        return line
      })

      setCards(updatedLines)
    }
  }

  const getBadgeColor = text => {
    switch (text) {
      case "Waiting":
        return "secondary"
      case "Approved":
        return "primary" 
      case "Pending":
        return "warning"
      default:
        return "success"
    }
  }
  const [customActiveTabProject, setcustomActiveTabProject] = useState("1")
  useEffect(() => {
    // Get the active tab from local storage when component mounts
    const storedActiveTab = localStorage.getItem('customActiveTabProject');
    if (storedActiveTab) {
      setcustomActiveTabProject(storedActiveTab);
    }
  }, []);
  const toggleCustom = tab => {
    if (customActiveTabProject !== tab) {
      setcustomActiveTabProject(tab)
      localStorage.setItem('customActiveTabProject', tab);
    }
  }



  return (
    <React.Fragment>
      <div className="page-content" style={customActiveTabProject === "3" ? { height: '1000px' } : {}}>
        <Container fluid>
          <Breadcrumbs title="Project" breadcrumbItem="Project View" />
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTabProject === "1" })}
                onClick={() => {
                  toggleCustom("1")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Board</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTabProject === "2" })}
                onClick={() => {
                  toggleCustom("2")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Sprint</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTabProject === "3" })}
                onClick={() => {
                  toggleCustom("3")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope"></i>
                </span>
                <span className="d-none d-sm-block">Time Log</span>
              </NavLink>
            </NavItem>
            {/* Dashboard Tab */}
            {/* <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTabProject === "4" })}
                onClick={() => {
                  toggleCustom("4")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="d-none d-sm-block">Dashboard</span>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTabProject === "5" })}
                onClick={() => {
                  toggleCustom("5")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="d-none d-sm-block">Task</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTabProject === "6" })}
                onClick={() => {
                  toggleCustom("6")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="d-none d-sm-block">Settings</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTabProject} className="p-3 text-muted">
            <TabPane tabId="1">
              <BoardTab />
            </TabPane>
            <TabPane tabId="2">
              <SprintTab />
            </TabPane>
            <TabPane tabId="3">
              <TimeLogTab />
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12"></Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <TaskTab />
            </TabPane>
            <TabPane tabId="6">
              <SettingTab />
            </TabPane>
          </TabContent>
        </Container>
      </div>

      <Modal
        id="modalForm"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Update Task" : "Add New Task"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="form-group mb-3">
              <Label htmlFor="taskname" className="col-form-label">
                Task Name<span className="text-danger">*</span>
              </Label>
              <Col lg={12}>
                <Input
                  id="taskname"
                  name="cardTitle"
                  type="text"
                  className="form-control validate"
                  placeholder="Enter Task Name..."
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.cardTitle || ""}
                  invalid={
                    validation.touched.cardTitle && validation.errors.cardTitle
                      ? true
                      : false
                  }
                />
                {validation.touched.cardTitle && validation.errors.cardTitle ? (
                  <FormFeedback type="invalid">
                    {validation.errors.cardTitle}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>
            <div className="form-group mb-3">
              <label className="col-form-label">Task Description</label>
              <Col lg={12}>
                <textarea
                  id="taskdesc"
                  className="form-control"
                  placeholder="Enter Task Description"
                  name="taskdesc"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.taskdesc || ""}
                ></textarea>
                {validation.touched.taskdesc && validation.errors.taskdesc ? (
                  <FormFeedback type="invalid" className="d-block">
                    {validation.errors.taskdesc}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>

            <div className="form-group mb-3">
              <label className="col-form-label">
                Add Team Member<span className="text-danger">*</span>
              </label>
              <SimpleBar style={{ height: "200px" }}>
                <ul
                  className="list-unstyled user-list validate"
                  id="taskassignee"
                >
                  {(AddTeamMember || []).map((image, index) => {
                    const isChecked = images.some(item => item.id === image.id)
                    return (
                      <li key={index}>
                        <div className="form-check form-check-primary mb-2 d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"member" + image.id}
                            name="userImages"
                            onBlur={validation.handleBlur}
                            value={validation.values.userImages || ""}
                            onChange={() => handleImage(image)}
                            checked={isChecked}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor={"member" + image.id}
                          >
                            {image.name}
                          </label>
                          <img
                            src={image.img}
                            className="rounded-circle avatar-xs m-1"
                            alt=""
                          />
                        </div>
                      </li>
                    )
                  })}
                  {validation.touched.userImages &&
                    validation.errors.userImages ? (
                    <FormFeedback type="invalid" className="d-block">
                      {validation.errors.userImages}
                    </FormFeedback>
                  ) : null}
                </ul>
              </SimpleBar>
            </div>

            <div className="form-group mb-4">
              <label className="col-form-label">
                Status<span className="text-danger">*</span>
              </label>
              <div className="col-lg-12">
                <select
                  className="form-select validate"
                  id="TaskStatus"
                  name="badgeText"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.badgeText}
                >
                  <option defaultValue="">Choose..</option>
                  <option defaultValue="secondary">Waiting</option>
                  <option defaultValue="primary">Approved</option>
                  <option defaultValue="warning">Pending</option>
                  <option defaultValue="success">Complete</option>
                </select>
                {validation.touched.badgeText && validation.errors.badgeText ? (
                  <FormFeedback type="invalid" className="d-block">
                    {validation.errors.badgeText}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="taskbudget" className="col-form-label">
                Budget<span className="text-danger">*</span>
              </label>
              <Col lg={12}>
                <input
                  id="taskbudget"
                  name="budget"
                  type="number"
                  placeholder="Enter Task Budget..."
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.budget || ""}
                />
                {validation.touched.budget && validation.errors.budget ? (
                  <FormFeedback type="invalid" className="d-block">
                    {validation.errors.budget}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>
            <Row>
              <Col lg={10}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="updatetaskdetail"
                >
                  {!!isEdit ? "Update Task" : "Create Task"}
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
}

export default TasksKanban