import React, { useRef, useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "components/Common/Breadcrumb2"
import Paginations from "components/Common/Pagination"
import { useFormik } from "formik"
import * as Yup from "yup"
import { SketchPicker } from "react-color"
import { isEmpty } from "lodash"
import api from "../../constants/api"
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData,
} from "store/AppMaster/actions"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Modal,
  Button,
  Label,
  Input,
  InputGroup,
  AccordionBody,
  FormFeedback,
  Form,
} from "reactstrap"
import CardColumn from "./CardList"
import messageConstant from "constants/message"
import { ColorPicker } from "@vtaits/react-color-picker"
import LoginButton from "components/Common/PageComponent/LoginButton"
import AnotherButton from "components/Common/PageComponent/AnotherButton"
import ColumnBackGroundColor from "components/Common/PageComponent/ColumnBackGroundColor"

const BoardColumn = props => {
  document.title = "Board Column | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { data, loading } = useSelector(state => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }))
  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const perPageData = 6
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData
  const currentdata = useMemo(
    () => (indexOfFirst, indexOfLast),
    [indexOfFirst, indexOfLast]
  )
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_standard2, setmodal_standard2] = useState(false)

  const [isApiCall, setIsApiCall] = useState(0)
  const [columnList, setList] = useState([])
  const [column, setColumnList] = useState({column_name : "", column_color : "" , id : "", column_order :""})
  const [statusListData, setStatusListData] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [addFlag, setAddFlag] = useState(false)
  const [editFlag, setEditFlag] = useState(false)

  useEffect(() => {
    if (isApiCall === 0) {
      getBoardListData()
      setIsApiCall(1)
    }
  }, [isApiCall, columnList])

  const getBoardListData = () => {
    setStatusListData(true)
    dispatch(clearData())
    let filterData = {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      limit : 100
    }
    dispatch(onGetList(filterData, props.router.navigate, api.BoardColumnList))
  }

  if (!isEmpty(data) && !isEmpty(data.column) && statusListData) {
    setList(data.column)
    setStatusListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
    { title: "Board Column", link: "#" },
  ]
  const [colorHor, setcolorHor] = useState("#fffff")
  const [simple_color2, setsimple_color2] = useState(0)
  const handleHor = color => {
    setcolorHor(color.hex)
  }

  const handleClick = () => {
    setModal(!modal)
    setIsEdit(false)
    setColumnList(" ")
  }
  const toggle = () => {
    setModal(!modal)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      organization_id: localStorage.getItem("projectNayaOrganization")
        ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
        : 0,
      column_name: (column && column.column_name) || "",
      column_background_color: (column && column.column_background_color) || "",
    },
    validationSchema: Yup.object({
      column_name: Yup.string()
        .required(messageConstant.COLUMN_NAME_REQUIRED)
        .max(250, "Must be less than  250 characters"),
      column_background_color: Yup.string()
        .required(messageConstant.BACKGROUND_COLOR_REQUIRED)
        .max(250, messageConstant.BACKGROUND_COLOR_INVALID),
    }),
    onSubmit: values => {
      if (isEdit) {
        setEditFlag(true)
        const updateData = {
          id: column.id,
          column_name: values["column_name"],
          column_background_color: values["column_background_color"],
        }
        dispatch(clearData())
        dispatch(onUpdateData(updateData, props.router.navigate, api.BoardColumnEdit))
      } else {
        // alert("Hi")
        setAddFlag(true)
        const newData = {
          organization_id: localStorage.getItem("projectNayaOrganization")
            ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
            : 0,
          column_name: values["column_name"],
          column_background_color: values["column_background_color"],
        }
        dispatch(clearData())
        dispatch(onAddNewData(newData, props.router.navigate, api.BoardColumnAdd))
      }
    },
  })

  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false
  }

  // column Add
  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.task_board_column) && addFlag) {
      setAddFlag(false)
      validation.resetForm()
      toggle()
      dispatch(clearData())
      getBoardListData()
    }
  }, [data, addFlag])

  // column Edit
  if (!isEmpty(data) && !isEmpty(data.task_board_column) && editFlag) {
    setEditFlag(false)
    validation.resetForm()
    setIsEdit(false)
    toggle()
    dispatch(clearData())
    getBoardListData()
  }

  const handleEditClick = arg => {
    const Column = arg
    // console.log("Column",Column);
    setColumnList((prev) => ({
        ...prev,
        id: Column.id,
        column_name: Column.column_name,
        column_background_color: Column.column_color,
      }));
    setIsEdit(true)
    toggle()
  }
  // const handleBackgroundColor = color => {
  //   validation.setFieldValue("column_background_color", color.hex)
  // }

  const handleColumnNameChange = e => {
    validation.setFieldValue("column_name", e.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Board Column" breadcrumbItems={breadcrumbItem} />
          <div className="text-end">
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                toggle()
              }}
              data-toggle="modal"
              data-target="#myModal"
              className="btn"
            >
              <i className="mdi mdi-plus me-1"></i>
              Add Board Column
            </Button>
          </div>
          {!isEmpty(columnList) &&
            columnList.map((column, key) => (
              <CardColumn
                column={column}
                key={"_column_" + key}
                handleEditClick={handleEditClick}
              />
            ))}
        </Container>
      </div>
      {/*create Modal */}
      <Modal
        isOpen={modal}
        toggle={() => {
          handleClick()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {!!isEdit ? "Edit Column" : "Add Column"}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

            <Form
            onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
                }}>
        <div className="modal-body">
          <Row>
            <Col sm={12} className="mb-3">
              <Label>
                Column Name<span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="column_name"
                placeholder="Enter Column Name Here"
                className="form-control"
                validation={validation}
                onChange={handleColumnNameChange}
                value={validation.values.column_name}
                invalid={
                  validation.touched.column_name && validation.errors.column_name ? true : false
              }
              />
             {validation.touched.column_name && validation.errors.column_name ? (
                <FormFeedback type="invalid">{validation.errors.column_name}</FormFeedback>
            ) : null}
            </Col>
            <Col sm={12} className="mb-3">
            <ColumnBackGroundColor validation={validation} showStrike={true} />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <AnotherButton  className="btn btn-secondary" onClick={
                    (e)=>{
                        e.preventDefault();
                        handleClick()}}
                buttonName="Cancel"
                />{' '}
          <LoginButton buttonName="Submit" />
        </div>
        </Form>
      </Modal>

    </React.Fragment>
  )
}
export default withRouter(BoardColumn)
