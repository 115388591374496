import PropTypes from 'prop-types';
import React from "react";
const ToggleSubmitCancelButton = ({ validation, cancelToggle }) => {

    return (
        <div className="d-flex justify-content-end">
        <button
                type="submit"
                disabled={validation.isSubmitting ? true : false}
                className="me-2 btn btn-primary save-user"
            >
                Save
            </button>
            <button onClick={cancelToggle}
                type="button"
                className="btn btn-outline-secondary save-user"
            >
                Cancel
            </button>
        </div>
    )
}

ToggleSubmitCancelButton.propTypes = {
    validation: PropTypes.object,
    cancelToggle: PropTypes.func
}
export default ToggleSubmitCancelButton
