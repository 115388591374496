import Breadcrumbs from "components/Common/Breadcrumb2"
import Name from "components/Common/PageComponent/Name"
import ToggleSubmitCancelButton from "components/Common/PageComponent/ToggleSubmitCancelButton"
import TableContainer from "components/Common/TableContainer"
import withRouter from "components/Common/withRouter"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import {
  clearData,
  addNewData as onAddNewData,
  getList as onGetList,
  updateData as onUpdateData,
} from "store/AppMaster/actions"
import * as Yup from "yup"
import configJs from "../../../../config"
import api from "../../../../constants/api"
import messageConstant from "constants/message"
import AddButton from "components/Common/PageComponent/AddButton"
import BackGroundColor from "components/Common/PageComponent/BackGroundColor"
import DatePicker from "components/Common/PageComponent/DatePicker"
import moment from "moment"
const Holiday = props => {
  document.title = "Holiday | " + configJs.app_name.REACT_APP_NAME
  const dispatch = useDispatch()
  const [adminEpic, setEpic] = useState("")
  const [editFlag, setEditFlag] = useState(false)
  const [addFlag, setAddFlag] = useState(false)
  /**Epic List*/
  const [listCount, setListCount] = useState(0)
  const [filterData, setFilterData] = useState({
    organization_id : localStorage.getItem("projectNayaOrganization") ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id : 0,
  })
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [isApiCall, setIsApiCall] = useState(0)
  const [holidayList, setHolidayList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])
  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(onGetList(filterData, props.router.navigate, api.HolidayList))
  }
  if (!isEmpty(data) && !isEmpty(data.holiday) && updateListData) {
    setHolidayList(data.holiday)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }
//   console.log("holidayList", holidayList);
  const toggle = () => {
    setModal(!modal)
  }
  const handleClicks = () => {
    validation.resetForm()
    setEpic()
    setIsEdit(false)
    toggle()
  }
 
// console.log("adminEpic", adminEpic);
  /**Add Edit Holiday*/
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      festival_name: (adminEpic && adminEpic.festival_name) || "",
    festival_date: (adminEpic && adminEpic.festival_date) ? moment(adminEpic.festival_date, 'DD-MM-YYYY').toDate() : "",

      background_color: (adminEpic && adminEpic.background_color) || "",
      id: (adminEpic && adminEpic.id) || "",
    },
    validationSchema: Yup.object({
    festival_name: Yup.string()
        .required(messageConstant.NAME_REQUIRED)
        .max(250, messageConstant.NAME_INVALID)
        .matches(/^[a-zA-Z0-9 \s]+$/, "Must be a valid Epic"),
    festival_date: Yup.string()
        .required(messageConstant.DATE_REQUIRED),
    background_color: Yup.string()
        .required(messageConstant.BACKGROUND_COLOR_REQUIRED)
        .max(250, messageConstant.BACKGROUND_COLOR_INVALID),
    }),
    onSubmit: values => {
      if (isEdit) {
        setEditFlag(true)
        const festivalDate= moment(values["festival_date"]).format('DD-MM-YYYY');
        const updateData = {
          id: adminEpic.id,
          festival_name: values["festival_name"],
          festival_date: festivalDate,
          background_color: values["background_color"],
        }
        dispatch(clearData())
        dispatch(onUpdateData(updateData, props.router.navigate, api.EditHoliday))
      } 
      else {
        setAddFlag(true)
        const festivalDate= moment(values["festival_date"]).format('DD-MM-YYYY');
        const newData = {
        organization_id : localStorage.getItem("projectNayaOrganization") ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id : 0,
        festival_name: values["festival_name"],
        festival_date: festivalDate,
        background_color: values["background_color"],
        }
        dispatch(clearData())
        dispatch(onAddNewData(newData, props.router.navigate, api.AddHoliday))
      }
    },
  })
  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false
  }
  if (!isEmpty(data) && !isEmpty(data.holiday) && addFlag) {
    setAddFlag(false)
    validation.resetForm()
    toggle()
    dispatch(clearData())
    getListData()
  }
  if (!isEmpty(data) && !isEmpty(data.holiday) && editFlag) {
    setEditFlag(false)
    validation.resetForm()
    setIsEdit(false)
    toggle()
    dispatch(clearData())
    getListData()
  }
  
  const breadcrumbItems = [
    {
      title: "Home",
      path: `/${props.router.organization_slug}/organization/dashboard`,
    },
    {
      title: "Holiday",
      path: `/${props.router.organization_slug}/organization/holiday`,
    },
  ]

  const handleColumnNameChange = e => {
    validation.setFieldValue("festival_name", e.target.value)
  }


const handleEdit = arg => {
    const adminEpic = arg
    console.log("adminEpic", adminEpic);
    // const festivalDate= moment(adminEpic.festival_date).format('YYYY-MM-DD');
    console.log(adminEpic.festival_date);
    setEpic({
      id: adminEpic.id,
      festival_name: adminEpic.festival_name,
    //   festival_date: festivalDate,
      festival_date: adminEpic.festival_date,
      background_color: adminEpic.background_color,
    })
    setIsEdit(true)
    toggle()
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Holiday" breadcrumbItems={breadcrumbItems} />
          <Col lg="12">
            <div className="text-lg-end">
              {/* */}
              <Link
                to="#"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => toggle()}
              >
                <i className="mdi mdi-plus me-1"></i>Add Holiday
              </Link>
            </div>
          </Col>
          <Row>
            <Col md={12}>
              <>
        <>
            {Object.entries(holidayList).map(([month, holidays]) => 
            {
                return (

                <Row key={month}>
                    <Col lg="12">
                        <Card className="mt-1">
                            <CardBody>
                                <CardHeader className="bg-light">
                                    <h4 className="mb-0">{month}</h4>
                                </CardHeader>
                                {holidays.map((holiday, index) => (
                                    
                                    <Row key={index} className="items-center mt-4">
                                        <Col md="auto">
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                                                    padding: "5px",
                                                    borderRadius: "5px",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {holiday.festival_date.split("-")[0]}
                                            </div>
                                        </Col>
                                        <Col>
                                            <span
                                                style={{
                                                    backgroundColor: "darkseagreen",
                                                    padding: "5px",
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    display: "block",
                                                    color: "white",
                                                }}
                                            >
                                                {holiday.festival_name}
                                                <span className="mdi mdi-pencil ms-2"
                                                 onClick={() => handleEdit(holiday)}
                                                 style={{ cursor: "pointer" }}
                                                />
                                            </span>
                                            
                                        </Col>
                                    </Row>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )})}
        </>
                <Modal isOpen={modal} toggle={handleClicks} centered>
                  <ModalHeader toggle={handleClicks} epic="h4">
                    {!!isEdit ? "Edit Holiday" : "Add Holiday"}
                  </ModalHeader>

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <ModalBody>
                      <Row>
                        <Col sm={12} className="mb-3">
                          <Label>
                          Festival Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="festival_name"
                            placeholder="Enter Festival Name Here"
                            className="form-control"
                            validation={validation}
                            onChange={handleColumnNameChange}
                            value={validation.values.festival_name}
                            invalid={
                              validation.touched.festival_name &&
                              validation.errors.festival_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.festival_name &&
                          validation.errors.festival_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.festival_name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <DatePicker
                          label="Festival Date"
                          name="festival_date"
                          validation={validation}
                          showStrike={true}
                        />
                      </Row>
                      <Row>
                        <BackGroundColor
                          validation={validation}
                          showStrike={true}
                        />
                      </Row>
                    </ModalBody>

                    <div className="modal-footer">

                      <ToggleSubmitCancelButton
                        validation={validation}
                        cancelToggle={() => toggle()}
                      />
                    </div>
                  </Form>
                </Modal>
              </>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(Holiday)
