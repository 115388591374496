import React, { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Paginations from "components/Common/Pagination";
import { useFormik } from "formik";
import * as Yup from 'yup';

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

import {Row, Container,  Button, Modal, Form} from "reactstrap";
import Name from "components/Common/PageComponent/Name";
import { useSelector } from "react-redux";
import {
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
    getData as onGetData,
    clearData,
  } from "store/AppMaster/actions"
import DatePicker from "components/Common/PageComponent/DatePicker";
import api from "../../constants/api";
import { isEmpty, set } from "lodash";
import AnotherButton from "components/Common/PageComponent/AnotherButton";
import LoginButton from "components/Common/PageComponent/LoginButton";
import { useDispatch } from "react-redux";
import configJs from "../../config"
import CardSprint from "./CardList"
import { getDates } from "common/commonFunction";
import CustomSelect from "components/Common/PageComponent/DropdownList";

const Sprint = props => {
    document.title = "Sprint | " + process.env.REACT_APP_NAME
    const dispatch = useDispatch()
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
      }))
      const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
      }))
      const [filterData, setFilterData] = useState({
        start: configJs.api_parameter.START,
        limit: configJs.api_parameter.LIMIT,
        search: '',
        sort_by: configJs.api_parameter.SORTBY,
        sort_order: configJs.api_parameter.SORTORDER,
        id: localStorage.getItem("projectNayaOrganization") ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id : 0,
      })

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const perPageData = 6;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
    const currentdata = useMemo(() => (indexOfFirst, indexOfLast), [indexOfFirst, indexOfLast])
    const [addSprint, setAddSprint] = useState('');
    const [editSprint, setEditSprint] = useState('');
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isApiCall, setIsApiCall] = useState(0);
    const [Sprint, setSprint] = useState({})
    const [sprintList, setSprintList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [updateListData, setUpdateListData] = useState(false)
    const [addFlag,setAddFlag]=useState(false);
    const [editFlag,setEditFlag]=useState(false);
    const [statusModal,setStatusModal] = useState(false);
    const [singleSprintId,setSingleSprintId] = useState("");
    const [StatusChangeFlag,setStatusChangeFlag ] = useState("");
    const [selectedSprintStatus, setSelectedSprintStatus] = useState("");

    useEffect(() => {
        if (isApiCall == 0) {
            getListData();
            setIsApiCall(1);
        }

      }, [isApiCall]);
  
  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(onGetList(filterData , props.router.navigate , api.sprintList))
  } 
    //   list data
  if (!isEmpty(data) && !isEmpty(data.Sprint) && updateListData) { 
    if(Array.isArray(data.Sprint)){
        setSprintList(data.Sprint);
        setListCount(data.filter_count);
        setUpdateListData(false);
    }  
  }
    const breadcrumbItem = [
        { title: "Home", path: `/${props.router.organization_slug}/organization/dashboard` },
        { title: "Sprint", path: `/${props.router.organization_slug}/organization/sprint` },
    ];

            // Add Edit Sprint //
        const handleClick = () => {
            setModal(!modal);
            setIsEdit(false);
            setSprint(' ');
          }
          const toggle = () => {
            setModal(!modal)
          }
      
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            organization_id:localStorage.getItem("projectNayaOrganization")
            ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
            : 0,
            name: (Sprint && Sprint.name) || "",
            start_date: (Sprint && Sprint.start_date) || "",
            end_date: (Sprint && Sprint.end_date) || ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Sprint Name").max(250, "Must be less than  250 characters")
                // .matches(
                //     /^[a-zA-Z0-9\s]+$/,
                //     "Must be a valid Sprint")
                ,
            start_date: Yup.date().required("Please Enter Start Date"),
            end_date: Yup.date().required("Please Enter End Date")
        }),
        onSubmit: values => {
            if (isEdit) {
                setEditFlag(true);
                // getDates(start_date,end_date)
                const updateData = {
                    id: Sprint.id,
                    name: values["name"],
                    start_date : values["start_date"],
                    end_date : values["end_date"]
                };
                dispatch(clearData());
                dispatch(onUpdateData(updateData, props.router.navigate, api.sprintEdit))
            } 
            else {
                setAddFlag(true);
                // getDates(start_date,end_date)
                const newData = {
                    organization_id:localStorage.getItem("projectNayaOrganization")
                    ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
                    : 0,
                    name: values["name"],
                    start_date : values["start_date"],
                    end_date : values["end_date"]
                };
                dispatch(clearData());
                dispatch(onAddNewData(newData, props.router.navigate, api.sprintCreate))

            }
        },
    });
    
    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false;
    }
    
    // sprint Add
    if (!isEmpty(data) && !isEmpty(data.Sprint) && addFlag) {
        setAddFlag(false);
        validation.resetForm();
        toggle();
        dispatch(clearData());
        getListData();
    }
    // sprint Edit
    if (!isEmpty(data) && !isEmpty(data.Sprint) && editFlag) {
        setEditFlag(false);
        validation.resetForm();
        setIsEdit(false);
        toggle();
        dispatch(clearData());
        getListData();
    }

    const handleEditClick = arg => {
        const Sprint = arg;
        const startDate= moment(Sprint.start_date).format('YYYY-MM-DD');
        const endDate = moment(Sprint.end_date).format('YYYY-MM-DD');
        
        setSprint({
            id: Sprint.id,
            name: Sprint.name,
            start_date: startDate,
            end_date: endDate
        });
         setIsEdit(true);
        toggle();
      };

      
      const StatusChangetoggle = () => {
        setStatusModal(!statusModal)
      } 

    const handleStatusChange = (SprintId) => {
        setSingleSprintId(SprintId);
        setStatusModal(!statusModal);
    }

    const Statuschangevalidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            organization_id:localStorage.getItem("projectNayaOrganization")
            ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
            : 0,
            id: singleSprintId || "",
            status: selectedSprintStatus,
        },
        validationSchema: Yup.object({
            status: Yup.string()
            .required("Please Enter Sprint Name")
            // .max(250, "Must be less than  250 characters"),
        }),
        onSubmit: values => {
            setStatusChangeFlag(true);
                const updateStatus = {
                    organization_id:localStorage.getItem("projectNayaOrganization")
                    ? JSON.parse(localStorage.getItem("projectNayaOrganization")).id
                    : 0,
                    id: values["id"],
                    status : values["status"],
                };
                dispatch(clearData());
                dispatch(onUpdateData(updateStatus, props.router.navigate, api.sprintStatusChange))
        },
    });

    if (!loading && Statuschangevalidation.isSubmitting) {
        Statuschangevalidation.isSubmitting = false;
    }
    
    // sprint Status Change
    if (!isEmpty(data) && !isEmpty(data.Sprint) && StatusChangeFlag) {
        setStatusChangeFlag(false);
        Statuschangevalidation.resetForm();
        StatusChangetoggle();
        dispatch(clearData());
        getListData();
    }
    
    const handleSprintStatus = selectedSprintStatus => {
        setSelectedSprintStatus(selectedSprintStatus)
        Statuschangevalidation.setFieldValue("status", selectedSprintStatus)
      }
    const changeOptions = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" }
    ]
    return (
        <React.Fragment>
            {/* <DeleteModal
                toggle ={toggleDeleteModal}
                show={isDeleteModalOpen}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setIsDeleteModalOpen(false)}
            /> */}
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Sprint" breadcrumbItems={breadcrumbItem} />
                    <div className="text-end">
                        <Button color="primary"
                            type="submit"
                            onClick={() => {
                                toggle();
                            }}
                            data-toggle="modal"
                            data-target="#myModal"
                            className="btn"
                        >
                            <i className="mdi mdi-plus me-1"></i>
                            Add Sprint
                        </Button>
                    </div>
                    <Row className="mt-4">
              {sprintList && sprintList.map((singleSprint, key) => (
                  <CardSprint singleSprint={singleSprint} key={"_sprint_" + key}  handleEditClick={handleEditClick} handleStatusChange={handleStatusChange}  />  
              ))}
                    </Row>
                    <Row className="mt-4">
                        <Paginations
                            perPageData={perPageData}
                            // data={sprintList}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            // listCount={listCount}
                            // getFilterData={getListData}
                            isShowingPageLength={false}
                            paginationDiv="col-12"
                            paginationClass="pagination pagination-rounded justify-content-end mt-2 mb-5" />
                    </Row>
                </Container>
            </div>
            {/*create Sprint Modal */}
            <Modal
                isOpen={modal}
                toggle={() => {
                    handleClick();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                    {!!isEdit ? "Edit Sprint" : "Add Sprint"}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false);
                        }}
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        
                    </button>
                </div>
                <div className="modal-body">
                <Form
                    onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                    }}
                    >
                    <Row>
                        <Name validation={validation}/>
                        <DatePicker
                        label="Start Date"
                        name="start_date" 
                        validation={validation} 
                        showStrike={true}
                        />
                        <DatePicker
                        label="End Date" 
                        name="end_date" 
                        validation={validation}
                        showStrike={true}
                        />
                        
                    </Row>
                    </Form>
                </div>
                <div className="modal-footer">
                <Form
            onSubmit={(e) => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
              <div className="d-flex align-items-center gap-2">
                <AnotherButton  className="btn btn-secondary" 
                onClick={
                        (e)=>{
                            e.preventDefault();
                            handleClick();
                        }}
                    buttonName="Cancel"
                    />{" "}
                <LoginButton buttonName="Submit" />
                </div>
                    </Form>
                </div>
            </Modal>

            {/* Status Change Modal */}
            <Modal
                isOpen={statusModal}
                toggle={() => {
                    StatusChangetoggle();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                    Change Sprint Status
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            StatusChangetoggle(false);
                        }}
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        
                    </button>
                </div>
                <div className="modal-body">
                <Form
                    onSubmit={(e) => {
                    e.preventDefault()
                    Statuschangevalidation.handleSubmit()
                    return false
                    }}
                    >
                        <div>
              <CustomSelect
                label="Status"
                showStrike={true}
                // value={selectedSprintStatus || defaultDesignationValue}
                options={changeOptions}
                name="status"
                className="custom-select"
                validation={Statuschangevalidation}
                onChange={selectedStatusChange =>
                  handleSprintStatus(selectedStatusChange, "status")
                }
              />
            </div>
               
                <div className="modal-footer">
              <div className="d-flex align-items-center gap-2">
                <AnotherButton  className="btn btn-secondary" 
                onClick={
                        (e)=>{
                            e.preventDefault();
                            StatusChangetoggle();
                        }}
                    buttonName="Cancel"
                    />{" "}
                <LoginButton buttonName="Submit" />
                </div>
                </div>
                    </Form>
                </div>
            </Modal>


        </React.Fragment>
    );
}
export default withRouter(Sprint);