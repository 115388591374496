import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import labelConstant from "constants/label";

const SidebarContent = props => {
  const [authData, setAuthData] = useState(JSON.parse(localStorage.getItem("projectNayaOrganization")));
  const params = new useParams();
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const menuItems = [
    {
      path: `/${authData.slug}/organization/dashboard`,
      icon: "bx bx-home-circle",
      label: "Dashboard",
    },
    {
      path: `/${authData.slug}/organization/employee`,
      icon: "bx bx-user",
      label: "Employee",
    },
    {
      path: `/${authData.slug}/organization/project`,
      icon: "bx bx-briefcase-alt-2",
      label: "Project",
    },
    {
      path: `/${authData.slug}/organization/sprint`,
      icon: "bx bx-spreadsheet",
      label: "Sprint",
    },
    {
      path: `/${authData.slug}/organization/backlog`,
      icon: "bx bx-server",
      label: "Backlog",
    },
    {
      path: `/${authData.slug}/organization/active-sprint`,
      icon: "bx bx-columns",
      label: "Active Sprint",
    },
    {
      path: `/#`,
      icon: "bx bx-cog",
      label: "Master",
      sub_menu: [
        {
          path: `/${authData.slug}/organization/task-tag`,
          icon: "bx bx-purchase-tag",
          label: "Tag",
        },
        {
          path: `/${authData.slug}/organization/task-epic`,
          icon: "bx bx-bar-chart-alt",
          label: "Epic",
        },
        {
          path: `/${authData.slug}/organization/task-type`,
          icon: "bx bx-task",
          label: "Task Type",
        },
        {
          path: `/${authData.slug}/organization/priority`,
          icon: "bx bx-list-plus",
          label: "Priority",
        },
        {
          path: `/${authData.slug}/organization/task-Story-Point`,
          icon: "bx bx-building",
          label: "Story Point",
        },
        {
          path: `/${authData.slug}/organization/designation`,
          icon: "bx bx-user-pin",
          label: "Designation",
        },
        {
          path: `/${authData.slug}/organization/holiday`,
          icon: "mdi mdi-calendar-star",
          label: "Holiday",
        },
      ]
    }
  ];
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menuItems.map((menuItem, i) => (
              <li key={i}>
                <Link to={menuItem.path} 
                className={menuItem.sub_menu && menuItem.sub_menu.length > 0 ? 'has-arrow' : ''}>
                  <i className={menuItem.icon}></i>
                  <span>{menuItem.label}</span>
                </Link>
                {menuItem.sub_menu && menuItem.sub_menu.length > 0 ? (
                  <ul className="sub-menu" aria-expanded="false">
                    {menuItem.sub_menu.map((subMenuItem, i) => (
                      <li key={i}>
                        <Link to={subMenuItem.path} className=" ">
                          <i className={subMenuItem.icon}></i>
                          <span>{subMenuItem.label}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null
                }
              </li>

            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));
